import { Component, OnInit } from "@angular/core";

import { ColorsPanelService } from './colors-panel.service';

@Component({
  selector: "app-colors-panel",
  templateUrl: "./colors-panel.component.html"
})
export class ColorsPanelComponent implements OnInit {
  sidebarColor: string;
  dashboardWhite: boolean;

  constructor(private colorsPanelService: ColorsPanelService) {}
  ngOnInit() {
    this.sidebarColor = this.colorsPanelService.sidebarColor;
    this.dashboardWhite = this.colorsPanelService.dashboardWhite;
    setTimeout(() => {
      this.colorsPanelService.changeSidebarColor(this.colorsPanelService.sidebarColor);
      if(this.dashboardWhite){
        this.changeDashboardColor('white-content');
      };
    });
  }

  changeSidebarColor(color: string){
    this.sidebarColor = color;
    this.colorsPanelService.changeSidebarColor(color);
  }

  changeDashboardColor(color: string){
    this.colorsPanelService.changeDashboardColor(color);
  }

}
