import { ElementSchemaRegistry } from '@angular/compiler';
import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { truncate } from 'fs';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AddonsSettingsService } from 'src/app/pages/settings/addons-settings/addons-settings.service';
import { convertToObject, isConstructorDeclaration } from 'typescript';
import { accommodationColumns } from '../../../reception.interface';
import { ReceptionService } from '../../../reception.service';
import { SummaryAddonsService } from './summary-addons.service';

@Component({
	selector: 'app-reservation-table',
	templateUrl: './reservation-table.component.html',
	styleUrls: ['./reservation-table.component.scss'],
})
@Injectable()
export class ReservationTableComponent implements OnInit, AfterViewInit {
	@ViewChild(MatSort) sort: MatSort;
	@Input() displayedColumns;
	@Input() roomTypes;
	@Input() formData;
	@Input() isLoading: boolean = true;
	@Input() people;
	@Input() events: Observable<void>;
	@Input('summaryCost') summaryCost: number;
	@Input('serviceForm') serviceForm: any;
	@Output() bookingObject = new EventEmitter<any>();
	@Output() summaryObject = new EventEmitter<any>();
	@Output() $globalGuestCounter = new EventEmitter<any>();
	@Output() $globalKidsCounter = new EventEmitter<any>();
	@Output() $globalCostValue = new EventEmitter<any>();
	@Output() $accomodationArrayWithGuestCount = new EventEmitter<any>();
	@Output() $accoArray = new EventEmitter<any>();
	@Output() $guestObject = new EventEmitter<any>();
	@Output() sendSC = new EventEmitter<number>();
	private eventsSubscription: Subscription;
	public newResForm: FormGroup;
	public filteredRoomTypes = [];
	public guestObjectToSend = [];
	public dataSource = new MatTableDataSource();
	public accommodationSource = new MatTableDataSource();
	public addonsSource = new MatTableDataSource();
	public color: ThemePalette = 'primary';
	public mode: ProgressSpinnerMode = 'indeterminate';
	public accommodationColumns: accommodationColumns[] = [
		{ name: 'Pokój' },
		// {name: "Uwagi"},
		{ name: 'Wartość' },
		{ name: 'Pobyt' },
		{ name: 'Akcje' },
	];
	// public addonsColumns: any[] = [{ name: 'Pokój' }, { name: 'Dodatek' }];
	//public addonsColumns: any[] = [{ name: 'Pokój' }, { name: 'Dodatek' }, { name: 'Cena' }, { name: 'Ilość' }];
	public addonsColumns: any[] = [{ name: 'Pokój' }];
	public jej = 0;
	public datepickerDates = {
		startDate: '',
		endDate: '',
	};
	public USER_SCHEMA = {
		Pokój: 'text',
		Dorosły: 'number',
		Wartość: 'number',
	};
	public isToday: boolean;
	public currentRoomCapacity: number = 0;
	public dataSchema = this.USER_SCHEMA;
	public accommodationArray = [];
	public rawRoomArray = [];
	public summaryValue: number = 0;
	public globalGuestNumber: number = 0;
	public globalKidsNumber: number = 0;
	public globalGuestCounter: number = 0;
	public globalCostValue;
	public availableRooms = {};
	public withLocalization: boolean = true;
	public flattenedColumns = [];
	public bedsSummary: number = 0;
	public tempValue: number = 0;
	public tempAccommodationArray = [];
	public addons: any[][] = [];
	public which: number = -1;
	public whichTable: boolean[] = [];
	public ifStartedAdding: boolean = false;
	public tempAddons: any[] = [];
	public whichDisabled: boolean[] = [];
	public addonsTable: any[][] = [];
	public addonsTableStart: boolean = true;
	public tempAddonsTableLength: number = 0;
	public addonDateFrom: string = '';
	public addonDateTo: string = '';
	public summaryAddonsCost: number = 0;
	public addonsCounter: number = 0;
	public roomName: string[] = [];
	public todayYearPlusOne: number = 0;
	public addonsTableCounter: number;
	public addonsTablePointerNumber: number[];
	public indexes: number[] = [];
	public ceny: any[] = [];
	public ileDodLozek: any[] = [];
	public ileDostepnychPokoi: any[] = [];
	public isDeleted: boolean = false;
	constructor(
		private receptionService: ReceptionService,
		public authService: AuthService,
		public addonsSettingsService: AddonsSettingsService,
		public summaryAddonsService: SummaryAddonsService,
		private router: Router,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource(this.roomTypes);
		this.eventsSubscription = this.events.subscribe(() => this.check());
		//console.log(this.serviceForm);
		this.summaryAddonsService.setTempAccommodationArray([]);
		this.summaryAddonsService.setWhichTable([]);
		this.summaryAddonsService.setAddonsTableStart(true);
		this.summaryAddonsService.setSummaryAddonsCost(0);
		this.summaryAddonsService.setAddonsCounter(0);
		this.summaryAddonsService.setTodayYearPlusOne(0);
		this.summaryAddonsService.setAddonsTableCounter(0);
		this.summaryAddonsService.setAddonsTablePointerNumber([]);
		this.summaryAddonsService.setAccommodationArray([]);
		this.summaryAddonsService.setAccommodationSource(new MatTableDataSource());
		this.summaryAddonsService.setSummaryCost(0);
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}

	check(): void {
		this.roomTypes.forEach((el, index) => {
			if (!el.ceny[1]) {
				this.roomTypes.splice(index, 1);
			}
		});
		for (let i = 0; i < this.roomTypes.length; i++) {
			if (this.roomTypes[i].ceny[1].ceny.length == 0) {
				this.roomTypes.splice(i, 1);
			}
		}
		this.roomTypes.forEach((element, index) => {
			if (element.polozenieNazwa) {
				this.availableRooms[element.polozenieNazwa] = element.pokoje;
			} else {
				this.availableRooms[element.pokojtypNazwa] = element.pokoje;
			}
		});
		this.dataSource = new MatTableDataSource(this.roomTypes);
	}

	calculateNewCost(event, roomIndex, element, defaultCost) {
		let elementName = element.name;
		let changedSingleCost;
		if (event.target.value && event.target.value !== null && event.target.value !== defaultCost) {
			changedSingleCost = parseFloat(event.target.value);
			this.summaryAddonsService.getAccommodationArray()[roomIndex][elementName] = changedSingleCost;
			this.$accomodationArrayWithGuestCount.emit(this.summaryAddonsService.getAccommodationArray());
		}
	}

	pickDefinedRoom(event, roomIndex, element, pokojNazwa) {
		let pickedRoom = event.value;
		if (event.value) {
			this.summaryAddonsService.getAccommodationArray()[roomIndex]['pokojId'] = pickedRoom;
			// this.$accomodationArrayWithGuestCount.emit(this.accommodationArray)
			this.$accoArray.emit(this.summaryAddonsService.getAccommodationArray());
		}
		if (event.value != undefined) {
			pokojNazwa.filter((value) => {
				if (value.pokojId == event.value) this.roomName[roomIndex] = value.pokojNazwa;
			});
		} else this.roomName[roomIndex] = '';
	}

	public calculateTheCost(): void {
		this.summaryAddonsService.setSummaryCost(0);
		//this.summaryValue = 0;
		this.summaryAddonsService.getAccommodationArray().forEach((el) => {
			//this.summaryValue = this.summaryValue + parseFloat(el.Wartość);
			this.summaryAddonsService.setSummaryCost(this.summaryAddonsService.getSummaryCost() + parseFloat(el.Wartość));
		});

		//localStorage.setItem('summaryValue', this.summaryValue.toString());
		localStorage.setItem('summaryValue', this.summaryAddonsService.getSummaryCost().toString());
	}

	sendTheCost() {
		//this.summaryObject.emit(this.summaryValue);
		this.summaryObject.emit(this.summaryAddonsService.getSummaryCost());
		this.$globalCostValue.emit(this.globalCostValue);
	}

	sendTheCounter() {
		this.$globalGuestCounter.emit(this.globalGuestCounter);
		//this.summaryAddonsService.getAccommodationArray()[0].Wartość=this.accommodationArray[0].Wartość*this.globalGuestCounter;
		this.$accoArray.emit(this.summaryAddonsService.getAccommodationArray());
	}

	isAlreadyInArray(data) {
		if (!this.accommodationColumns.some((el) => el.name === data.className)) {
			let count = 0;
			if (data.count) {
				count = data.count;
			}
			this.accommodationColumns.splice(this.accommodationColumns.length - 1, 0, { name: data.className, count: count, isAdult: data.isAdult, bed: data.bed, classId: data.classId });
		}
	}

	pushToAccommodation(choosenRoom, index: number): void {
		this.summaryAddonsService.setIfNewDates(false);
		this.indexes.push(index);
		if (this.whichDisabled.length > 0 && this.whichDisabled[0] == true) {
			this.whichDisabled.push(false);
		}
		if (this.summaryAddonsService.getAddonsTableCounter() == undefined) this.summaryAddonsService.setAddonsTableCounter(0);
		this.summaryAddonsService.setAddonsTableCounter(this.summaryAddonsService.getAddonsTableCounter() + 1);
		//this.summaryAddonsService.setAddonsTableCounter(this.addonsTableCounter);

		if (this.summaryAddonsService.getAddonsTablePointerNumber() == undefined) this.summaryAddonsService.setAddonsTablePointerNumber([]);
		this.summaryAddonsService.getAddonsTablePointerNumber()[this.summaryAddonsService.getAddonsTablePointerNumber().length ? this.summaryAddonsService.getAddonsTablePointerNumber().length : 0] = index; //setting index to pointer number(works similar to pointer)
		//this.summaryAddonsService.setAddonsTablePointerNumber(this.addonsTablePointerNumber);
		let actionPosition;
		this.globalGuestCounter = this.globalGuestCounter + choosenRoom.ileLozek;
		if (this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[index] == undefined) this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[index] = 0;
		this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[index]++;
		this.currentRoomCapacity = choosenRoom.ileLozek;

		let doroslyObject = this.people.filter((res) => res.isAdult == true)[0];
		doroslyObject.count = choosenRoom.ileLozek;
		let preparedRoom = {
			Pokój: choosenRoom.pokojtypNazwa,
			Dorosły: choosenRoom.ileLozek,
			Wartość: parseFloat(choosenRoom.ceny[1].razemBrutto),
			pokojtypId: choosenRoom.pokojtypId,
			pojemnosc: choosenRoom.ileLozek,
			ileDostepnychPokoi: choosenRoom.ileDostepnychPokoi,
			ileDodLozek: choosenRoom.ileDodLozek,
			ceny: choosenRoom.ceny,
			polozenieId: choosenRoom.polozenieId,
			polozenieNazwa: choosenRoom.polozenieNazwa,
		};

		if (choosenRoom.polozenieId) {
			preparedRoom.polozenieId = choosenRoom.polozenieId;
			preparedRoom.polozenieNazwa = choosenRoom.polozenieNazwa;
		}
		this.people.forEach((element) => {
			let adultGuestName;
			this.people.every((e) => {
				if (e.isAdult == true) {
					adultGuestName = e.className;
					return e.className;
				}
			});
			preparedRoom[element.className] = { name: element.className, isAdult: element.isAdult, bed: element.bed, classId: element.classId, count: element.isAdult == true ? choosenRoom.ileLozek : 0 };
			this.isAlreadyInArray(element);
		});

		moment().format('MM-DD') == this.displayedColumns[1] ? (this.isToday = true) : (this.isToday = false);

		actionPosition = this.accommodationColumns.indexOf({ name: 'Akcje' });
		this.accommodationColumns.push(this.accommodationColumns.splice(actionPosition, 1)[0]);
		this.flattenedColumns = this.accommodationColumns.map((col) => col.name);
		this.summaryAddonsService.getAccommodationArray().push(preparedRoom);
		if (this.summaryAddonsService.getAccommodationArray()) this.summaryAddonsService.setIsAccommodationArrayNotEmpty(true);
		this.calculateTheCost();
		this.summaryAddonsService.setAccommodationSource(new MatTableDataSource(this.summaryAddonsService.getAccommodationArray()));
		for (let i = 0; i < this.summaryAddonsService.getAccommodationArray().length; i++) {
			this.summaryAddonsService.getTempAccommodationArray()[i] = this.summaryAddonsService.getAccommodationArray()[i].Pokój;
			this.summaryAddonsService.getTempAccommodationArray()[i] = this.summaryAddonsService.getTempAccommodationArray()[i][0].toUpperCase() + this.summaryAddonsService.getTempAccommodationArray()[i].slice(1).toLowerCase();
			this.roomName[i] = '';
		}
		this.toggleAddonsAll(this.summaryAddonsService.getTempAccommodationArray().length);
		let naDzien = new Date(moment().format('YYYY-MM-DD'));

		let active: boolean = false;

		this.dataSource.data.forEach((res) => {
			if (res['pokojtypId'] == choosenRoom.pokojtypId && this.withLocalization == false) {
				choosenRoom['ileDostepnychPokoi']--;
				return true;
			} else if (res['pokojtypId'] == choosenRoom.pokojtypId && res['polozenieId'] == choosenRoom.polozenieId && this.withLocalization == true) {
				choosenRoom['ileDostepnychPokoi']--;
				return true;
			}
		});
		this.$accomodationArrayWithGuestCount.emit(this.summaryAddonsService.getAccommodationArray());

		for (let j = this.tempAddonsTableLength; j < this.addons.length; j++) {
			this.addonsTable[j] = [];
			for (let i = 0; i < this.addons[j].length; i++) {
				this.addonsTable[j][i] = [];
				this.addonsTable[j][i] = 0;
			}
		}
		this.tempAddonsTableLength = this.addons.length;
		this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);

		let tempPrzydzielonePokoje: number[] = this.summaryAddonsService.getPrzydzielonePokojeNumberTable();
		for (let i = 0; i < this.roomTypes.length; i++) {
			if (!tempPrzydzielonePokoje[i]) tempPrzydzielonePokoje[i] = 0;
		}
		this.summaryAddonsService.setPrzydzielonePokojeNumberTable(tempPrzydzielonePokoje);

		let data = {
			dataOd: moment(this.serviceForm.value.fromDate).format('YYYY-MM-DD'),
			dataDo: moment(this.serviceForm.value.toDate).format('YYYY-MM-DD'),
		};
		this.summaryAddonsService.getRoomsDateFrom().push(data.dataOd);
		this.summaryAddonsService.getRoomsDateTo().push(data.dataDo);

		//console.log(this.summaryAddonsService.getAccommodationSource());
		//console.log((this.summaryAddonsService.getAccommodationSource().data[0]['Wartość'] = 1));
	}

	deleteRow(index, element): void {
		this.summaryAddonsService.setIfNewDates(false);
		if (this.whichDisabled.length > 0 && this.whichDisabled[0] == true) this.whichDisabled.pop();
		this.globalCostValue -= element['Wartość'];
		this.globalGuestCounter -= element['Dorosły'].count;
		this.dataSource.data.forEach((roomTypeInAvail) => {
			if (roomTypeInAvail['pokojtypId'] == element.pokojtypId && this.withLocalization == false) {
				roomTypeInAvail['ileDostepnychPokoi']++;
				return true;
			} else if (roomTypeInAvail['pokojtypId'] == element.pokojtypId && roomTypeInAvail['polozenieId'] == element.polozenieId && this.withLocalization == true) {
				roomTypeInAvail['ileDostepnychPokoi']++;
				return true;
			}
		});
		for (var i = 0; i < this.summaryAddonsService.getAccommodationArray().length; i++) {
			if (i === index) {
				this.summaryAddonsService.getAccommodationArray().splice(i, 1);
			}
			this.summaryAddonsService.setAccommodationSource(new MatTableDataSource(this.summaryAddonsService.getAccommodationArray()));
		}
		this.calculateTheCost();
		this.summaryAddonsService.setTempAccommodationArray([]);
		for (let i = 0; i < this.summaryAddonsService.getAccommodationArray().length; i++) {
			this.summaryAddonsService.getTempAccommodationArray()[i] = this.summaryAddonsService.getAccommodationArray()[i].Pokój;
		}
		this.roomName.splice(index, 1);

		let tempCounter: number = 0;
		for (let i = 0; i < this.addonsTable[index].length; i++) {
			tempCounter += this.addonsTable[index][i];
		}
		this.addonsTable.splice(index, 1);
		this.addons.splice(index, 1);
		this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);
		this.summaryAddonsService.setAddons(this.addons);
		if (this.summaryAddonsService.getAddonsCounter() != undefined && this.summaryAddonsService.getAddonsCounter() - tempCounter >= 0)
			this.summaryAddonsService.setAddonsCounter(this.summaryAddonsService.getAddonsCounter() - tempCounter);

		//if (this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[index] > 0) this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[index]--;
		if (this.summaryAddonsService.getAddonsTableCounter() >= 1) {
			this.summaryAddonsService.setAddonsTableCounter(this.summaryAddonsService.getAddonsTableCounter() - 1);
			//this.summaryAddonsService.setAddonsTableCounter(this.addonsTableCounter);
		}
		let tempPointer: number[] = [];
		tempPointer = this.summaryAddonsService.getAddonsTablePointerNumber().splice(index, 1);
		//this.summaryAddonsService.setAddonsTablePointerNumber(this.addonsTablePointerNumber);

		if (this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[tempPointer[0]] > 0) this.summaryAddonsService.getPrzydzielonePokojeNumberTable()[tempPointer[0]]--;

		this.getAsortNumberOfDays(this.addons);

		this.summaryAddonsService.getRoomsDateFrom().splice(index, 1);
		this.summaryAddonsService.getRoomsDateTo().splice(index, 1);
		this.indexes.splice(index, 1);

		if (!this.summaryAddonsService.getAccommodationArray()) {
			this.summaryAddonsService.setIsAccommodationArrayNotEmpty(false);
		}
	}

	deleteRow2(index, element): void {
		this.globalCostValue -= element['Wartość'];
		this.globalGuestCounter -= element['Dorosły'].count;
		this.calculateTheCost();
	}

	getCount(isDeleting: boolean) {
		this.bedsSummary = 0;
		this.guestObjectToSend = [];
		this.globalGuestCounter = 0;
		this.summaryAddonsService.getAccommodationArray().map((pokoj) => {
			Object.keys(pokoj).map((key) => {
				if (pokoj[key] && pokoj[key].hasOwnProperty('bed')) {
					if (pokoj[key].bed == true) {
						this.globalGuestCounter += pokoj[key].count;
					}
				}
			});
		});
		if (isDeleting) {
			this.$accomodationArrayWithGuestCount.emit(this.summaryAddonsService.getAccommodationArray());
			// this.globalGuestCounter = this.bedsSummary;
		}
	}

	changeNumber(event, num) {
		if (event.target.value > num || event.target.value < 1) {
			event.target.value = num;
		}
		this.globalGuestCounter = event.target.value;
	}

	changeKidsNumber(event, roomIndex, number = 1) {
		if (number == 1) this.tempValue = this.summaryAddonsService.getAccommodationArray()[roomIndex]['Wartość'];
		else this.summaryAddonsService.getAccommodationArray()[roomIndex]['Wartość'] = this.tempValue;
	}

	calculateGuestNumber(event, roomIndex, element, isBedNeeded) {
		let inputValue = parseInt(event.target.value);
		let obj = { count: inputValue, bed: isBedNeeded, classId: element.classId };
		if (inputValue > 0 && inputValue <= 4 && Number(event.target.value)) {
			this.summaryAddonsService.getAccommodationArray()[roomIndex][element.name].count = inputValue;
			this.summaryAddonsService.getAccommodationArray()[roomIndex]['Wartość'] = this.summaryAddonsService.getAccommodationArray()[roomIndex].ceny[inputValue].razemBrutto;
			this.calculateTheCost();
			this.getCount(false);
			let childrenCounter = this.summaryAddonsService.getAccommodationArray().reduce((prev, curr) => {});
			if (isBedNeeded) {
				this.$accomodationArrayWithGuestCount.emit(this.summaryAddonsService.getAccommodationArray());
				// this.globalGuestCounter = this.bedsSummary;
				this.globalKidsNumber = childrenCounter;
			}
		}
	}
	toggleAddons(roomsNamesIndex: number, arrayLength: number) {
		if (!this.summaryAddonsService.getIfNewDates() && !this.whichDisabled[roomsNamesIndex]) {
			//if not disabled
			this.addonsSettingsService.getDodatekWrList(true).subscribe((res) => {
				res.sort((a, b) => {
					//sorting by names of addons
					return a.nazwa > b.nazwa ? 1 : -1;
				});

				for (let i = 0; i < arrayLength; i++) {
					//setting to every addon addons details
					this.addons[i] = [];
					this.addons[i] = [...res];
				}

				this.summaryAddonsService.setAddons(this.addons);

				if (this.summaryAddonsService.getAddonsTableStart()) {
					//setting initial value 0 to addonsTable (table of addons values displayed on addons view on the right)
					this.tempAddonsTableLength = this.addons.length;
					for (let j = 0; j < this.addons.length; j++) {
						this.addonsTable[j] = [];
						for (let i = 0; i < this.addons[j].length; i++) {
							this.addonsTable[j][i] = [];
							this.addonsTable[j][i] = 0;
						}
					}
					this.summaryAddonsService.setAddonsTableStart(false);
				} else {
					//the same as previous if, in case there are more addons than initially
					for (let j = this.tempAddonsTableLength; j < this.addons.length; j++) {
						this.addonsTable[j] = [];
						for (let i = 0; i < this.addons[j].length; i++) {
							this.addonsTable[j][i] = [];
							this.addonsTable[j][i] = 0;
						}
					}
					this.tempAddonsTableLength = this.addons.length;
				}

				this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);

				if (!this.ifStartedAdding) {
					//setting whichTable, used to show or hide addons' details
					for (let i = 0; i < this.addons.length; i++) {
						this.summaryAddonsService.getWhichTable()[i] = false;
						this.ifStartedAdding = true;
					}
				}

				this.summaryAddonsService.getWhichTable()[roomsNamesIndex] = !this.summaryAddonsService.getWhichTable()[roomsNamesIndex]; //show or hide current addon's details
			});
		}
	}

	toggleAddonsAll(arrayLength: number) {
		this.addonsSettingsService.getDodatekWrList(true).subscribe((res) => {
			res.sort((a, b) => {
				//sorting by names of addons
				return a.nazwa > b.nazwa ? 1 : -1;
			});

			for (let i = 0; i < arrayLength; i++) {
				//setting to every addon addons details
				this.addons[i] = [];
				this.addons[i] = [...res];
			}

			this.summaryAddonsService.setAddons(this.addons);

			if (this.summaryAddonsService.getAddonsTableStart()) {
				//setting initial value 0 to addonsTable (table of addons values displayed on addons view on the right)
				for (let j = 0; j < this.addons.length; j++) {
					this.addonsTable[j] = [];
					for (let i = 0; i < this.addons[j].length; i++) {
						this.addonsTable[j][i] = [];
						this.addonsTable[j][i] = 0;
					}
				}
				this.summaryAddonsService.setAddonsTableStart(false);
				this.tempAddonsTableLength = this.addonsTable.length;
			} else {
				//the same as previous if, in case there are more addons than initially
				for (let j = this.tempAddonsTableLength; j < this.addons.length; j++) {
					this.addonsTable[j] = [];
					for (let i = 0; i < this.addons[j].length; i++) {
						this.addonsTable[j][i] = [];
						this.addonsTable[j][i] = 0;
					}
				}
				this.tempAddonsTableLength = this.addons.length;
			}

			this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);

			if (!this.ifStartedAdding) {
				//setting whichTable, used to show or hide addons' details
				for (let i = 0; i < this.addons.length; i++) {
					this.summaryAddonsService.getWhichTable()[i] = false;
					this.ifStartedAdding = true;
				}
			}

			for (let i = 0; i < arrayLength; i++) {
				this.summaryAddonsService.getWhichTable()[i] = true; //show addon's details
			}
		});
	}

	setAddonsDates() {
		this.addonDateFrom = this.displayedColumns[1];
		this.addonDateTo = this.displayedColumns[this.displayedColumns.length - 1];
		let todayYear: string = moment().format('YYYY');
		this.summaryAddonsService.setTodayYearPlusOne(parseInt(todayYear) + 1);
		if (this.addonDateTo < this.addonDateFrom) {
			this.addonDateFrom = todayYear + '-' + this.addonDateFrom;
			this.addonDateTo = this.summaryAddonsService.getTodayYearPlusOne().toString() + '-' + this.addonDateTo;
		} else {
			this.addonDateFrom = todayYear + '-' + this.addonDateFrom;
			this.addonDateTo = todayYear + '-' + this.addonDateTo;
		}
		this.addonDateTo = moment(this.addonDateTo).add('1', 'days').format('YYYY-MM-DD');
	}

	getAsortNumberOfDays(asort: any, roomsNamesIndex: number = -1, addonsDetailsIndex: number = -1): void {
		this.setAddonsDates();
		let toMom = moment(this.addonDateTo);
		let fromMom = moment(this.addonDateFrom);
		let diff = toMom.diff(fromMom, 'days');
		const numberOfNights = diff;
		for (let y = 0; y < asort.length; y++) {
			for (let z = 0; z < asort[y].length; z++) {
				switch (asort[y][z].sposobNaliczania) {
					case 'NALICZ_BEZ_OSTATNIEGO_DNIA':
						asort[y][z].asortNumberOfDays = numberOfNights;
						break;
					case 'NALICZ_BEZ_PIERWSZEGO_DNIA':
						asort[y][z].asortNumberOfDays = numberOfNights;
						break;
					case 'NALICZ_BEZ_PIERWSZEGO_I_OSTATNIEGO_DNIA':
						asort[y][z].asortNumberOfDays = numberOfNights - 1 < 0 ? 0 : numberOfNights - 1;
						break;
					case 'NALICZ_BRAK_NALICZANIA':
						asort[y][z].asortNumberOfDays = 0;
						break;
					case 'NALICZ_RAZ_W_DNIU_PRZYJAZDU':
						asort[y][z].asortNumberOfDays = 1;
						break;
					case 'NALICZ_RAZ_W_DNIU_WYJAZDU':
						asort[y][z].asortNumberOfDays = 1;
						break;
					case 'NALICZ_WG_ILOSCI_DOB_HOTELOWYCH':
						asort[y][z].asortNumberOfDays = numberOfNights;
						break;
					case 'NALICZ_ZAWSZE':
						// if (asort[y][z].nazwa == 'Bukiet kwiatów' || asort[y][z].nazwa == 'Butelka wina') asort[y][z].asortNumberOfDays = 1;
						// else asort[y][z].asortNumberOfDays = numberOfNights + 1;
						asort[y][z].asortNumberOfDays = numberOfNights + 1;
						break;
					default:
						asort[y][z].asortNumberOfDays = 1;
						break;
				}
			}
		}
		//if(this.notZeroSummaryAddons)
		this.summaryAddonsCost = 0;
		for (let i = 0; i < this.addonsTable.length; i++) {
			//count current addon price(with specified asortNumberOfDays)
			for (let j = 0; j < this.addonsTable[i].length; j++) {
				if (asort[i][j].asortNumberOfDays != undefined && this.addonsTable[i][j] != 0) {
					this.summaryAddonsCost += this.addonsTable[i][j] * asort[i][j].uslugaCenaJednBrutto * asort[i][j].asortNumberOfDays;
					//add number of addons * price * specified number of days
				}
			}
		}
		this.summaryAddonsService.setSummaryAddons(this.summaryAddonsCost);
		this.summaryAddonsService.setAddons(asort);
	}

	addAddon(roomsNamesIndex: number, addonsDetailsIndex: number) {
		if (!this.summaryAddonsService.getIfNewDates() /*&& !this.whichDisabled[roomsNamesIndex]*/) {
			//if not disabled
			this.addonsTable[roomsNamesIndex][addonsDetailsIndex]++;
			this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);
			this.summaryAddonsService.setAddonsCounter(this.summaryAddonsService.getAddonsCounter() + 1);
			this.getAsortNumberOfDays(this.addons, roomsNamesIndex, addonsDetailsIndex);
		}
	}
	removeAddon(roomsNamesIndex: number, addonsDetailsIndex: number) {
		if (!this.summaryAddonsService.getIfNewDates() /*&& !this.whichDisabled[roomsNamesIndex]*/) {
			//if not disabled
			if (this.addonsTable[roomsNamesIndex][addonsDetailsIndex] >= 1) {
				this.addonsTable[roomsNamesIndex][addonsDetailsIndex]--;
				this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);
				//this.tempAddonsTableLength--;
				this.summaryAddonsService.setAddonsCounter(this.summaryAddonsService.getAddonsCounter() - 1);
				this.getAsortNumberOfDays(this.addons, roomsNamesIndex, addonsDetailsIndex);
			}
		}
	}

	updateOrBlockAfterDatesChange(ifUpdateAllData: boolean) {
		if (ifUpdateAllData && this.summaryAddonsService.getAccommodationArray().length > 0) {
			//console.log(this.dataSource.data); tyle ile rodzajow pokoi
			setTimeout(() => {
				let data = {
					dataOd: moment(this.serviceForm.value.fromDate).format('YYYY-MM-DD'),
					dataDo: moment(this.serviceForm.value.toDate).format('YYYY-MM-DD'),
				};

				let j: number = 0,
					oldValueIndexes: number[] = [];
				let tempPokojTypNazwa: string[] = [];
				for (let i = 0; i < this.dataSource.data.length; i++) {
					if (this.dataSource.data[i]['pokojtypId'] == this.summaryAddonsService.getAccommodationArray()[j].pokojtypId && this.withLocalization == false) {
						//console.log('już 1!');
						this.ceny.push(this.summaryAddonsService.getAccommodationArray()[j]['ceny']);
						this.ileDodLozek.push(this.summaryAddonsService.getAccommodationArray()[j]['ileDodLozek']);
						this.ileDostepnychPokoi.push(this.summaryAddonsService.getAccommodationArray()[j]['ileDostepnychPokoi']);
						this.summaryAddonsService.getAccommodationArray()[j]['ceny'] = this.dataSource.data[i]['ceny'];
						this.summaryAddonsService.getAccommodationArray()[j]['ileDodLozek'] = this.dataSource.data[i]['ileDodLozek'];
						this.summaryAddonsService.getAccommodationArray()[j]['ileDostepnychPokoi'] = this.dataSource.data[i]['ileDostepnychPokoi'];
						if (this.dataSource.data[i]['ileDostepnychPokoi'] - 1 >= 0) this.dataSource.data[i]['ileDostepnychPokoi']--;
						else {
							let tempPokojTypId: number = this.dataSource.data[i]['pokojtypId'];
							this.dataSource.data[i]['ileDostepnychPokoi']--;
							let k = 0;
							for (let m = 0; m < this.dataSource.data.length; m++) {
								if (this.dataSource.data[m]['pokojtypId'] == this.summaryAddonsService.getAccommodationArray()[k].pokojtypId && this.withLocalization == false && this.dataSource.data[m]['pokojtypId'] == tempPokojTypId) {
									this.dataSource.data[m]['ileDostepnychPokoi']++;
									oldValueIndexes.push(k);
									if (tempPokojTypNazwa && !tempPokojTypNazwa.includes(this.dataSource.data[m]['pokojtypNazwa'])) tempPokojTypNazwa.push(this.dataSource.data[m]['pokojtypNazwa']);
								}
								if (m == this.dataSource.data.length - 1 && k < this.summaryAddonsService.getAccommodationArray().length) {
									m = -1;
									k++;
								}
								if (k >= this.summaryAddonsService.getAccommodationArray().length) break;
							}
						}
						j++;
						i = 0;
						if (j >= this.summaryAddonsService.getAccommodationArray().length) break;
					} else if (
						this.dataSource.data[i]['pokojtypId'] == this.summaryAddonsService.getAccommodationArray()[j].pokojtypId &&
						this.dataSource.data[i]['polozenieId'] == this.summaryAddonsService.getAccommodationArray()[j].polozenieId &&
						this.withLocalization == true
					) {
						//console.log('już 2!');
						this.ceny.push(this.summaryAddonsService.getAccommodationArray()[j]['ceny']);
						this.ileDodLozek.push(this.summaryAddonsService.getAccommodationArray()[j]['ileDodLozek']);
						this.ileDostepnychPokoi.push(this.summaryAddonsService.getAccommodationArray()[j]['ileDostepnychPokoi']);
						this.summaryAddonsService.getAccommodationArray()[j]['ceny'] = this.dataSource.data[i]['ceny'];
						this.summaryAddonsService.getAccommodationArray()[j]['ileDodLozek'] = this.dataSource.data[i]['ileDodLozek'];
						this.summaryAddonsService.getAccommodationArray()[j]['ileDostepnychPokoi'] = this.dataSource.data[i]['ileDostepnychPokoi'];
						//console.log(this.dataSource.data[i]['ileDostepnychPokoi'] - 1);
						if (this.dataSource.data[i]['ileDostepnychPokoi'] - 1 >= 0) this.dataSource.data[i]['ileDostepnychPokoi']--;
						else {
							//oldValueIndexes.push(j);
							let tempPokojTypId: number = this.dataSource.data[i]['pokojtypId'];
							this.dataSource.data[i]['ileDostepnychPokoi']--;
							let k = 0;
							for (let m = 0; m < this.dataSource.data.length; m++) {
								if (
									this.dataSource.data[m]['pokojtypId'] == this.summaryAddonsService.getAccommodationArray()[k].pokojtypId &&
									this.dataSource.data[m]['polozenieId'] == this.summaryAddonsService.getAccommodationArray()[k].polozenieId &&
									this.withLocalization == true &&
									this.dataSource.data[m]['pokojtypId'] == tempPokojTypId
								) {
									this.dataSource.data[m]['ileDostepnychPokoi']++;
									oldValueIndexes.push(k);
									if (tempPokojTypNazwa && !tempPokojTypNazwa.includes(this.dataSource.data[m]['pokojtypNazwa'])) tempPokojTypNazwa.push(this.dataSource.data[m]['pokojtypNazwa']);
								}

								if (m == this.dataSource.data.length - 1 && k < this.summaryAddonsService.getAccommodationArray().length) {
									m = -1;
									k++;
								}
								if (k >= this.summaryAddonsService.getAccommodationArray().length) break;
							}
						}
						j++;
						i = 0;
						if (j >= this.summaryAddonsService.getAccommodationArray().length) break;
					}
				}

				//console.log(oldValueIndexes);
				this.isDeleted = false;
				for (let k = 0; k < this.summaryAddonsService.getAccommodationArray().length; k++) {
					for (let i = 0; i < this.dataSource.data.length; i++) {
						if (this.dataSource.data[i]['pokojtypId'] == this.summaryAddonsService.getAccommodationArray()[k].pokojtypId) {
							i = this.dataSource.data.length;
						} else if (i == this.dataSource.data.length - 1) {
							//oldValueIndexes.push(k);
							//delete all rooms, because updated room types are not the same as previous
							this.summaryAddonsService.summaryAddons = 0;
							this.summaryAddonsService.addons.splice(0);
							this.summaryAddonsService.addonsTableNumber.splice(0);
							this.summaryAddonsService.setAddonsTableNumber(this.addonsTable);
							this.summaryAddonsService.setAddons(this.addons);
							this.summaryAddonsService.przydzielonePokojeNumberTable.splice(0);
							this.summaryAddonsService.addonsTablePointerNumber.splice(0);
							this.summaryAddonsService.addonsTableCounter = 0;
							this.summaryAddonsService.setTempAccommodationArray([]);
							this.addons = [];
							this.summaryAddonsService.setWhichTable([]);
							this.summaryAddonsService.setAddonsTableStart(true);
							this.summaryAddonsCost = 0;
							this.summaryAddonsService.setAddonsCounter(0);
							this.summaryAddonsService.setTodayYearPlusOne(0);
							this.summaryAddonsService.setAddonsTableCounter(0);
							this.summaryAddonsService.setAddonsTablePointerNumber([]);
							//delete data for Zakwaterowanie
							this.summaryAddonsService.setAccommodationArray([]);
							this.summaryAddonsService.setAccommodationSource(new MatTableDataSource(this.summaryAddonsService.getAccommodationArray()));
							this.summaryAddonsService.setSummaryCost(0);
							this.mySendSc(this.summaryAddonsService.getSummaryCost()); //send to parent component summaryCost set to 0
							this.isDeleted = true;

							this.snackBar.open('Brak dostępności przynajmniej jednego typu pokoju w podanym okresie, wybierz inny typ pokoju lub zmień termin.', '', {
								duration: 15000,
							});
						}
					}
				}
				if (!this.isDeleted) {
					//console.log(oldValueIndexes);
					//console.log(this.summaryAddonsService.getAccommodationArray());
					for (let i = 0; i < this.summaryAddonsService.getAccommodationSource().data.length; i++) {
						for (let j = 0; j < oldValueIndexes.length; j++) {
							if (oldValueIndexes[j] == i) {
								this.summaryAddonsService.getAccommodationArray()[oldValueIndexes[j]]['ceny'] = this.ceny[oldValueIndexes[j]];
								this.summaryAddonsService.getAccommodationArray()[oldValueIndexes[j]]['ileDodLozek'] = this.ileDodLozek[oldValueIndexes[j]];
								this.summaryAddonsService.getAccommodationArray()[oldValueIndexes[j]]['ileDostepnychPokoi'] = this.ileDostepnychPokoi[oldValueIndexes[j]];
								j = oldValueIndexes.length;
							} else if (j == oldValueIndexes.length - 1) {
								this.summaryAddonsService.getAccommodationArray()[i]['Wartość'] = this.dataSource.data[this.indexes[i]]['ceny'][this.summaryAddonsService.getAccommodationSource().data[i]['Dorosły'].count].razemBrutto;
								this.summaryAddonsService.getRoomsDateFrom()[i] = data.dataOd;
								this.summaryAddonsService.getRoomsDateTo()[i] = data.dataDo;
							}
						}
						this.summaryAddonsService.getAccommodationArray()[i]['ileDostepnychPokoi']--;
						this.whichDisabled[i] = false; //if all data to stop blocking
					}
					// console.log(oldValueIndexes.length);
					if (oldValueIndexes.length == 0) {
						for (let i = 0; i < this.summaryAddonsService.getAccommodationSource().data.length; i++) {
							this.summaryAddonsService.getAccommodationArray()[i]['ceny'] = this.ceny[i];
							this.summaryAddonsService.getAccommodationArray()[i]['ileDodLozek'] = this.ileDodLozek[i];
							this.summaryAddonsService.getAccommodationArray()[i]['ileDostepnychPokoi'] = this.ileDostepnychPokoi[i];
							// if (this.dataSource.data[this.indexes[i]]['ceny'][this.summaryAddonsService.getAccommodationSource().data[i]['Dorosły'].count])
							this.summaryAddonsService.getAccommodationArray()[i]['Wartość'] = this.dataSource.data[this.indexes[i]]['ceny'][this.summaryAddonsService.getAccommodationSource().data[i]['Dorosły'].count].razemBrutto;
							this.summaryAddonsService.getRoomsDateFrom()[i] = data.dataOd;
							this.summaryAddonsService.getRoomsDateTo()[i] = data.dataDo;

							this.summaryAddonsService.getAccommodationArray()[i]['ileDostepnychPokoi']--;
						}
					}

					this.calculateTheCost();
					this.sendTheCost();

					//console.log(this.summaryAddonsService.getAccommodationArray());

					if (tempPokojTypNazwa.length > 0) {
						this.snackBar.open('Pokoje: ' + tempPokojTypNazwa + ' nie mogły zostać zaktualizowane.', '', {
							duration: 10000,
						});
					}
				}
				//}
			}, 2000);
		} else {
			//block data which already exist
			for (let i = 0; i < this.summaryAddonsService.getAccommodationArray().length; i++) {
				this.whichDisabled[i] = true; //table for checking if Zakwaterowanie row or Dodatki row should be disabled (because user changed range of dates)
			}
		}
	}

	mySendSc(summaryCost) {
		this.sendSC.emit(summaryCost);
	}

	setDatesArgumentsToFalse() {
		this.summaryAddonsService.setIsFirstDate(false);
		this.summaryAddonsService.setIsSecondDate(false);
	}

	dateFormat(which: number, index: number): any {
		if (which == 1) {
			return moment(this.summaryAddonsService.getRoomsDateFrom()[index]).format('DD.M.YYYY');
		} else if (which == 2) {
			return moment(this.summaryAddonsService.getRoomsDateTo()[index]).format('DD.M.YYYY');
		}
	}
}
