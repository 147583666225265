import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { toUnicode } from 'punycode';
import { BehaviorSubject } from 'rxjs';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { AddonsSettingsService } from 'src/app/pages/settings/addons-settings/addons-settings.service';
import { BasicConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { ReservationsService } from '../../../reservations/reservations.service';
import { StayinsNewService } from '../../../stayins/stayins-new/stayins-new.service';

@Component({
	selector: 'app-edit-reservation',
	templateUrl: './edit-reservation.component.html',
	styleUrls: ['./edit-reservation.component.scss'],
})
export class EditReservationComponent implements OnInit {
	public availableRoomTypes = [];
	public availableRooms = [];
	public localizationSelect = false;
	public showFirmaData: boolean = false;
	public startTime = this.data.dataOd ? moment(this.data.dataOd) : this.data.rezerwacjaDataOd ? moment(this.data.rezerwacjaDataOd) : moment(this.data.melduneDataOd);
	public endTime = this.data.dataDo ? moment(this.data.dataDo) : this.data.rezerwacjaDataDo ? moment(this.data.rezerwacjaDataDo) : moment(this.data.melduneDataDo);
	public reservationForm: FormGroup;
	public minDate = new Date();
	public counter = 0;
	public pricePlan = [];
	public totalPriceForPlan = 0;
	public isPriceFromPlan: boolean = true;
	public isToday: boolean;
	public result: string = '';
	public selectedRoom;
	public selectedType;
	public localizationArray = [];
	public fullLocalizationTypeList = [];
	public selectedPolozenieId: number;
	public editWhat: string = '';
	public resSource = [];
	public dodatkiWyswietl: any[] = [];
	public currentReservation: any = '';

	constructor(
		public appService: AppServiceService,
		private stayinsNewService: StayinsNewService,
		private reservationService: ReservationsService,
		private addonsSettingsService: AddonsSettingsService,
		public dialogRef: MatDialogRef<EditReservationComponent>,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.reservationForm = this.formBuilder.group({
			rezerwacjaDataOd: [this.startTime.format('YYYY-MM-DD')],
			rezerwacjaDataDo: [this.endTime.format('YYYY-MM-DD')],
			pobytBrutto: [parseFloat(this.data.pobytBrutto).toFixed(2)],
			passCode: [{ value: this.data.passCode, disabled: true }],
			mail: [{ value: this.data.osobaEmail, disabled: true }],
			telefon: [{ value: this.data.osobaTelefon, disabled: true }],
			osobaImiona: [{ value: this.data.osobaImiona, disabled: true }],
			osobaNazwisko: [{ value: this.data.osobaNazwisko, disabled: true }],
			pokojId: [this.data.pokojId],
			pokojtypId: [this.data.pokojtypId],
			polozenieId: [this.data.polozenieId],
			ileDoroslych: this.data.rezerwacjaIleDoroslych !== undefined ? this.data.rezerwacjaIleDoroslych : this.data.ileDoroslych,
			ileDzieci: this.data.rezerwacjaIleDzieci !== undefined ? this.data.rezerwacjaIleDzieci : this.data.ileDzieci,
			uwagiPortal: [this.data.rezerwacjaUwagiPortal ? this.data.rezerwacjaUwagiPortal : this.data.uwagiPortal],
			uwagi: [this.data.rezerwacjaUwagimemo],
			zrodlRezId: this.data.zrodlRezId ? this.data.zrodlRezId : this.data.rezerwacjaZrodloRez,
			firma: this.formBuilder.group({
				firmaId: [{ value: null, disabled: true }],
				firmaNip: [{ value: null, disabled: true }],
				firmaNazwa: [{ value: '', disabled: true }],
				firmaNrMieszkania: [{ value: '', disabled: true }],
				firmaNrDomu: [{ value: '', disabled: true }],
				firmaUlica: [{ value: '', disabled: true }],
				miastoNazwa: [{ value: '', disabled: true }],
				firmaKodPocztowy: [{ value: '', disabled: true }],
			}),
			// dodatkiNazwa: [],
			// dodatkiCena: [],
			// dodatkiIlosc: [],
		});
	}

	ngOnInit(): void {
		//console.log(this.data);
		this.stayinsNewService.getZrodlaRezerwacji().subscribe((res) => {
			this.resSource = res;
		});
		setTimeout(() => {
			this.reservationService.getReservationInfo(this.data.rezerwacjaId).subscribe(
				(res: any) => {
					this.currentReservation = res;
					if (res.firma) {
						this.showFirmaData = true;
						this.reservationForm.get(['firma']).patchValue(res.firma);
					} else {
						this.showFirmaData = false;
					}
				},
				(err) => {
					this.showFirmaData = false;
				}
			);
		}, 500);

		this.reservationForm.get('passCode').setValue(this.data.passCode);
		this.selectedType = this.data.pokojtypNazwa;
		if (this.data.rezerwacje) {
			this.data = Object.assign(this.data, this.data.rezerwacje[0]);
			this.reservationForm.get('pokojId').setValue(this.data.pokojId);
		}
		if (this.data.polozenieId) {
			this.reservationForm.get('polozenieId').setValue(this.data.polozenieId);
		}
		this.reservationForm.get('uwagiPortal').setValue(this.data.rezerwacjaUwagiPortal ? this.data.rezerwacjaUwagiPortal : this.data.uwagiPortal);
		this.reservationForm.get('uwagi').setValue(this.data.rezerwacjaUwagimemo);
		this.reservationForm.get('passCode').setValue(this.data.passCode);

		this.getInitialRoomData(this.data.pokojtypId);
		if (moment().format('MM-DD') == moment(this.reservationForm.value.rezerwacjaDataOd).format('MM-DD')) {
			this.isToday = true;
		} else {
			this.isToday = false;
		}
		this.updateRooms(this.data.pokojtypId);
		this.appService.isLocalizationOn.value === true ? (this.localizationSelect = true) : (this.localizationSelect = false);

		this.dialogRef.keydownEvents().subscribe((event) => {
			if (event.key === 'Escape') {
				this.onCancel();
			}
		});

		setTimeout(() => {
			//console.log(this.currentReservation);
			if (this.currentReservation.usluga) {
				//console.log(this.currentReservation.usluga[0]);
				for (let i = 0; i < this.currentReservation.usluga.length; i++) {
					this.dodatkiWyswietl.push({ dodatkiNazwa: 'dodatkiNazwa' + i, dodatkiCena: 'dodatkiCena' + i, dodatkiIlosc: 'dodatkiIlosc' + i });
					console.log(this.currentReservation.usluga[i].asortNazwa);
					this.reservationForm.addControl(this.dodatkiWyswietl[i].dodatkiNazwa, new FormControl(this.currentReservation.usluga[i].asortNazwa));
					this.reservationForm.addControl(this.dodatkiWyswietl[i].dodatkiCena, new FormControl(this.currentReservation.usluga[i].uslugaCenaJednBrutto));
					this.reservationForm.addControl(this.dodatkiWyswietl[i].dodatkiIlosc, new FormControl(this.currentReservation.usluga[i].uslugaIlosc));
				}
				// console.log(this.dodatkiWyswietl);
				for (let i = 0; i < this.currentReservation.usluga.length; i++) {
					this.reservationForm.controls[this.dodatkiWyswietl[i].dodatkiNazwa].disable();
					this.reservationForm.controls[this.dodatkiWyswietl[i].dodatkiCena].disable();
					this.reservationForm.controls[this.dodatkiWyswietl[i].dodatkiIlosc].disable();
				}
			}
		}, 1000);
	}

	onSubmit() {
		this.confirmDialog('Czy jesteś pewny wprowadzonych zmian?', 'formSubmit');
	}

	updateLocalization(newLocalId) {
		this.selectedPolozenieId = newLocalId.value;
		this.fullLocalizationTypeList.forEach((el) => {
			if (el.polozenieId === newLocalId.value) {
				this.reservationForm.controls['pokojId'].setValue(null);
				this.reservationForm.controls['pokojtypId'].setValue(null);
				this.availableRooms = el.pokoje;
			}
		});
	}

	setCustomPrice(event) {
		if (this.isPriceFromPlan === true) {
			this.confirmDialog(`Zamierzasz zmienić ustaloną cenę za pobyt. Kontynuować?`, 'changePrice');
		}
	}
	onCancel() {
		this.dialogRef.close(false);
	}

	confirmDialog(text, callTrigger): void {
		const dialogData = new ConfirmDialogModel('Potwierdź akcję', text);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (callTrigger === 'changePrice') {
				this.isPriceFromPlan = !dialogResult;
				this.result = dialogResult;
			}
			if (dialogResult === false && callTrigger === 'changePrice') {
				this.totalPriceForPlan ? this.reservationForm.controls['pobytBrutto'].setValue(this.totalPriceForPlan.toFixed(2)) : this.reservationForm.controls['pobytBrutto'].setValue(parseFloat(this.data.pobytBrutto).toFixed(2));
			}
			if (dialogResult === true && callTrigger === 'formSubmit') {
				let data;
				data = this.reservationForm.value;
				data['rezerwacjaId'] = this.data.rezerwacjaId;
				if (this.reservationForm.value.polozenieId == null) {
					delete data.polozenieId;
				}
				if (this.reservationForm.value.pokojId == null) {
					delete data.pokojId;
				}
				if (!this.isPriceFromPlan) {
					data['kwotaZaPobyt'] = this.reservationForm.value.pobytBrutto;
					data['rezerwacjaPlan'] = 0;
				} else {
					data['rezerwacjaPlan'] = 1;
				}
				data.rezerwacjaDataOd = moment(data.rezerwacjaDataOd).format('YYYY-MM-DD');
				data.rezerwacjaDataDo = moment(data.rezerwacjaDataDo).format('YYYY-MM-DD');
				data.pokojId = this.reservationForm.value.pokojId;
				data.rezerwacjaZrodloRez = this.reservationForm.value.zrodlRezId;
				data.rezerwacjaUwagimemo = this.reservationForm.get('uwagi').value;
				data.rezerwacjaUwagiPortal = this.reservationForm.get('uwagiPortal').value;
				this.stayinsNewService.editReservation(data).subscribe((res) => {
					if (res.status === 200) {
						this.dialogRef.close(true);
					}
				});
			}
		});
	}

	updateResCost(event) {
		this.totalPriceForPlan = 0;
		moment().format('MM-DD') == moment(this.reservationForm.value.rezerwacjaDataOd).format('MM-DD') ? (this.isToday = true) : (this.isToday = false);
		let date1 = moment(this.reservationForm.value.rezerwacjaDataOd, 'DD-MM-YYYY');
		let date2 = moment(this.reservationForm.value.rezerwacjaDataDo, 'DD-MM-YYYY');
		let data = {
			dataOd: moment(this.reservationForm.value.rezerwacjaDataOd).format('YYYY-MM-DD'),
			dataDo: moment(this.reservationForm.value.rezerwacjaDataDo).format('YYYY-MM-DD'),
			czySniadania: this.data.rezerwacjaSniadanie ? this.data.rezerwacjaSniadanie : 0,
			pokojtypId: this.reservationForm.value.pokojtypId,
			dlugoscPobytu: date2.diff(date1, 'days'),
			ileDoroslych: this.reservationForm.value.ileDoroslych,
			ileDzieci: this.reservationForm.value.ileDzieci.toString(),
		};
		if (data.dataOd === data.dataDo) {
			date2 = moment(this.reservationForm.value.rezerwacjaDataDo, 'DD-MM-YYYY').add(1, 'd');
			this.reservationForm.controls['rezerwacjaDataDo'].setValue(date2.format('YYYY-MM-DD'));
			data.dataDo = moment(this.reservationForm.value.rezerwacjaDataDo).format('YYYY-MM-DD');
		}
		this.counter++;
		if (this.counter == 2) {
			this.stayinsNewService.getRoomPrices(data).subscribe((res) => {
				this.pricePlan = res;
				res.forEach((element) => {
					this.totalPriceForPlan = this.totalPriceForPlan + element.cenaBrutto;
					this.reservationForm.controls['pobytBrutto'].setValue(this.totalPriceForPlan.toFixed(2));
					this.isPriceFromPlan = true;
				});
			});
			this.counter = 0;
		}
	}

	updateRoomIds(event) {
		if (event.value.polozenieId) {
			this.reservationForm.controls['polozenieId'].setValue(event.value.polozenieId);
		}
	}

	updateRooms(event) {
		this.availableRooms = [];
		if (event.value) {
			this.reservationForm.controls['pokojId'].setValue(null);
			this.reservationForm.controls['pokojtypId'].setValue(event.value);
			this.fullLocalizationTypeList.forEach((element) => {
				if (element.pokojtypId === event.value && this.selectedPolozenieId === element.polozenieId) {
					this.availableRooms = element.pokoje;
				}
			});
		} else {
			this.reservationForm.controls['pokojId'].setValue(null);
			this.reservationForm.controls['pokojtypId'].setValue(event);
			this.fullLocalizationTypeList.forEach((element) => {
				if (element.pokojtypId === event && this.selectedPolozenieId === element.polozenieId) {
					this.availableRooms = element.pokoje;
				}
			});
		}
	}

	resetForm() {
		this.reservationForm.controls['rezerwacjaDataOd'].setValue(this.data.dataOd);
		this.reservationForm.controls['rezerwacjaDataDo'].setValue(this.data.dataDo);
		this.reservationForm.controls['pobytBrutto'].setValue(this.data.pobytBrutto);
		this.reservationForm.controls['polozenieId'].setValue(this.data.polozenieId);
		this.reservationForm.controls['pokojtypId'].setValue(this.data.pokojtypId);
		this.reservationForm.controls['pokojId'].setValue(this.data.pokojId);
	}

	getInitialRoomData(pokojTypId?: number) {
		let dates = {
			dataDo: this.data.dataDo ? this.data.dataDo : this.data.rezerwacjaDataDo ? this.data.rezerwacjaDataDo : this.data.meldunekDataDo,
			dataOd: this.data.dataOd ? this.data.dataOd : this.data.rezerwacjaDataOd ? this.data.rezerwacjaDataOd : this.data.meldunekDataOd,
		};
		let defaultRoomType = this.data.pokojtypId;
		let defaultPolozenieId = this.data.polozenieId;
		this.stayinsNewService.getDostepneTypy(dates).subscribe(
			(res) => {
				this.fullLocalizationTypeList = res;
				if (pokojTypId) {
					this.fullLocalizationTypeList.forEach((element) => {
						if (element.pokojtypId === pokojTypId && this.selectedPolozenieId === element.polozenieId) {
							this.availableRooms = element.pokoje;
						}
					});
				}
				this.localizationArray = [];
				res.forEach((element) => {
					this.localizationArray.push({
						polozenieNazwa: element.polozenieNazwa,
						polozenieId: element.polozenieId,
					});
				});
				if (this.localizationSelect === false) {
					this.availableRoomTypes = res;
				} else {
					res.forEach((el) => {
						if (el.polozenieId === this.data.polozenieId) {
							this.availableRoomTypes.push(el);
						}
					});
					this.reservationForm.controls['pokojtypId'].setValue(defaultRoomType);
					const roomsFromSpecificType = this.availableRoomTypes.filter((rez) => {
						if (defaultPolozenieId) {
							return rez.pokojtypId == defaultRoomType || rez.polozenieId == defaultPolozenieId;
						} else {
							return rez.pokojtypId == defaultRoomType;
						}
					})[0];
					if (roomsFromSpecificType.length >= 1) {
						this.availableRooms = roomsFromSpecificType[0].pokoje;
					} else {
						this.availableRooms = [];
					}
					this.availableRoomTypes.forEach((element) => {
						if (element.pokojtypId === this.data.pokojtypId) {
							this.availableRooms = element.pokoje;
						}
					});
					this.availableRooms.push({
						pokojId: this.data.rezerwacje ? this.data.rezerwacje[0].pokojId : this.data.pokojId,
						pokojNazwa: this.data.rezerwacje ? this.data.rezerwacje[0].pokojNazwa : this.data.pokojNazwa,
					});
				}
				this.reservationForm.controls['pokojId'].setValue(this.data.pokojId);
			},
			(error) => {
				console.log('Error happened' + error);
			}
		);
		moment().format('MM-DD') == moment(this.reservationForm.value.rezerwacjaDataOd).format('MM-DD') ? (this.isToday = true) : (this.isToday = false);
	}
}
