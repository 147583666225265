import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { HotelModules } from 'src/app/auth/models/hotels-list.model';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { SummaryAddonsService } from 'src/app/pages/reception/stayins/stayins-new/reservation-table/summary-addons.service';
import { AssigningComponent } from 'src/app/pages/settings/assigning/assigning.component';
import { ROUTES } from '../../shared/models/routes-links.model';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
	providers: [AssigningComponent],
})
export class NavbarComponent implements OnInit {
	private listTitles: any[];
	location: Location;
	mobile_menu_visible: any = 0;
	// private toggleButton: any;
	public sidebarVisible: boolean = false;
	public isHKNewVisible: boolean = false;
	public isReservationNewVisible: boolean = false;
	public isAssignmentVisible: boolean = false;
	public isCollapsed = true;
	public activeModules = [];
	public isAdmin;
	closeResult: string;
	userNameSubscription: Subscription;
	userName: string;
	constructor(
		location: Location,
		private element: ElementRef,
		private router: Router,
		private modalService: NgbModal,
		private authService: AuthService,
		private translate: TranslateService,
		public sidebarService: SidebarService,
		public appService: AppServiceService,
		public dialog: MatDialog,
		public assignmentDialog: AssigningComponent,
		public summaryAddonsService: SummaryAddonsService
	) {
		this.location = location;
		// this.appService.getApiVersion().subscribe((res) => {
		//   this.appService.apiVersion = res.apiVersion.replace("-SNAPSHOT", "");
		//   if (parseInt(this.appService.apiVersion.replace(/\./g, "")) >= 5618) {
		//     this.appService.getPmsModuleConfig().subscribe((modules) => {
		//       if (modules.length >= 1) {
		//         this.activeModules = [];
		//         modules.map((module) => {
		//           if (module.active == true) {
		//             this.activeModules.push(module.name);
		//             if (module.name == "reception")
		//               this.isReservationNewVisible = true;
		//             if (module.name == "housekeeping") this.isHKNewVisible = true;
		//           }
		//         });
		//       }
		//     });
		//   }
		// });
	}
	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		var navbar = document.getElementsByClassName('navbar')[0];
		if (window.innerWidth < 993 && !this.isCollapsed) {
			navbar?.classList.add('bg-white');
			navbar?.classList.remove('navbar-transparent');
		} else {
			navbar?.classList.remove('bg-white');
			navbar?.classList.add('navbar-transparent');
		}
	};
	ngOnInit() {
		this.isAdmin = this.authService.user.value.isAdmin;
		this.activeModules = HotelModules[this.authService.user.value.hotelCode];
		if (this.isAdmin === true) {
			this.isAssignmentVisible = true;
		}
		if (this.activeModules?.indexOf('reception') != -1 || this.activeModules?.indexOf('all') != -1) {
			this.isReservationNewVisible = true;
		}
		if (this.activeModules?.indexOf('housekeeping') != -1 || this.activeModules?.indexOf('all') != -1) {
			this.isHKNewVisible = true;
		}
		window.addEventListener('resize', this.updateColor);
		this.listTitles = ROUTES.filter((listTitle) => listTitle);
		const navbar: HTMLElement = this.element.nativeElement;
		this.sidebarService.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

		this.router.events.subscribe((event) => {
			// this.sidebarClose();
			if (event instanceof NavigationEnd) {
				this.sidebarService.sidebarVisible
					? this.sidebarService.sidebarOpen()
					: () => {
							this.sidebarService.sidebarClose();
							var $layer: any = document.getElementsByClassName('close-layer')[0];
							if ($layer) {
								$layer.remove();
								this.sidebarService.mobile_menu_visible = 0;
							}
					  };
			}
		});

		this.userNameSubscription = this.authService.user.subscribe((user) => {
			if (user) {
				this.userName = user.username;
			}
		});
		this.sidebarService.sidebarToggle();
		if (this.sidebarService.isMobileMenu() == true) {
			this.sidebarService.sidebarClose();
			this.sidebarService.toggleButton.classList.remove('toggled');
		}
	}

	collapse() {
		this.isCollapsed = !this.isCollapsed;
		const navbar = document.getElementsByTagName('nav')[0];
		if (!this.isCollapsed) {
			navbar.classList.remove('navbar-transparent');
			navbar.classList.add('bg-white');
		} else {
			navbar.classList.add('navbar-transparent');
			navbar.classList.remove('bg-white');
		}
	}

	openAssignmentDialog(): void {
		const dialogRef = this.dialog.open(AssigningComponent, {
			maxWidth: '30vw',
			autoFocus: false,
			panelClass: 'custom-dialog-container',
		});
	}

	onRedirect(path): void {
		this.router.navigate([path]);
	}

	getTitle() {
		let titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === '#') {
			titlee = titlee.slice(1);
		}
		let route = titlee.split('/').slice(-1);
		if (route[0]) {
			return route[0];
		}
		return 'Dashboard';
	}

	open(content) {
		this.modalService.open(content, { windowClass: 'modal-search' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	onLogout() {
		this.userNameSubscription.unsubscribe();
		this.authService.logout();
	}

	refresh() {
		//window.location.reload();
		//clear all Zakwaterowanie and Dodatki
		this.summaryAddonsService.summaryAddons = 0;
		this.summaryAddonsService.addons.splice(0);
		this.summaryAddonsService.addonsTableNumber.splice(0);
		this.summaryAddonsService.setAddonsTableNumber([]);
		this.summaryAddonsService.setAddons([]);
		this.summaryAddonsService.przydzielonePokojeNumberTable.splice(0);
		this.summaryAddonsService.addonsTablePointerNumber.splice(0);
		this.summaryAddonsService.addonsTableCounter = 0;
		this.summaryAddonsService.setTempAccommodationArray([]);
		this.summaryAddonsService.setWhichTable([]);
		this.summaryAddonsService.setAddonsTableStart(true);
		this.summaryAddonsService.setAddonsCounter(0);
		this.summaryAddonsService.setTodayYearPlusOne(0);
		this.summaryAddonsService.setAddonsTableCounter(0);
		this.summaryAddonsService.setAddonsTablePointerNumber([]);
		//delete data for Zakwaterowanie
		this.summaryAddonsService.setAccommodationArray([]);
		this.summaryAddonsService.setAccommodationSource(new MatTableDataSource(this.summaryAddonsService.getAccommodationArray()));
		this.summaryAddonsService.setSummaryCost(0);
	}
}
