import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ResetPasswordData } from 'src/app/auth/models/auth-response-data.model';
import { SetNewPasswordService } from './set-new-password.service';

@Component({
    selector: 'app-set-new-password',
    templateUrl: './set-new-password.component.html',
    styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {
    public setNewPasswordForm: FormGroup | null = null;
    private newPasswordDatas: any[] = [];
    constructor(
        private _formBuilder: FormBuilder,
        private setNewPasswordService: SetNewPasswordService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {
        this.setNewPasswordForm = this._formBuilder.group({
            accountType: ['PMS', Validators.required],
            employeeUsername: ['', Validators.required],
            // secret: ['', Validators.required],
            password: ['', Validators.required],
            repeatePassword: ['', Validators.required],
            lang: ['pl', Validators.required],
            hotelCode: ['', Validators.required],
        });

        this.setNewPasswordService.newPasswordDatas.subscribe((data) => {
            // console.log(data, 'newPasswordDatas');
            if (data !== null) {
                this.newPasswordDatas = data;
                this.setNewPasswordForm.controls.employeeUsername.setValue(
                    this.newPasswordDatas[0]['employeeUsername']
                );
                this.setNewPasswordForm.controls.hotelCode.setValue(
                    this.newPasswordDatas[0]['hotelCode']
                );
            } else {
                this.router.navigate(['/auth']);
            }
        });

        // this.route.params.subscribe((params: Params) => {
        //     if (params['employeeUsername'])
        //         this.setNewPasswordForm.controls.employeeUsername.setValue(
        //             params['employeeUsername']
        //         );
        //     if (params['secret'])
        //         this.setNewPasswordForm.controls.secret.setValue(
        //             params['secret']
        //         );
        //     if (params['hotelCode'])
        //         this.setNewPasswordForm.controls.hotelCode.setValue(
        //             params['hotelCode']
        //         );
        // });
    }

    ngOnInit(): void {}
    onSubmitChangePassword(): void {
        this.setNewPasswordForm.controls.password.setErrors(null);
        this.setNewPasswordForm.controls.repeatePassword.setErrors(null);
        if (!this.setNewPasswordForm.valid) return;
        if (
            this.setNewPasswordForm.value.password !==
            this.setNewPasswordForm.value.repeatePassword
        ) {
            this.setNewPasswordForm.controls.repeatePassword.setErrors({
                diffrent: 'Niezgodne hasła',
            });
            this.setNewPasswordForm.controls.repeatePassword.setErrors({
                diffrent: 'Niezgodne hasła',
            });
            this._snackBar.open('Proszę poprawić hasła.', '', {
                duration: 5000,
            });
            return;
        }
        let resetPasswordRequests = [];
        this.newPasswordDatas.forEach((resetObject) => {
            if (resetObject.secret) {
                let resetPasswordData: ResetPasswordData = {
                    accountType: this.setNewPasswordForm.value.accountType,
                    employeeUsername: resetObject.employeeUsername,
                    secret: resetObject.secret,
                    newPassword: this.setNewPasswordForm.value.password,
                    langKod: this.setNewPasswordForm.value.accountType,
                };
                resetPasswordRequests.push(
                    this.authService.resetPassword(
                        resetPasswordData,
                        resetObject.hotelCode
                    )
                );
            }
        });

        forkJoin(resetPasswordRequests).subscribe((res) => {
            let successCount = res.filter((response) => {
                return (
                    response['statusCode'] === 2 ||
                    response['statusText'] === 'Hasło zostało zmienione'
                );
            });
            if (successCount.length == res.length) {
                // all password changed

                this._snackBar.open(
                    'Hasło zostało poprawnie zmienione. Prosimy się zalogować ponownie.',
                    'OK',
                    { duration: 5000 }
                );
                this.router.navigate(['/auth']);

                // this.authService
                //     .loginToMultiple(
                //         resetPasswordRequests[0].employeeUsername,
                //         resetPasswordRequests[0].password,
                //         this.setNewPasswordService.getRelatedHotels(
                //             resetPasswordRequests[0].hotelCode
                //         )
                //     )
                //     .subscribe((response) => {
                //         let isAnyError = response.filter((el) => {
                //             return el.status == 400 || el.status == 403;
                //         });
                //         if (isAnyError.length == 0) {
                //             this.router.navigate(['/auth']);
                //             return;
                //         }
                //         this._snackBar.open(
                //             'Hasło zostało poprawnie zmienione.',
                //             'OK',
                //             { duration: 5000 }
                //         );
                //         this.router.navigate(['/auth']);
                //     });
            } else {
                // some error, just contact with admin
                this._snackBar.open(
                    'Hasło nie mogło zostać zmienione. Skontaktuj się z administratorem.',
                    'OK',
                    {}
                );
                this.router.navigate(['/auth']);
            }
        });

        // let relatedHotels = this.setNewPasswordService.getRelatedHotels(
        //     this.setNewPasswordForm.value.hotelCode
        // );
        // let resetPasswordData: ResetPasswordData = {
        //     accountType: this.setNewPasswordForm.value.accountType,
        //     employeeUsername: this.setNewPasswordForm.value.employeeUsername,
        //     secret: this.setNewPasswordForm.value.secret,
        //     newPassword: this.setNewPasswordForm.value.password,
        //     langKod: this.setNewPasswordForm.value.accountType,
        // };
        // this.authService
        //     .resetPassword(resetPasswordData, relatedHotels)
        //     .subscribe(
        //         (response) => {
        //             this._snackBar.open('Hasło zostało zmienione', '', {
        //                 duration: 5000,
        //             });
        //             this.router.navigate(['/auth']);
        //         },
        //         (error) => {
        //             console.log(error.error.message);
        //             if (error.error.message.indexOf('Link straci') !== -1) {
        //                 this.router.navigate(['/auth']);
        //                 this._snackBar.open(error.error.message, 'OK');
        //             } else if (
        //                 error.error.message.indexOf(
        //                     'Niepoprawna suma kontrolna'
        //                 ) !== -1
        //             ) {
        //                 this.router.navigate(['/auth']);
        //                 this._snackBar.open('Niepoprawne dane.', 'OK', {
        //                     duration: 5000,
        //                 });
        //             } else {
        //                 this._snackBar.open('Prosimy spróbować ponownie', '', {
        //                     duration: 5000,
        //                 });
        //             }
        //         }
        //     );
    }
}
