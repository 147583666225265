import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { User } from "src/app/auth/models/user.model";
import { ParamService } from "src/app/shared/services/param.service";
import { AuthService } from "../../../auth/auth.service";
import { EdytujZlecenie, Room } from "../housekeeping.interface";
import { ServiceOrderService } from "../service-order/service-order.service";
import { RoomServiceService } from "./room-service.service";


class ZdjecieZlecenia {
  constructor(public imageBase64: string, public zlecenieId) { }
}

@Component({
  selector: "app-room-service",
  templateUrl: "./room-service.component.html",
  styleUrls: ["./room-service.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ]
})
export class RoomServiceComponent implements OnInit {
  public user: User;
  public room: Room;
  public editData: EdytujZlecenie;
  public fileListArray: any[] = [];
  public fileList: any;
  public fileListBase: any[] = [];
  public fileListImages: any[] = [];
  public uprawnieniList: any[];
  public serviceBlockType: any[];
  public fromParent;
  public pokojNr: string;
  public roomServiceForm: FormGroup;
  public today = moment().format("YYYY-MM-DD");
  public tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
  public uF;
  public inProgress: boolean = true;
  public priorityStatus: string = 'normalny';
  public blockadeStatus: string = 'włączona'
  public filteringSchedule: number = 0;
  public filteringBlock: number = 0;
  public spinnerActive: boolean;
  public serviceId: number;
  public defaultTimeOfBlockadeFrom: string ="";
  public defaultTimeOfBlockadeTo: string ="";

  constructor(
    private dialogRef: MatDialogRef<RoomServiceComponent>,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    private roomServiceService: RoomServiceService,
    private _snackBar: MatSnackBar,
    private serviceOrderService: ServiceOrderService,
    @Inject(MAT_DIALOG_DATA) data,
    private param: ParamService
  ) {
    this.authService.user.subscribe((user) => {
      this.user = user;
    });
    this.getServiceActions();
    this.getUprawnieniList();

    this.fromParent = data
  }

  ngOnInit(): void {
    this.spinnerActive = true;
    this.appendData();
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.onClickCancel();
      }
  });
  //this.param.getDomyslnaGodzinaPrzyjazdu().subscribe((res) => {
  //  this.defaultTimeOfBlockadeFrom=res.paramWartosc;
  //});
  
  this.filteringSchedule=1;
  this.defaultTimeOfBlockadeFrom="14:00:00";
  //this.param.getDomyslnaGodzinaWyjazdu().subscribe((res) => {
  //  this.defaultTimeOfBlockadeTo=res.paramWartosc;
  //});
  this.defaultTimeOfBlockadeTo="12:00:00";
  }

  onClickCancel(): void {
    this.dialogRef.close()
  }

  changePriority(event) {
    if (event.checked === true) {
      this.priorityStatus = 'wysoki'
    } else {
      this.priorityStatus = 'normalny'
    }
  }

  changeBlockade(event) {
    if (event.checked === true) {
      this.blockadeStatus = 'włączona'
    } else {
      this.blockadeStatus = 'wyłączona'
    }
  }

  processFile(imageInput: any) {
    const file: File = imageInput;
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      this.uF = event.target.result;
      let tmpBase = event.target.result;
      let zdjecieZlecenia = new ZdjecieZlecenia(tmpBase, null);
      let fileImage = {
        src: tmpBase,
        type: file.type,
        alt: null,
        name: file.name,
      };
      this.fileListBase.push(zdjecieZlecenia);
      this.resizeImage(30, tmpBase).then((imageResized) => {
        fileImage.src = imageResized;
      });
      this.fileListImages.push(fileImage);
    });

    reader.readAsDataURL(file);
  }

  appendData() {
    let serviceData = this.fromParent.serviceOrderObject;
    if (serviceData) {
      this.serviceId = serviceData.zlecenieId
      this.filteringSchedule = serviceData.zlecenieStatus;
      this.filteringBlock = serviceData.zleceniePriorytet;
    }
    if(serviceData  && serviceData.pokojNazwa){
      this.pokojNr = serviceData.pokojNazwa
    }
    if (serviceData && serviceData.zlecenieStan === 1  ) {
      this.inProgress = false
      this.pokojNr = serviceData.pokojNazwa
    }

    if (serviceData) {
      this.roomServiceForm = this.formBuilder.group({
        osobaId: [serviceData.osobaId, Validators.required],
        pokojId: this.fromParent.room.pokojId,
        zlecenieDataOd: serviceData.zlecenieDataOd,
        zlecenieDataDo: serviceData.zlecenieDataDo,
        zlecenieCzasOd: [serviceData.zlecenieCzasOd, Validators.required],
        zlecenieCzasDo: [serviceData.zlecenieCzasDo, Validators.required],
        zlecenieRodzajId: [serviceData.zlecenieRodzajId, Validators.required],
        zlecenieOpis: serviceData.zlecenieOpis,
        zlecenieOpis2: serviceData.zlecenieOpis2,
        zlecenieStan: serviceData.zlecenieStan,
        zlecenieStatus: serviceData.zlecenieStatus,
        zleceniePriorytet: serviceData.zleceniePriorytet
      });
      this.getFiles(serviceData.zlecenieId);
      if(this.fromParent.modalType == 'detail' || this.fromParent.serviceOrderObject.zlecenieStan){
        this.roomServiceForm.controls['osobaId'].disable();
        this.roomServiceForm.controls['zlecenieDataOd'].disable();
        this.roomServiceForm.controls['zlecenieDataDo'].disable();
        this.roomServiceForm.controls['zlecenieRodzajId'].disable();
        this.roomServiceForm.controls['zlecenieOpis'].disable();
        this.roomServiceForm.controls['zlecenieOpis2'].disable();
        this.roomServiceForm.controls['zlecenieOpis2'].disable();
        this.roomServiceForm.controls['zlecenieStatus'].disable();
        this.roomServiceForm.controls['zleceniePriorytet'].disable();
      }
    } else {
      this.spinnerActive = false;
      this.roomServiceForm = this.formBuilder.group({
        osobaId: [null, Validators.required],
        pokojId: this.fromParent.room.pokojId,
        zlecenieDataOd: [this.today, Validators.required],
        zlecenieDataDo: [this.tomorrow, Validators.required],
        zlecenieCzasOd: null,
        zlecenieCzasDo: null,
        zlecenieRodzajId: [null, Validators.required],
        zlecenieOpis: "",
        zlecenieOpis2: "",
        zlecenieStatus: 0,
        zlecenieStan: 0,
        zleceniePriorytet: 0
      });
    }
    this.roomServiceForm.value.zleceniePriorytet === 0 ? this.priorityStatus = 'normalny' : this.priorityStatus = 'wysoki'
    if (this.fromParent.zlecenieStan == 1) {
      this.roomServiceForm.controls['zlecenieOpis'].disable()
      this.roomServiceForm.controls['zlecenieOpis2'].disable()
    }
  }

  makeFilesList(files: FileList) {
    this.fileList = files;
    this.fileListArray = Array.from(this.fileList);
    this.fileListArray.forEach((element, index) => {
      var file = this.fileList[index];
      if (this.fileList && file) {
        this.resizeImage(50, file);
        this.processFile(file);
      }
    });
  }

  removeFile(fileIndex) {
    this.fileListArray.splice(fileIndex, 1);
    this.fileListBase.splice(fileIndex, 1);
    this.fileListImages.splice(fileIndex, 1);
  }

  onClickAddService() {
    let data = this.roomServiceForm.value;
    data.zleceniePriorytet !== true ? data.zleceniePriorytet = 0 : data.zleceniePriorytet = 1;
    data.zlecenieStatus !== true ? data.zlecenieStatus = 0 : data.zlecenieStatus = 1;
    data.zlecenieStan = data.zlecenieStan == true ? 1 : 0;
    data.zlecenieCzasOd = this.defaultTimeOfBlockadeFrom;
    data.zlecenieCzasDo = this.defaultTimeOfBlockadeTo;
    data.zlecenieDataOd = moment(this.roomServiceForm.controls.zlecenieDataOd.value).format('YYYY-MM-DD')
    data.zlecenieDataDo = moment(this.roomServiceForm.controls.zlecenieDataDo.value).format('YYYY-MM-DD')
    data.zalozylId = this.roomServiceForm.value.osobaId
    this.roomServiceService.addServiceRoom(data).subscribe((response) => {
      if (response && response.status == 200) {
        this._snackBar.open(response.entity, '', {duration: 2000})
        let tmpZlecenieId = response.metadata.zlecenieId[0];
        this.addImagesToServiceRoom(tmpZlecenieId);
        this.dialogRef.close('Edited');
      }
    });
  }

  onClickEditService() {
    let zlecenieStatus = this.roomServiceForm.value.zlecenieStatus;
    if (this.roomServiceForm.value.zlecenieStatus === true) {
      zlecenieStatus = 1
    } else if (this.roomServiceForm.value.zlecenieStatus === false) {
      zlecenieStatus = 0
    }
    this.editData = {
      osobaId: this.roomServiceForm.value.osobaId,
      zleceniePriorytet: this.roomServiceForm.value.zleceniePriorytet == true ? 1 : 0,
      zlecenieId: this.fromParent.serviceOrderObject.zlecenieId,
      zlecenieDataOd: moment(this.roomServiceForm.controls.zlecenieDataOd.value).format('YYYY-MM-DD'),
      zlecenieCzasDo: this.roomServiceForm.value.zlecenieCzasDo,
      zlecenieDataDo: moment(this.roomServiceForm.value.zlecenieDataDo).format('YYYY-MM-DD'),
      zlecenieOpis2: this.roomServiceForm.value.zlecenieOpis2,
      zlecenieStan: this.roomServiceForm.value.zlecenieStan == true ? 1 : 0,
      zlecenieStatus: zlecenieStatus,
      zlecenieOpis: this.roomServiceForm.value.zlecenieOpis,
      zlecenieRodzaj: this.roomServiceForm.value.zlecenieRodzaj
    };
    if(zlecenieStatus == 1){
      this.editData["zlecenieDataOd"] = moment(this.roomServiceForm.value.zlecenieDataOd).format('YYYY-MM-DD');
    }
    this.roomServiceService
      .editServiceRoom(this.editData)
      .subscribe((response) => {
        if (response.status == 200) {
          this.addImagesToServiceRoom(this.editData.zlecenieId);
          this._snackBar.open(
            'Zlecenie zostało edytowane', '', {
              duration: 2000,
            })
          this.dialogRef.close({status:'Edited',data : this.editData});
        }
      });
  }

  onClickResolveService(): void {
    let serviceData = this.fromParent.serviceOrderObject;
    let editData = {
      osobaId: this.roomServiceForm.value.osobaId,
      zlecenieStatus: 0,
      zlecenieCzasDo: "00:00:00",
      zlecenieId: this.fromParent.serviceOrderObject.zlecenieId,
      zlecenieDataDo: moment(serviceData.zlecenieDataDo).format('YYYY-MM-DD'),
      zlecenieCzasOd: "00:00:00",
      zlecenieDataOd: moment(serviceData.zlecenieDataOd).format('YYYY-MM-DD'),
      zlecenieStan: 1,
      zlecenieOpis2: this.roomServiceForm.value.zlecenieOpis2,
      zlecenieOpis: this.roomServiceForm.value.zlecenieOpis,
      zleceniePriorytet: this.roomServiceForm.value.zleceniePriorytet == true ? 1 : 0,

    };
    this.roomServiceService
      .editServiceRoom(editData)
      .subscribe((response) => {
        if (response.status === 200) {
          this._snackBar.open(
            'Zlecenie zostało zamknięte', '', {
              duration: 2000,
            })
          this.dialogRef.close({status:'Resolved', data : editData});
        }
      });

      //setTimeout(()=>{
     // window.location.reload();
      //},2000);
  }

  getUprawnieniList(): void {
    this.roomServiceService.getUprawnieniList().subscribe((res) => {
      this.uprawnieniList = res;
    });
  }

  getServiceActions(): void {
    let rawResponse = [];
    this.roomServiceService.getServiceActions().subscribe((res) => {
      rawResponse = res;
      this.serviceBlockType = rawResponse.filter(({nazwa}) => !nazwa.includes('Blokada pokoju'))
    });
  }

  addImagesToServiceRoom(zlecenieId): void {
    let successAddedCount = 0;
    this.fileListBase.forEach((zdjecieZleceniaOBJ, index) => {

      zdjecieZleceniaOBJ.zlecenieId = zlecenieId;
      this.roomServiceService
        .addPhotoToServiceRoom(zdjecieZleceniaOBJ)
        .subscribe((res) => {
          if (res.status == 200) {
            successAddedCount++;
          }
          if (index == this.fileListBase.length - 1 && res.status == 200) {
            if (res.status== 200){
              this._snackBar.open(
                `Dodano ${this.fileListBase.length}/${this.fileListBase.length} zdjęć`, '', {
                  duration: 3000,
                }
              );
            }else{
              this._snackBar.open(
                `Wystąpił błąd przy dodawaniu zdjęć`, '', {
                  duration: 3000,
                }
              );
            }
          }
        });
    });
  }

  resizeImage(procent: number, image) {
    var img = new Image();
    img.src = image;
    return new Promise((resolve, reject) => {
      img.onload = function () {
        var canvas = document.createElement("canvas");
        img.width = (img.width * procent) / 100;
        img.height = (img.height * procent) / 100;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL("image/png"));
      };
    });
  }

  getFiles(zlecenieId: number) {
    this.roomServiceService
      .getPhotoToServiceRoom(zlecenieId)
      .subscribe((response) => {
        response.forEach((imageObject) => {
          this.fileListImages.push({ src: imageObject.imageBase64, imageId: imageObject.imageId });
        });
        this.spinnerActive = false;
      });
  }
}
