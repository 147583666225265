import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { ComponentsModule } from './components/components.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthComponent } from './layouts/auth-layout/auth.component';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LOCALE_ID } from '@angular/core';
import '@angular/common/locales/global/pl';
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MultipropertyComponent } from './layouts/multiproperty/multiproperty.component';
import { ReservationTableComponent } from './pages/reception/stayins/stayins-new/reservation-table/reservation-table.component';
import { SetNewPasswordComponent } from './layouts/auth-layout/set-new-password/set-new-password.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatMenuModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'pl',
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
        }),
        PerfectScrollbarModule,
    ],
    exports: [TranslateModule, SharedModule],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthComponent,
        MultipropertyComponent,
        SetNewPasswordComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'pl' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        NgbActiveModal,
        {
            provide: MatDialogRef,
            useValue: {},
        },
        ReservationTableComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
