import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from 'src/app/auth/auth.service';
import { ROUTES } from '../../shared/models/routes-links.model';
import { version } from 'package.json';
import { HotelModules, HOTELS, HotelsRelations } from '../../../app/auth/models/hotels-list.model';
import { SidebarService } from './sidebar.service';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@ViewChildren('links') private links: QueryList<ElementRef>;
	menuItems: any[];
	credentialsSubscription: Subscription;
	$hotelName: Subscription;
	routeSubscription: Subscription;
	hkMode: boolean;
	public appVersion = version;
	public hotelName: string = '';
	public hotelList: any = [];
	public activeModules = [];
	versionDate: Date = new Date();
	public selectHotelForm: FormGroup = null;
	public ifSolMarina: boolean = false;

	constructor(private authService: AuthService, private renderer: Renderer2, private router: Router, public sidebarService: SidebarService, public appService: AppServiceService, private formBuilder: FormBuilder) {
		this.selectHotelForm = this.formBuilder.group({
			selectedHotel: ['', Validators.required],
		});
		this.authService.hotelList.subscribe((hotels) => {
			if (hotels) {
				this.hotelList = hotels;
			}
		});
	}

	ngOnInit(): void {
		// Set links on the navigation
		this.credentialsSubscription = this.authService.credentials.subscribe((res) => {
			// Filter routes to hide unnecessary nav items which depends on credentials
			// this.hkMode = JSON.parse(localStorage.getItem("userData")).isAdmin

			this.$hotelName = this.authService.user.subscribe((user) => {
				if (user) {
					this.hotelName = user.hotelName;
					// this.hotelList = HOTELS.filter((hotelObj) => {
					//     return (
					//         HotelsRelations[user.hotelCode]?.indexOf(
					//             hotelObj.hotelCode
					//         ) != -1 || hotelObj.hotelCode == user.hotelCode
					//     );
					// });

					this.appService.getPmsModuleConfig().subscribe((res) => {
						if (res.length >= 1) {
							this.activeModules = [];
							res.map((module) => {
								if (module.active == true) {
									this.activeModules.push(module.name);
								}
							});

							this.menuItems = ROUTES.filter((menuItem) => {
								if (this.hkMode) {
									return menuItem.hkModeEnabled;
								}
								if (this.activeModules?.indexOf(menuItem.title.toLowerCase()) == -1 && this.activeModules?.indexOf('all') == -1) {
									return false;
								}
								return menuItem;
							});

							// Add property to control collapse behavior of nav items
							for (const menuItem of this.menuItems) {
								// Set active item open after reload
								if (menuItem.path.split('/').slice(1, 2)[0] === this.router.url.split('/').slice(1, 2)[0]) {
									menuItem.isHide = false;
									// Close all unactive items
								} else {
									menuItem.isHide = true;
								}
							}
						}
					});
				}
			});
			this.selectHotelForm.get(['selectedHotel']).setValue(this.hotelName);
			this.hkMode = res.hkMode;
			//jezeli istnieje
			// this.appService.getApiVersion().subscribe(res => {
			//   console.log(res);
			//   this.appService.apiVersion = res.apiVersion.replace('-SNAPSHOT', '');
			//   this.appService.apiVersion;
			//   if (this.appService.apiVersion.replace(/\./g, '').toString().slice(0, 3) > 561 || parseInt(this.appService.apiVersion.replace(/\./g, '')) >= 5618) {

			//   }
			// })

			//stare patrzenie na moduly
			this.menuItems = ROUTES.filter((menuItem) => {
				if (this.hkMode) {
					return menuItem.hkModeEnabled;
				}
				if (HotelModules[this.authService.user.value.hotelCode]?.indexOf(menuItem.title.toLowerCase()) == -1 && HotelModules[this.authService.user.value.hotelCode]?.indexOf('all') == -1) {
					return false;
				}
				return menuItem;
			});

			// Add property to control collapse behavior of nav items
			for (const menuItem of this.menuItems) {
				// Set active item open after reload
				if (menuItem.path.split('/').slice(1, 2)[0] === this.router.url.split('/').slice(1, 2)[0]) {
					menuItem.isHide = false;
					// Close all unactive items
				} else {
					menuItem.isHide = true;
				}
			}
		});

		// Control nav items collapse on route change
		this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			setTimeout(() => {
				for (const menuItem of this.menuItems) {
					// Set active item open after reload
					if (menuItem.path.split('/').slice(1, 2)[0] === this.router.url.split('/').slice(1, 2)[0]) {
						menuItem.isHide = false;
						// Close all unactive items
					} else {
						menuItem.isHide = true;
					}
				}
				this.links.forEach((link) => {
					let subLinksWrapper = link.nativeElement.children[1];
					if (link.nativeElement.classList.contains('active')) {
						this.renderer.removeClass(subLinksWrapper, 'show');
					}
					if (!link.nativeElement.classList.contains('active')) {
						//this.renderer.addClass(subLinksWrapper, 'collapse');
					}
				});
			});
		});
	}
	changeHotelDb(hotel) {
		this.authService.setActivatedHotelCode(hotel.hotelCode);
		this.authService.createUserAuthObject(hotel.hotelCode, this.authService.loggedUserInHotels[hotel.hotelCode]);
		// this.router.navigate(["/dashboard"]);
		window.location.reload();
	}

	expand($event, index: number) {
		if ($event.target.offsetParent.classList.contains('nav-item__parent') == true) {
			if ($event.target.offsetParent.classList.contains('nav-item__parent--collapsed') == true) {
				$event.target.offsetParent.classList.remove('nav-item__parent--collapsed');
			} else {
				$event.target.offsetParent.classList.add('nav-item__parent--collapsed');
			}
		}
		let link = this.links.toArray()[index];
		let subLinksWrapper = link.nativeElement.children[1];
		let subLinks = subLinksWrapper.children;
		let isSubAcive = false;
		for (let subLink of subLinks) {
			if (subLink.classList.contains('sub-active')) {
				isSubAcive = true;
				return;
			}
		}
		if (!isSubAcive) {
			if (!subLinksWrapper.classList.contains('collapse')) {
				//this.renderer.removeClass(subLinksWrapper, 'show');
				this.renderer.addClass(subLinksWrapper, 'collapse');
			} else {
				this.renderer.removeClass(subLinksWrapper, 'collapse');
				//this.renderer.addClass(subLinksWrapper, 'show');
			}
		}
	}

	isMobileMenu(): boolean {
		if (window.innerWidth > 991) {
			return false;
		}
		return true;
	}

	ngOnDestroy(): void {
		this.credentialsSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
		this.$hotelName.unsubscribe();
	}
}
