import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as moment from 'moment';

import { Item, Period, Section, Events, Text, NgxTimeSchedulerService, SchedulerType } from 'src/app/shared/ngx-time-scheduler/src/public_api';
import { ReceptionService } from '../reception.service';
import { ReservationsService } from '../reservations/reservations.service';
import { ContextMenuModel } from '../shared/context-menu/context-menu.model';
import { CancelReservationComponent } from '../shared/dialogs/cancel-reservation/cancel-reservation.component';
import { ConfirmEditComponent } from '../shared/dialogs/confirm-edit/confirm-edit.component';
import { EditReservationComponent } from '../shared/dialogs/edit-reservation/edit-reservation.component';
import { StayinsService } from '../stayins/stayins.service';
import { catchError, map, subscribeOn } from 'rxjs/operators';
import { CheckinReservationComponent } from '../shared/dialogs/checkin-reservation/checkin-reservation.component';
import { AppServiceService } from '../../app-service.service';
import { ServiceOrderService } from '../../housekeeping/service-order/service-order.service';
import { EditStayinComponent } from '../shared/dialogs/edit-stayin/edit-stayin.component';
import { RoomServiceComponent } from '../../housekeeping/room-service/room-service.component';
import { HousekeepingService } from '../../housekeeping/housekeeping.service';
import { ParamService } from 'src/app/shared/services/param.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogCloseResponse } from 'src/app/shared/enums/DialogEnums';

@Component({
	selector: 'app-booking-schedule',
	templateUrl: './booking-schedule.component.html',
	styleUrls: ['../reception.component.scss', './booking-schedule.component.scss'],
})
export class BookingScheduleComponent implements OnInit, OnDestroy {
	private stayinsListSubscription: Subscription;
	private reservationsListSubscription: Subscription;
	events: Events = new Events();
	text: Text = new Text();
	schedulerTypes: SchedulerType[];
	periods: Period[];
	sections: Section[];
	items = [];
	isLoading: boolean = true;
	public schedulerType;
	public localizationType;
	public archiveToo = 0;
	public localizationArray = [];
	public areDetailsVisible: boolean = false;
	title = 'context-menu';
	dates = null;
	isDisplayContextMenu: boolean;
	rightClickMenuItems: Array<ContextMenuModel> = [];
	rightClickMenuPositionX: number;
	rightClickMenuPositionY: number;
	public choosenLocal: string;
	public choosenObject = {};
	public authorities: string;
	public isLocalizationOn;
	public defaultTimeOfBlockadeFrom: any = '';
	public defaultTimeOfBlockadeTo: any = '';
	public isStart: boolean = true; //helps with setting Localization after coming back
	public noLocalization: boolean = false; //visible only "Lokalizacja" if true, because typ pokoju is chosen
	public noRoomType: boolean = false; //visible only "Pokój" if true, because localization other than "Lokalizacja" is chosen
	public currentSection: any = {
		id: 0,
		value: 'room',
		name: 'Pokój',
	};

	constructor(
		private receptionService: ReceptionService,
		private stayinsService: StayinsService,
		private reservationsService: ReservationsService,
		private serviceOrderService: ServiceOrderService,
		private service: NgxTimeSchedulerService,
		private appService: AppServiceService,
		private _snackBar: MatSnackBar,
		public router: Router,
		public dialog: MatDialog,
		private data: NgxTimeSchedulerService,
		private housekeepingService: HousekeepingService,
		private param: ParamService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.housekeepingService.disableParentContent.next(true);
		if (localStorage.getItem('userData')) {
			this.authorities = JSON.parse(localStorage.getItem('userData')).authorities;
		}
		this.data.fetchMessage().subscribe((local) => {
			if (this.isStart) local = 'Lokalizacja'; //enables to start from "Lokalizacja" after coming back
			this.choosenLocal = local;
			this.isStart = false;
		});
		this.text.GotoButton = 'Idź do';
		this.text.NextButton = 'Następne';
		this.text.PrevButton = 'Poprzednie';
		this.text.SectionTitle = 'Pokój';
		this.text.TodayButton = 'Dzisiaj';
		this.schedulerTypes = [
			{ id: 0, value: 'room', name: 'Pokój' },
			{ id: 1, value: 'type', name: 'Typ pokoju' },
		];
		let today = moment().subtract(7, 'days');
		this.dates = {
			dataOd: today.format('YYYY-MM-DD'),
			dataDo: today.add(45, 'days').format('YYYY-MM-DD'),
		};
		this.schedulerType = this.schedulerTypes[0];
		//
		this.items = [];
		if (this.authorities !== 'HK') {
			this.events.SectionClickEvent = (section) => {};
			this.events.SectionChange = (schedulerType) => {
				this.isLoading = true;
				this.schedulerType = schedulerType;
				if (schedulerType.value != 'room') this.noLocalization = true;
				else this.noLocalization = false;
				this.reservationsService.getPolozeniaList().subscribe((res) => {
					res.sort((a, b) => {
						return a.polozenieNazwa - b.polozenieNazwa;
					});
					if (this.appService.isLocalizationOn.getValue()) {
						this.localizationArray = [
							{
								id: 0,
								value: 'localization',
								name: 'Lokalizacja',
							},
						];
						if (!this.noLocalization) {
							res.forEach((element, index) => {
								this.localizationArray.push({
									id: index + 1,
									value: 'localization',
									name: element.polozenieNazwa,
								});
							});
						}
					} else {
						this.localizationArray = [
							{
								id: 0,
								value: 'polozenie',
								name: 'Położenie',
							},
						];
						if (!this.noLocalization) {
							res.forEach((element, index) => {
								this.localizationArray.push({
									id: index + 1,
									value: 'polozenie',
									name: element.polozenieNazwa,
								});
							});
						}
					}
				});
				this.getInitData(schedulerType.value).subscribe((res) => {
					this.onRefresh();
				});
			};
		}
		this.events.LocalizationChange = (localizationType) => {
			this.isLoading = true;
			this.localizationType = localizationType;
			if (localizationType.name != 'Lokalizacja') {
				this.noRoomType = true;
			} else this.noRoomType = false;
			this.schedulerTypes = [{ id: 0, value: 'room', name: 'Pokój' }];
			if (!this.noRoomType)
				this.schedulerTypes.push({
					id: 1,
					value: 'type',
					name: 'Typ pokoju',
				});
			this.getInitData('localization').subscribe((res) => {
				this.onRefresh();
			});
		};

		this.events.onEditBlockStayReservation = () => {
			this.getInitData('localization').subscribe((res) => {
				this.onRefresh();
			});
		};
		if (this.authorities !== 'HK') {
			this.events.ItemClicked = (item) => {
				this.areDetailsVisible = true;
				this.choosenObject = { ...item.object };
			};
			this.events.ItemDropped = (item) => {
				if (item.sectionID != item.oldsectionID) {
					if (!item.object.meldunekId && item.object.rezerwacjaId) {
						// rezerwacja
						let data = {
							title: `Edycja ${item.name}`,
							text: `Czy chcesz dokonać zmiany pokoju ${this.getRoomNameBySectionId(item.oldsectionID)} na pokój ${this.getRoomNameBySectionId(item.sectionID)} ?`,
							description: '',
							cancelButton: true,
							item: item,
						};
						this.openChangeRezerwacjaDialog(data, item);
						return;
					} else if (item.object.meldunekId) {
						// meldunek
						let data = {
							title: `Edycja ${item.name}`,
							text: `Czy chcesz dokonać zmiany pokoju ${this.getRoomNameBySectionId(item.oldsectionID)} na pokój ${this.getRoomNameBySectionId(item.sectionID)} ?`,
							description: '',
							cancelButton: true,
							item: item,
						};
						this.openChangeMeldunekDialog(data, item);
						return;
					} else {
						// rest
					}
				}
			};
			this.events.ItemContextMenu = (item, $event) => {
				this.displayContextMenu($event, item);
			};
		}
		this.periods = [
			{
				name: 'Miesiąc',
				timeFrameHeaders: ['MMM YYYY', 'DD'],
				classes: '',
				timeFrameOverall: 1440 * 30,
				timeFramePeriod: 1440,
			},
			{
				name: 'Tydzień',
				timeFrameHeaders: ['MMMM YYYY', 'DD(ddd)'],
				classes: '',
				timeFrameOverall: 1440 * 7,
				timeFramePeriod: 1440,
			},
		];
		this.events.PeriodChange = (start, end) => {
			this.dates = {
				dataOd: moment(start).format('YYYY-MM-DD'),
				dataDo: moment(end).format('YYYY-MM-DD'),
			};
			this.isLoading = true;
			//TODO przerobienie metody getInitData
			// usuniecie parametru i przerzucenie tego warunku do srodka
			this.getInitData(this.localizationType ? this.localizationType.value : this.schedulerType.value).subscribe((res) => {
				this.onRefresh();
			});
		};

		this.sections = [];
		this.param.getDomyslnaGodzinaPrzyjazdu().subscribe((res) => {
			this.defaultTimeOfBlockadeFrom = res.paramWartosc;
		});
		//this.defaultTimeOfBlockadeFrom = '14:00:00';
		this.param.getDomyslnaGodzinaWyjazdu().subscribe((res) => {
			this.defaultTimeOfBlockadeTo = res.paramWartosc;
		});
		//this.defaultTimeOfBlockadeTo = '12:00:00';
	}

	ngAfterViewInit() {
		this.getInitData('room').subscribe((res) => {
			this.onRefresh();
		});
		this.reservationsService.getPolozeniaList().subscribe((res) => {
			res.sort((a, b) => {
				return a.polozenieNazwa - b.polozenieNazwa;
			});
			if (this.appService.isLocalizationOn.getValue()) {
				this.localizationArray = [
					{
						id: 0,
						value: 'localization',
						name: 'Lokalizacja',
					},
				];
				if (!this.noLocalization) {
					res.forEach((element, index) => {
						this.localizationArray.push({
							id: index + 1,
							value: 'localization',
							name: element.polozenieNazwa,
						});
					});
				}
			} else {
				this.localizationArray = [
					{
						id: 0,
						value: 'polozenie',
						name: 'Położenie',
					},
				];
				if (!this.noLocalization) {
					res.forEach((element, index) => {
						this.localizationArray.push({
							id: index + 1,
							value: 'polozenie',
							name: element.polozenieNazwa,
						});
					});
				}
			}
		});
	}

	openChangeRezerwacjaDialog(data, item): void {
		const dialogRef = this.dialog.open(ConfirmEditComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result === DialogCloseResponse.CONFIRM) {
				this.reservationsService
					.zmienPokojAktywnejRezerwacji({
						rezerwacjaId: item.object.rezerwacjaId,
						pokojId: item.sectionID,
					})
					.subscribe(
						(res) => {
							console.log(res);

							if (res.status == 200) {
								this._snackBar.open('Rezerwacja przeniesiona', '', { duration: 2000 });
							} else {
								this._snackBar.open('Nie udało się przenieść rezerwacji', '', { duration: 2000 });

								item.sectionID = item.oldsectionID;
							}
							this.onRefresh();
						},
						() => {
							this._snackBar.open('Nie udało się przenieść rezerwacji', '', { duration: 2000 });
							item.sectionID = item.oldsectionID;
							this.onRefresh();
						}
					);
			} else {
				item.sectionID = item.oldsectionID;
				this.onRefresh();
			}
		});
	}

	openChangeMeldunekDialog(data, item): void {
		const dialogRef = this.dialog.open(ConfirmEditComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result === DialogCloseResponse.CONFIRM) {
				this.stayinsService.changeRoomOfStayin(item.object.meldunekId, item.sectionID).subscribe(
					(res) => {
						console.log(res);
						if (res.status == 200) {
							this._snackBar.open('Meldunek przeniesiony', '', { duration: 2000 });
							this.items = [];
							this.getInitData(this.schedulerType.value).subscribe((res) => {
								this.onRefresh();
							});
						} else {
							this._snackBar.open('Nie udało się przenieść meldunku', '', { duration: 2000 });

							item.sectionID = item.oldsectionID;
						}
						this.onRefresh();
					},
					() => {
						this._snackBar.open('Nie udało się przenieść meldunku', '', { duration: 2000 });
						item.sectionID = item.oldsectionID;
						this.onRefresh();
					}
				);
			} else {
				item.sectionID = item.oldsectionID;
				this.onRefresh();
			}
		});
	}
	openEditMeldDialog(data): void {
		const dialogRef = this.dialog.open(EditStayinComponent, {
			maxWidth: '20vw',
			autoFocus: false,
			data: data,
			hasBackdrop: false,
			disableClose: true,
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getInitData('room').subscribe((res) => {
					this.onRefresh();
					this._snackBar.open('Dane meldunku zostały zaktualizowane', ':)', {
						duration: 2000,
					});
				});
				let item = data.item;
				if (result == 'confirm') {
					item.oldsectionID = item.sectionID;
					item.object.pokojId = item.sectionID;
					this.stayinsService
						.editStayin({
							meldunekId: item.object.meldunekId,
							pokojId: item.sectionID,
						})
						.subscribe((res) => {
							this._snackBar.open(res.entity, '', {
								duration: 2000,
							});
							this.onRefresh();
						});
				} else {
					item.sectionID = item.oldsectionID;
					this.onRefresh();
				}
			}
		});
	}
	showServiceRoomDialog(serviceOrderObject): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.hasBackdrop = true;
		dialogConfig.autoFocus = false;
		dialogConfig.panelClass = 'device-dialog';
		dialogConfig.hasBackdrop = false;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			room: {
				pokojId: serviceOrderObject.object.pokojId,
			},
			serviceOrderObject: serviceOrderObject.object,
			modalType: 'detail',
			zlecenieStan: serviceOrderObject.object.zlecenieStan,
		};
		const dialogRef = this.dialog.open(RoomServiceComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status === 'Resolved' || res?.status === 'Edited') {
				serviceOrderObject.object = res.data;
				serviceOrderObject.object.dataOd = serviceOrderObject.object.zlecenieDataOd;
				serviceOrderObject.object.dataDo = serviceOrderObject.object.zlecenieDataDo;
				serviceOrderObject.start = moment(serviceOrderObject.object.zlecenieDataOd).startOf('day').add(1, 'hours');
				serviceOrderObject.end = moment(serviceOrderObject.object.zlecenieDataDo).endOf('day').subtract(1, 'hours');
				this.events.onEditBlockStayReservation();
			}
		});
	}

	openEditDialog(data): void {
		const dialogRef = this.dialog.open(EditReservationComponent, {
			maxWidth: '70vw',
			autoFocus: false,
			data: data,
			hasBackdrop: false,
			disableClose: true,
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getInitData('room').subscribe((res) => {
					this.onRefresh();
					this._snackBar.open('Dane rezerwacji zostały zaktualizowane', ':)', {
						duration: 2000,
					});
				});
				let item = data.item;
				if (result == 'confirm') {
					item.oldsectionID = item.sectionID;
					item.object.pokojId = item.sectionID;
					this.reservationsService
						//.edytujRezerwacje({
						.zmienPokojAktywnejRezerwacji({
							rezerwacjaId: item.object.rezerwacjaId,
							pokojId: item.sectionID,
						})
						.subscribe((res) => {
							this._snackBar.open(res.entity, '', {
								duration: 2000,
							});
							this.onRefresh();
						});
				} else {
					item.sectionID = item.oldsectionID;
					this.onRefresh();
				}
			}
		});
	}
	openCancelDialog(data, type: string): void {
		const dialogRef = this.dialog.open(CancelReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		let obj = {};
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'confirm') {
				if (type === 'single') {
					obj = {
						rezerwacjaId: data.item.object.rezerwacjaId,
						powodAnulacji: data.reason,
					};
				} else {
					obj = {
						grupaId: data.item.object.grupaId,
						powodAnulacji: data.reason,
					};
				}
				this.reservationsService.reservationCancel(obj, type).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });
					this.events.onEditBlockStayReservation();
				});
			}
		});
	}
	openCheckinDialog(data): void {
		const dialogRef = this.dialog.open(CheckinReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'checkin') {
				let obj = {
					rezerwacjaId: data.rezerwacjaId,
				};
				this.reservationsService.checkinReservation(obj).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });
					this.getInitData(this.schedulerType.value).subscribe((res) => {
						this.onRefresh();
					});
				});
			}
		});
	}
	getRoomNameBySectionId(id) {
		let ell = this.sections.filter((el) => {
			return el.id == id;
		});
		return ell[0].name;
	}
	addItem() {
		// this.service.itemPush({
		//   id: 4,
		//   sectionID: 5,
		//   name: "Item 4",
		//   start: moment().startOf("day"),
		//   end: moment().add(3, "days").endOf("day"),
		//   classes: "",
		//   object: {},
		//   oldsectionID: "",
		// });
	}
	onRefresh() {
		this.service.refresh();
		this.isLoading = false;
	}
	onClickPrev() {}

	popItem() {
		this.service.itemPop();
	}

	removeItem() {
		this.service.itemRemove(4);
	}

	ngOnDestroy(): void {
		this.housekeepingService.disableParentContent.next(false);
	}

	getInitData(type: string) {
		let dataParam = {
			wymeldowaneTez: this.archiveToo,
		};
		this.sections = [];
		this.items = [];
		const rooms$: Observable<any> = this.receptionService.getRooms().pipe(
			map((rooms) => {
				if (type == 'room') {
					rooms.map((item) => {
						this.sections.push({
							id: item.pokojId,
							name: `${item.pokojNazwa}`,
							tooltip: item.pokojNazwa,
							blocked: item.blokada,
							object: item,
						});
					});
				} else if (type == 'type') {
					//typy
					// DODANIE POLOZENIA, tak zeby byly oddzielne obiekty, a potem wrzucac do nich pojedyncze pokoje z rezerwacjami

					const filteredArr = rooms.reduce((acc, current) => {
						let x;
						let y; // nieprzydzielone
						if (this.appService.isLocalizationOn.getValue()) {
							x = acc.find((item) => item.pokojtypId === current.pokojtypId && item.polozenieId === current.polozenieId);
						} else {
							x = acc.find((item) => item.pokojtypId === current.pokojtypId);
						}

						if (!x) {
							return acc.concat([current]);
						} else {
							return acc;
						}
					}, []);

					const filteredWithoutLocationArr = rooms.reduce((acc, current) => {
						let y; // nieprzydzielone
						if (this.appService.isLocalizationOn.getValue()) {
							y = acc.find((item) => {
								return item.pokojtypId === current.pokojtypId;
							});
						} else {
							y = acc.find((item) => item.pokojtypId === current.pokojtypId);
						}

						if (!y) {
							return acc.concat([current]);
						} else {
							return acc;
						}
					}, []);
					if (this.appService.isLocalizationOn.getValue()) {
						//na typy pkoi
						filteredArr.map((item) => {
							if (`${item.pokojtypNazwa}` != 'UjnyRezerwa') {
								this.sections.push({
									id: item.polozenieId,
									name: `${item.polozenieNazwa} | ${item.pokojtypNazwa}`,
									tooltip: `${item.polozenieNazwa} | ${item.pokojtypNazwa}`,
									object: item,
									blocked: item.blokada,
								});
							}
						});
						//na zwykle typy bez polozenia
						// filteredWithoutLocationArr.map((item) => {
						// 	this.sections.push({
						// 		id: item.pokojtypId,
						// 		name: item.pokojtypNazwa,
						// 		tooltip: item.pokojtypNazwa,
						// 		object: item,
						// 		blocked: item.blokada,
						// 	});
						// });
					} else {
						filteredArr.map((item) => {
							this.sections.push({
								id: item.pokojtypId,
								name: item.pokojtypNazwa,
								tooltip: item.pokojtypNazwa,
								object: item,
								blocked: item.blokada,
							});
						});
					}
				} else if (type === 'localization') {
					rooms.map((item) => {
						if (item.polozenieNazwa == this.choosenLocal || this.choosenLocal === 'Lokalizacja') {
							this.sections.push({
								id: item.pokojId,
								name: `${item.pokojNazwa}`,
								tooltip: item.pokojNazwa,
								blocked: item.blokada,
								object: item,
							});
						}
					});
				}
				this.sections.sort((a, b) => {
					if (this.isLocalizationOn || this.authService.getHotelCode() == '0101' || this.authService.getHotelCode() == '9998') {
						//for instance for Arche
						//make Ujny Rez 4 and Ujny Rez 11 last two
						if (a.name == 'Ujny Rez 11' && b.name == 'Ujny Rez 4') {
							return 1;
						} else if (a.name == 'Ujny Rez 4' && b.name == 'Ujny Rez 11') {
							return -1;
						} else if (a.name == 'Ujny Rez 11' || a.name == 'Ujny Rez 4') return 1;
						else if (b.name == 'Ujny Rez 11' || b.name == 'Ujny Rez 4') return -1;
						else {
							//split a.name and b.name into smaller parts and than sort using these elements
							let lettersA: string[] = ['', ''];
							let stringNumbersA: string[] = ['', ''];
							let numbersA: number[] = [0, 0];
							let lettersB: string[] = ['', ''];
							let stringNumbersB: string[] = ['', ''];
							let numbersB: number[] = [0, 0];
							let whichA: number = 0;
							let whichB: number = 0;
							let i: number = 0;
							while (i < a.name.length) {
								if (whichA == 0 && i == 0) {
									lettersA[0] += a.name[0];
								} else if (whichA == 0 && i != 0) {
									if (!(a.name[i] == '1' || a.name[i] == '2' || a.name[i] == '3' || a.name[i] == '4' || a.name[i] == '5' || a.name[i] == '6' || a.name[i] == '7' || a.name[i] == '8' || a.name[i] == '9' || a.name[i] == '0'))
										lettersA[0] += a.name[i];
									else {
										whichA = 1;
										i--;
									}
								} else if (whichA == 1) {
									if (!(a.name[i] == ' ')) {
										stringNumbersA[0] += a.name[i];
									} else if (a.name[i] == ' ') {
										whichA = 2;
										i--;
									}
								} else if (whichA == 2) {
									if (!(a.name[i] == '1' || a.name[i] == '2' || a.name[i] == '3' || a.name[i] == '4' || a.name[i] == '5' || a.name[i] == '6' || a.name[i] == '7' || a.name[i] == '8' || a.name[i] == '9' || a.name[i] == '0'))
										lettersA[1] += a.name[i];
									else {
										whichA = 3;
										i--;
									}
								} else if (whichA == 3) {
									stringNumbersA[1] += a.name[i];
								}
								i++;
							}
							numbersA[0] = parseInt(stringNumbersA[0]);
							numbersA[1] = parseInt(stringNumbersA[1]);

							let j: number = 0;
							while (j < b.name.length) {
								if (whichB == 0 && j == 0) {
									lettersB[0] += b.name[0];
								} else if (whichB == 0 && j != 0) {
									if (!(b.name[j] == '1' || b.name[j] == '2' || b.name[j] == '3' || b.name[j] == '4' || b.name[j] == '5' || b.name[j] == '6' || b.name[j] == '7' || b.name[j] == '8' || b.name[j] == '9' || b.name[j] == '0'))
										lettersB[0] += b.name[j];
									else {
										whichB = 1;
										j--;
									}
								} else if (whichB == 1) {
									if (!(b.name[j] == ' ')) {
										stringNumbersB[0] += b.name[j];
									} else if (b.name[j] == ' ') {
										whichB = 2;
										j--;
									}
								} else if (whichB == 2) {
									if (!(b.name[j] == '1' || b.name[j] == '2' || b.name[j] == '3' || b.name[j] == '4' || b.name[j] == '5' || b.name[j] == '6' || b.name[j] == '7' || b.name[j] == '8' || b.name[j] == '9' || b.name[j] == '0'))
										lettersB[1] += b.name[j];
									else {
										whichB = 3;
										j--;
									}
								} else if (whichB == 3) {
									stringNumbersB[1] += b.name[j];
								}
								j++;
							}
							numbersB[0] = parseInt(stringNumbersB[0]);
							numbersB[1] = parseInt(stringNumbersB[1]);

							if (lettersA[0] != lettersB[0]) return lettersA[0] > lettersB[0] ? 1 : -1;
							else if (numbersA[0] != numbersB[0]) return numbersA[0] > numbersB[0] ? 1 : 0;
							else if (lettersA[1] != lettersB[1]) return lettersA[1] > lettersB[1] ? 1 : -1;
							else return numbersA[1] > numbersB[1] ? 1 : 0;
						}
					} else if (this.authService.getHotelCode() == '0051') {
						//NowaMotława
						//split a.name and b.name into smaller parts and than sort using these elements
						let lettersA: string[] = ['', ''];
						let stringNumbersA: string[] = ['', ''];
						let numbersA: number[] = [0, 0];
						let lettersB: string[] = ['', ''];
						let stringNumbersB: string[] = ['', ''];
						let numbersB: number[] = [0, 0];
						let whichA: number = 0;
						let whichB: number = 0;
						let i: number = 0;
						while (i < a.name.length) {
							if (whichA == 0 && i == 0) {
								lettersA[0] += a.name[0];
							} else if (whichA == 0 && i != 0) {
								if (!(a.name[i] == ' ')) lettersA[0] += a.name[i];
								else {
									whichA = 1;
									i--;
								}
							} else if (whichA == 1) {
								if (a.name[i] == ' ') {
									whichA = 2;
								}
							} else if (whichA == 2) {
								stringNumbersA[0] += a.name[i];
							}
							i++;
						}
						numbersA[0] = parseInt(stringNumbersA[0].trim());
						numbersA[1] = parseInt(stringNumbersA[1].trim());

						let j: number = 0;
						while (j < b.name.length) {
							if (whichB == 0 && j == 0) {
								lettersB[0] += b.name[0];
							} else if (whichB == 0 && j != 0) {
								if (!(b.name[j] == ' ')) lettersB[0] += b.name[j];
								else {
									whichB = 1;
									j--;
								}
							} else if (whichB == 1) {
								if (b.name[j] == ' ') {
									whichB = 2;
								}
							} else if (whichB == 2) {
								stringNumbersB[0] += b.name[j];
							}
							j++;
						}
						numbersB[0] = parseInt(stringNumbersB[0]);
						if (lettersA[0] != lettersB[0]) {
							return lettersA[0] > lettersB[0] ? 1 : -1;
						} else if (numbersA[0] != numbersB[0]) {
							return numbersA[0] > numbersB[0] ? 1 : -1;
						} else return 0;
					} else if (this.authService.getHotelCode() == '0271') {
						if (this.currentSection.name == 'Pokój') return parseInt(a.name) - parseInt(b.name);
						else {
							return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
						}
					} else {
						//for hotels without localization
						return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
					}
				});
				return rooms;
			})
		);
		const stayins$: Observable<any> = this.stayinsService.getStayinsList(dataParam).pipe(
			map((stay) => {
				stay.map((item, index) => {
					if (item && (type == 'room' || type == 'localization')) {
						let it = {};
						let tooltipString: String = `M: ${item.meldunekId.toString()} | ${item.osobaImieNazwisko} `;
						let sectionID = item.pokojId;
						if (this.appService.isLocalizationOn.getValue()) {
							sectionID = item.polozenieId;
							if (type == 'room' || type == 'localization') {
								sectionID = item.pokojId;
							} else {
								sectionID = item.polozenieId;
							}
							if (item.polozenieId == null || item.polozenieId == undefined) {
								if (type == 'room' || type == 'localization') {
									sectionID = item.pokojId;
								} else {
									sectionID = item.pokojtypId;
								}
							}
						} else {
							if (type == 'room' || type == 'localization') {
								sectionID = item.pokojId;
							} else {
								sectionID = item.pokojtypId;
							}
						}
						it = {
							id: index,
							sectionID: sectionID,
							oldsectionID: sectionID,
							name: tooltipString,
							tooltip: tooltipString,
							start: this.combineDateAndTime(item.meldunekDataOd, item.meldunekGodzOd, 'start').add(30, 'minutes'),
							end: this.combineDateAndTime(item.meldunekDataDo, item.meldunekGodzDo, 'end'),
							classes: 'time-sch__item-stayin',
							object: item,
						};
						let todayDate: string = moment().format('YYYY-MM-DD');
						let todayTime: any = moment().format('HH:mm:ss');
						if (
							(this.choosenLocal === item.polozenieNazwa || this.choosenLocal === 'Lokalizacja') &&
							((todayDate < item.meldunekDataDo && todayDate >= item.meldunekDataOd) || (todayDate == item.meldunekDataDo && todayTime < item.meldunekGodzDo))
						) {
							this.items.push(it);
						}
					}
				});
				return stay;
			})
		);
		const reservations$: Observable<any> = this.reservationsService
			.changeReservationsList(this.dates)
			// .getAllReservationsList()
			.pipe(
				map((res) => {
					res.map((item, index) => {
						if (item) {
							let it = {};
							let tooltipString: String = `R: ${item.rezerwacjaId.toString()} | ${item.osobaImieNazwisko} `;
							let sectionID = item.pokojId;

							if (this.appService.isLocalizationOn.getValue()) {
								sectionID = item.polozenieId;
								if (type == 'room' || type == 'localization') {
									sectionID = item.pokojId;
								} else {
									sectionID = item.polozenieId;
								}
								if (item.polozenieId == null || item.polozenieId == undefined) {
									if (type == 'room' || type == 'localization') {
										sectionID = item.pokojId;
									} else {
										sectionID = item.pokojtypId;
									}
								}
							} else {
								if (type == 'room' || type == 'localization') {
									sectionID = item.pokojId;
								} else {
									sectionID = item.pokojtypId;
								}
							}
							let classForReservation: string;
							let tempWstepna: string = 'Wst' + '#118' + 'pna';
							switch (item.rezerwacjaStatusText) {
								case tempWstepna:
									classForReservation = 'time-sch__item-reservation';
									break;

								case 'Zrealizowana':
									classForReservation = 'time-sch__item-reservation-archival';
									break;

								case 'Gwarantowana':
									classForReservation = 'time-sch__item-reservation-guarantee';
									break;

								case 'Internetowa':
									classForReservation = 'time-sch__item-reservation-online';
									break;

								default:
									classForReservation = 'time-sch__item-reservation';
									break;
							}
							it = {
								id: index,
								sectionID: sectionID,
								oldsectionID: sectionID,
								name: tooltipString,
								tooltip: tooltipString,
								start: this.combineDateAndTime(item.rezerwacjaDataOd, item.rezerwacjaGodzOd, 'start').add(30, 'minutes'),
								end: this.combineDateAndTime(item.rezerwacjaDataDo, item.rezerwacjaGodzDo, 'end').subtract(30, 'minutes'),
								classes: classForReservation,

								object: item,
							};

							let todayDate: string = moment().format('YYYY-MM-DD');
							let todayTime: any = moment().format('HH:mm:ss');
							if (item.rezerwacjaAktywna == 1 && item.rezerwacjaDataDo > todayDate) {
								if (this.choosenLocal === item.polozenieNazwa && item.rezerwacjaStatus != 4) {
									this.items.push(it);
								} else if (
									(item.rezerwacjaStatus == 4 && todayDate > item.rezerwacjaDataDo && todayDate > item.rezerwacjaDataOd) ||
									(todayDate == item.rezerwacjaDataDo && todayTime > item.rezerwacjaGodzDo && todayDate > item.rezerwacjaDataOd)
								) {
									this.items.push(it);
								} else if (this.choosenLocal === 'Lokalizacja' && item.rezerwacjaStatus != 4) {
									this.items.push(it);
								}
							} else if (item.rezerwacjaAktywna == 0) {
								if (item.rezerwacjaDataDo < todayDate) {
									this.items.push(it);
								} else if (item.rezerwacjaDataDo == todayDate && item.rezerwacjaGodzDo <= todayTime) {
									this.items.push(it);
								}
							}
						}
					});
					return res;
				})
			);
		this.serviceOrderService;

		const blocks$: Observable<any> = this.serviceOrderService.getBlockForRooms().pipe(
			map((stay) => {
				stay.map((item, index) => {
					if (item && (type == 'room' || type == 'localization')) {
						let it = {};

						let tooltipString: String = `B: ${item.zlecenieId.toString()} | ${item.zlecenieRodzaj} | ${item.zlecenieOpis}`;
						let sectionID = item.pokojId;

						if (this.appService.isLocalizationOn.getValue()) {
							sectionID = item.polozenieId;
							if (type == 'room' || type == 'localization') {
								sectionID = item.pokojId;
							} else {
								sectionID = item.polozenieId;
							}
							if (item.polozenieId == null || item.polozenieId == undefined) {
								if (type == 'room' || type == 'localization') {
									sectionID = item.pokojId;
								} else {
									sectionID = item.pokojtypId;
								}
							}
						} else {
							if (item.polozenieId == null || item.polozenieId == undefined) {
								if (type == 'room' || type == 'localization') {
									sectionID = item.pokojId;
								} else {
									sectionID = item.pokojtypId;
								}
							}
						}
						it = {
							id: index,
							sectionID: sectionID,
							oldsectionID: sectionID,
							name: tooltipString,
							tooltip: tooltipString,
							start: this.combineDateAndTime(item.zlecenieDataOd, item.zlecenieCzasOd, 'start').add(30, 'minutes'),
							end: this.combineDateAndTime(item.zlecenieDataDo, item.zlecenieCzasDo, 'end'),
							classes: 'time-sch__item-block',
							object: item,
						};
						if (this.choosenLocal === item.polozenieNazwa) {
							this.items.push(it);
						} else if (this.choosenLocal === 'Lokalizacja') {
							this.items.push(it);
						}
					}
				});
				//console.log('blokada posortowna')
				return stay;
			}),
			catchError((err) => {
				return [[]];
			})
		);

		return forkJoin([rooms$, stayins$, reservations$, blocks$]).pipe(
			map((res) => {
				//console.log('odpowiedzi')
				let obj = {
					rooms: res[0],
					stayins: res[1],
					reservations: res[2],
					blocks: res[3],
				};
				return obj;
			})
		);
	}

	// CONTEXT MENU

	displayContextMenu(event, item) {
		this.isDisplayContextMenu = true;
		if (!item.object.meldunekId) {
			this.rightClickMenuItems = [
				{
					menuText: 'Szczegóły',
					menuEvent: 'edit',
					icon: 'edit',
					onClick: () => {
						this.onEditRes(item);
					},
				},
				{
					menuText: 'Zamelduj',
					menuEvent: 'checkin',
					icon: 'luggage',
					onClick: () => {
						this.onCheckinRes(item);
					},
				},
				{
					menuText: 'Anuluj',
					menuEvent: 'cancel',
					icon: 'delete_forever',
					onClick: () => {
						this.onCancelRes(item);
					},
				},
			];
		} else if (item.object.meldunekId != null && item.object.rezerwacjaId != null) {
			this.rightClickMenuItems = [
				{
					menuText: 'Szczegóły',
					menuEvent: 'edit',
					icon: 'edit',
					onClick: () => {
						this.onEditMeld(item);
					},
				},
				// {
				//   menuText: "Cofnij meldunek",
				//   menuEvent: "checkin_back",
				//   icon: "undo",
				//   onClick: () => {
				//     this.onCheckinResBack(item);
				//   },
				// },
				// {
				//   menuText: "Anuluj",
				//   menuEvent: "cancel",
				//   icon: "delete_forever",
				//   onClick: () => {
				//     this.onCancelRes(item);
				//   },
				// },
			];
		} else if (item.object.zlecenieId != null) {
			this.rightClickMenuItems = [
				{
					menuText: 'Szczegóły',
					menuEvent: 'edit',
					icon: 'edit',
					onClick: () => {
						this.onEditBlock(item);
					},
				},
			];
		}

		this.rightClickMenuPositionX = event.clientX;
		this.rightClickMenuPositionY = event.clientY;
	}

	onCancelRes(item) {
		let data = {
			reason: '',
			item: item,
		};
		this.openCancelDialog(data, item.object.grupaId ? 'group' : 'single');
	}
	onEditRes(item) {
		let data = item.object;
		data.dataOd = item.object.rezerwacjaDataOd;
		data.dataDo = item.object.rezerwacjaDataDo;
		this.openEditDialog(data);
	}
	onEditMeld(item) {
		let data = item.object;
		data.dataOd = item.object.meldunekDataOd;
		data.dataDo = item.object.meldunekDataDo;
		this.openEditMeldDialog(data);
	}
	onEditBlock(item) {
		let data = item.object;
		item.object.dataOd = item.object.zlecenieDataOd;
		item.object.dataDo = item.object.zlecenieDataDo;
		data.dataOd = item.object.zlecenieDataOd;
		data.dataDo = item.object.zlecenieDataDo;

		this.showServiceRoomDialog(item);
	}
	onCheckinRes(item) {
		let data = item.object;
		data.dataOd = item.object.rezerwacjaDataOd;
		data.dataDo = item.object.rezerwacjaDataDo;
		this.openCheckinDialog(data);
	}

	getRightClickMenuStyle() {
		return {
			position: 'fixed',
			left: `${this.rightClickMenuPositionX}px`,
			top: `${this.rightClickMenuPositionY}px`,
		};
	}

	@HostListener('document:click')
	documentClick(): void {
		this.isDisplayContextMenu = false;
	}

	@HostListener('contextmenu', ['$event'])
	onRightClick(event) {
		event.preventDefault();
	}

	combineDateAndTime(date: any, time: any, whichTime: string): any {
		let timeM = moment(time, 'HH:mm:ss');
		let dateM = moment(date, 'YYYY-MM-DD');
		if (!timeM.isValid()) {
			if (whichTime == 'start') timeM = this.defaultTimeOfBlockadeFrom;
			else if (whichTime == 'end') timeM = this.defaultTimeOfBlockadeTo;
		}
		let x = `${dateM.format('YYYY-MM-DD')} ${timeM.format('HH:mm:ss')}`;

		let newDate = moment(x, 'YYYY-MM-DD HH:mm:ss');
		return newDate;
	}
}
