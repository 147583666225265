import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment_ from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { ReceptionService } from 'src/app/pages/reception/reception.service';
import { ReservationsService } from 'src/app/pages/reception/reservations/reservations.service';
import { CancelReservationComponent } from 'src/app/pages/reception/shared/dialogs/cancel-reservation/cancel-reservation.component';
import { CheckinReservationComponent } from 'src/app/pages/reception/shared/dialogs/checkin-reservation/checkin-reservation.component';
import { StayinsNewService } from 'src/app/pages/reception/stayins/stayins-new/stayins-new.service';
import { BasicConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { RoomBlockerDialogComponent } from 'src/app/shared/room-blocker-dialog/room-blocker-dialog.component';
import { Events, Header, HeaderDetails, Item, ItemMeta, Period, SchedulerType, Section, SectionItem, Text } from './ngx-time-scheduler.model';
import { NgxTimeSchedulerService } from './ngx-time-scheduler.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { StayinsService } from 'src/app/pages/reception/stayins/stayins.service';

const moment = moment_;

const ELEMENT_DATA: any[] = [{ position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' }];

@Component({
	selector: 'ngx-ts[items][periods][sections]',
	templateUrl: './ngx-time-scheduler.component.html',
	styleUrls: ['./ngx-time-scheduler.component.css'],
})
export class NgxTimeSchedulerComponent implements OnInit, OnDestroy {
	@ViewChild('sectionTd') set SectionTd(elementRef: ElementRef) {
		if (elementRef) {
			this.SectionLeftMeasure = elementRef.nativeElement.clientWidth + 'px';
		}
		this.changeDetector.detectChanges();
	}

	displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
	dataSource = ELEMENT_DATA;

	@Input() currentTimeFormat = 'DD-MMM-YYYY HH:mm';
	@Input() showCurrentTime = true;
	@Input() showGoto = true;
	@Input() showToday = true;
	@Input() allowDragging = false;
	// @Input() allowResizing = false;
	@Input() locale = 'pl';
	@Input() showBusinessDayOnly = false;
	@Input() headerFormat = 'Do MMM YYYY';
	@Input() minRowHeight = 40;
	@Input() maxHeight: string = null;
	@Input() text = new Text();
	@Input() public items: Item[];
	@Input() sections: Section[];
	@Input() periods: Period[];
	@Input() schedulerTypes: SchedulerType[];
	@Input() localizationArray: [];
	@Input() events: Events = new Events();
	@Input() start;
	@Input() areDetailsVisible: boolean;
	@Input() choosenObject;
	// @Input() start = moment().subtract(7, "days").startOf("day");
	public end = moment().endOf('day');
	public showGotoModal = false;
	public currentTimeIndicatorPosition: string;
	public schedulerTypeId: number = 0;
	public currentTimeVisibility = 'visible';
	public currentTimeTitle: string;
	public ShowCurrentTimeHandle = null;
	public SectionLeftMeasure = '0';
	public currentPeriod: Period;
	public currentPeriodMinuteDiff = 0;
	public header: Header[];
	public sectionItems: SectionItem[];
	public subscription = new Subscription();
	public isLocalizationOn: boolean;
	public choosenLocal: string;
	public locked: boolean = true;
	public buttonStatus: string = 'lock';
	public selected: Date | null;
	public bookingResForm = new FormGroup({
		rezerwacjaDataOd: new FormControl(),
		rezerwacjaDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		rezerwacjaIleDoroslych: new FormControl(),
		rezerwacjaIleDzieci: new FormControl(),
		rezerwacjaCena: new FormControl(),
		passCode: new FormControl(),
		rezerwacjaUwagimemo: new FormControl(),
		rezerwacjaUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
		// dodatkiNazwa: new FormControl(),
		// dodatkiCena: new FormControl(),
		// dodatkiIlosc: new FormControl(),
	});
	public bookingMelForm = new FormGroup({
		meldunekDataOd: new FormControl(),
		meldunekDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		meldunekIleDoroslych: new FormControl(),
		meldunekIleDzieci: new FormControl(),
		pobytBrutto: new FormControl(),
		passCode: new FormControl(),
		meldunekUwagimemo: new FormControl(),
		meldunekUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
		// dodatkiNazwa2: new FormControl(),
		// dodatkiCena2: new FormControl(),
		// dodatkiIlosc2: new FormControl(),
	});
	public lockerTooltip: string = 'Odblokuj zmiany';
	public isTTLockAvailable;
	public showResForm: boolean;
	public showMelForm: boolean;
	public initialResForm;
	public isHousekeeping: boolean;
	public data2: boolean = true;
	private environment: any;
	public currentSection: any = {
		id: 0,
		value: 'room',
		name: 'Pokój',
	};
	public dodatkiIloscRezerwacja: number[] = [];
	public dodatkiIloscMeldunek: number[] = [];
	public dodatkiWyswietl: any[] = [];
	public dodatkiMeldunekWyswietl: any[] = [];
	public currentReservation: any = [];
	public currentStayin: any = [];

	constructor(
		private changeDetector: ChangeDetectorRef,
		private service: NgxTimeSchedulerService,
		private appService: AppServiceService,
		private _formBuilder: FormBuilder,
		public dialog: MatDialog,
		private reservationsService: ReservationsService,
		private _snackBar: MatSnackBar,
		private stayinsNewService: StayinsNewService,
		private receptionService: ReceptionService,
		private router: Router,
		private authService: AuthService,
		private reservationService: ReservationsService,
		private stayinsService: StayinsService
	) {
		moment.locale(this.locale);
		this.environment = environment;
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.hideDetails();
	}

	ngOnInit(): void {
		this.checkIsTTLockAvailable();
		this.service.fetchMessage().subscribe((choosenLocal) => (this.choosenLocal = choosenLocal));
		this.isLocalizationOn = true;
		this.setSectionsInSectionItems();
		this.changePeriod(this.periods[0], false);
		this.refresh();
		setTimeout(() => {
			this.isLocalizationOn = this.appService.isLocalizationOn.getValue();
		}, 1000);
		if (this.router.url == '/housekeeping/booking-schedule') {
			this.isHousekeeping = true;
		} else {
			this.isHousekeeping = false;
		}
		this.bookingResForm = this._formBuilder.group({
			rezerwacjaDataOd: [this.choosenObject.rezerwacjaDataOd, Validators.required],
			rezerwacjaDataDo: [this.choosenObject.rezerwacjaDataDo, Validators.required],
			osobaTelefon: [this.choosenObject.osobaTelefon, Validators.required],
			//osobaTelefon: [{ value: " ", disabled: true }],
			osobaEmail: [this.choosenObject.osobaEmail, Validators.required],
			osobaAdres: [{ value: ' ', disabled: true }],
			polozenieNazwa: [
				{
					value: this.choosenObject.polozenieNazwa,
					disabled: true,
				},
				Validators.required,
			],
			pokojNazwa: [{ value: this.choosenObject.pokojNazwa, disabled: true }, Validators.required],
			rezerwacjaIleDoroslych: [this.choosenObject.rezerwacjaIleDoroslych, Validators.required],
			rezerwacjaIleDzieci: [this.choosenObject.rezerwacjaIleDzieci, Validators.required],
			pobytBrutto: [parseFloat(this.choosenObject.pobytBrutto).toFixed(2), Validators.required],
			passCode: [this.choosenObject.passCode ? this.choosenObject.passCode : null],
			rezerwacjaUwagimemo: [this.choosenObject.rezerwacjaUwagimemo],
			rezerwacjaUwagiPortal: [this.choosenObject.rezerwacjaUwagiPortal],
			zrodloRezerwacji: [
				{
					value: this.choosenObject.zrodloRezerwacji,
					disabled: true,
				},
				Validators.required,
			],
			rezerwacjaDataZglosz: [this.choosenObject.rezerwacjaDataZglosz],
		});

		this.bookingResForm.disable();

		this.bookingMelForm = this._formBuilder.group({
			meldunekDataOd: [this.choosenObject.meldunekDataOd, Validators.required],
			meldunekDataDo: [this.choosenObject.meldunekDataDo, Validators.required],
			osobaTelefon: [{ value: this.choosenObject.osobaTelefon, disabled: true }],
			osobaEmail: [this.choosenObject.osobaEmail, Validators.required],
			osobaAdres: [{ value: ' ', disabled: true }],
			polozenieNazwa: [
				{
					value: this.choosenObject.polozenieNazwa,
					disabled: true,
				},
				Validators.required,
			],
			pokojNazwa: [{ value: this.choosenObject.pokojNazwa, disabled: true }, Validators.required],
			meldunekIleDoroslych: [this.choosenObject.meldunekIleDoroslych, Validators.required],
			meldunekIleDzieci: [this.choosenObject.meldunekIleDzieci, Validators.required],
			pobytBrutto: [parseFloat(this.choosenObject.pobytBrutto).toFixed(2), Validators.required],
			passCode: [this.choosenObject.passCode ? this.choosenObject.passCode : null],
			meldunekUwagimemo: [this.choosenObject.meldunekUwagimemo],
			meldunekUwagiPortal: [this.choosenObject.meldunekUwagiPortal],
			zrodloRezerwacji: [
				{
					value: this.choosenObject.zrodloRezerwacji,
					disabled: true,
				},
				Validators.required,
			],
			rezerwacjaDataZglosz: [this.choosenObject.rezerwacjaDataZglosz],
		});
		this.bookingMelForm.disable();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.choosenObject.rezerwacjaId && !this.choosenObject.meldunekId) {
			this.showMelForm = false;
			this.showResForm = true;

			this.lockOrUnlockNextOpening(true);

			this.service.fetchMessage().subscribe((choosenLocal) => (this.choosenLocal = choosenLocal));
		} else if (this.choosenObject.meldunekId && !this.choosenObject.zlecenieId) {
			this.showResForm = false;
			this.showMelForm = true;

			this.lockOrUnlockNextOpening(true);
		} else {
			this.showResForm = false;
			this.showMelForm = false;
		}
		if (!this.choosenObject.meldunekId) {
			// console.log(this);
			//console.log(this.choosenObject);
			//console.log(this.bookingResForm.controls.pokojNazwa);
			// if (this.bookingResForm.controls.pokojNazwa.value == null) {
			// 	// this.bookingResForm.reset();

			// }
			//console.log(this.choosenObject);

			if (this.choosenObject.rezerwacjaId) {
				//setTimeout(() => {
				//console.log(this.choosenObject.rezerwacjaDataOd);
				this.bookingResForm.patchValue({
					rezerwacjaDataOd: this.choosenObject.rezerwacjaDataOd,
					rezerwacjaDataDo: this.choosenObject.rezerwacjaDataDo,
					osobaTelefon: this.choosenObject.osobaTelefon,
					//osobaTelefon: { value: " ", disabled: true },
					osobaEmail: this.choosenObject.osobaEmail,
					osobaAdres: '',
					polozenieNazwa: this.choosenObject.polozenieNazwa,
					pokojNazwa: this.choosenObject.pokojNazwa,
					rezerwacjaIleDoroslych: this.choosenObject.rezerwacjaIleDoroslych,
					rezerwacjaIleDzieci: this.choosenObject.rezerwacjaIleDzieci,
					pobytBrutto: parseFloat(this.choosenObject.pobytBrutto).toFixed(2),
					passCode: this.choosenObject.passCode ? this.choosenObject.passCode : null,
					rezerwacjaUwagimemo: this.choosenObject.rezerwacjaUwagimemo,
					rezerwacjaUwagiPortal: this.choosenObject.rezerwacjaUwagiPortal,
					zrodloRezerwacji: this.choosenObject.zrodloRezerwacji,
					rezerwacjaDataZglosz: this.choosenObject.rezerwacjaDataZglosz,
				});
				this.reservationService.getReservationInfo(this.choosenObject.rezerwacjaId).subscribe(
					(res) => {
						if (this.currentReservation.usluga && this.dodatkiWyswietl.length > 0) {
							for (let i = 0; i < this.currentReservation.usluga.length; i++) {
								this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiNazwa);
								this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiCena);
								this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiIlosc);
							}
							this.dodatkiWyswietl.splice(0);
						}
						//console.log(this.dodatkiWyswietl, this.bookingResForm);
						this.currentReservation = [];
						this.currentReservation = res;
						//console.log(this.currentReservation);
						//console.log(this.choosenObject);
						if (this.currentReservation.usluga) {
							//console.log(this.currentReservation.usluga[0]);
							for (let i = 0; i < this.currentReservation.usluga.length; i++) {
								this.dodatkiWyswietl.push({ dodatkiNazwa: 'dodatkiNazwa' + i, dodatkiCena: 'dodatkiCena' + i, dodatkiIlosc: 'dodatkiIlosc' + i });
								//console.log(this.currentReservation.usluga[i].asortNazwa);
								this.bookingResForm.addControl(this.dodatkiWyswietl[i].dodatkiNazwa, new FormControl(this.currentReservation.usluga[i].asortNazwa));
								//this.bookingResForm.get(this.dodatkiWyswietl[i].dodatkiNazwa).disable();
								this.bookingResForm.addControl(this.dodatkiWyswietl[i].dodatkiCena, new FormControl(this.currentReservation.usluga[i].uslugaCenaJednBrutto));
								//this.bookingResForm.get(this.dodatkiWyswietl[i].dodatkiCena).disable();
								this.bookingResForm.addControl(this.dodatkiWyswietl[i].dodatkiIlosc, new FormControl(this.currentReservation.usluga[i].uslugaIlosc));
								//this.bookingResForm.get(this.dodatkiWyswietl[i].dodatkiIlosc).disable();
							}
							// console.log(this.dodatkiWyswietl);
							this.bookingResForm.disable();
						}
					},
					(err) => {}
				);
				//}, 1000);
				//setTimeout(() => {
				//}, 1);
			}
		} else if (this.choosenObject.meldunekId) {
			if (this.choosenObject.meldunekId) {
				//setTimeout(() => {
				//console.log(this.choosenObject.rezerwacjaDataOd);
				this.bookingMelForm.patchValue({
					meldunekDataOd: this.choosenObject.meldunekDataOd,
					meldunekDataDo: this.choosenObject.meldunekDataDo,
					osobaTelefon: this.choosenObject.osobaTelefon,
					//osobaTelefon: { value: " ", disabled: true },
					osobaEmail: this.choosenObject.osobaEmail,
					osobaAdres: '',
					polozenieNazwa: this.choosenObject.polozenieNazwa,
					pokojNazwa: this.choosenObject.pokojNazwa,
					meldunekIleDoroslych: this.choosenObject.meldunekIleDoroslych,
					meldunekIleDzieci: this.choosenObject.meldunekIleDzieci,
					pobytBrutto: parseFloat(this.choosenObject.pobytBrutto).toFixed(2),
					passCode: this.choosenObject.passCode ? this.choosenObject.passCode : null,
					meldunekUwagimemo: this.choosenObject.meldunekUwagimemo,
					meldunekUwagiPortal: this.choosenObject.meldunekUwagiPortal,
					zrodloRezerwacji: this.choosenObject.zrodloRezerwacji,
					rezerwacjaDataZglosz: this.choosenObject.rezerwacjaDataZglosz,
				});
			}
			this.stayinsService.getStayinInfo(this.choosenObject.meldunekId).subscribe(
				(res) => {
					if (this.currentStayin.usluga && this.dodatkiMeldunekWyswietl.length > 0) {
						for (let i = 0; i < this.currentStayin.usluga.length; i++) {
							this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiNazwa);
							this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiCena);
							this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiIlosc);
						}
						this.dodatkiMeldunekWyswietl.splice(0);
					}
					//console.log(this.dodatkiMeldunekWyswietl, this.bookingMelForm);
					this.currentStayin = [];
					this.currentStayin = res;
					//console.log(this.currentStayin);
					//console.log(this.choosenObject);
					if (this.currentStayin.usluga) {
						//console.log(this.currentReservation.usluga[0]);
						for (let i = 0; i < this.currentStayin.usluga.length; i++) {
							this.dodatkiMeldunekWyswietl.push({ dodatkiNazwa: 'dodatkiNazwa' + i, dodatkiCena: 'dodatkiCena' + i, dodatkiIlosc: 'dodatkiIlosc' + i });
							//console.log(this.currentStayin.usluga[i].asortNazwa);
							this.bookingMelForm.addControl(this.dodatkiMeldunekWyswietl[i].dodatkiNazwa, new FormControl(this.currentStayin.usluga[i].asortNazwa));
							//this.bookingMelForm.get(this.dodatkiMeldunekWyswietl[i].dodatkiNazwa).disable();
							this.bookingMelForm.addControl(this.dodatkiMeldunekWyswietl[i].dodatkiCena, new FormControl(this.currentStayin.usluga[i].uslugaCenaJednBrutto));
							//this.bookingMelForm.get(this.dodatkiMeldunekWyswietl[i].dodatkiCena).disable();
							this.bookingMelForm.addControl(this.dodatkiMeldunekWyswietl[i].dodatkiIlosc, new FormControl(this.currentStayin.usluga[i].uslugaIlosc));
							//this.bookingMelForm.get(this.dodatkiMeldunekWyswietl[i].dodatkiIlosc).disable();
						}
						// console.log(this.dodatkiMeldunekWyswietl);
						this.bookingMelForm.disable();
					}
				},
				(err) => {}
			);
			//}, 1000);
			//setTimeout(() => {
			//}, 1);
			// console.log(this);
			// console.log(this.choosenObject);
			// this.dodatkiIloscMeldunek.splice(0);
			// for (let i = 0; i < 5; i++) {
			// 	this.dodatkiIloscMeldunek.push(i);
			// 	this.bookingMelForm.addControl('dodatkiNazwa2', new FormControl('s'));
			// 	this.bookingMelForm.addControl('dodatkiCena2', new FormControl('o'));
			// 	this.bookingMelForm.addControl('dodatkiIlosc2', new FormControl('k'));
			// }
		}
		this.isLocalizationOn = this.appService.isLocalizationOn.getValue();
	}

	refreshView() {
		this.setSectionsInSectionItems();
		this.changePeriod(this.currentPeriod, false);
	}

	hideDetails(): void {
		this.showMelForm = false;
		this.showResForm = false;
		if (this.currentReservation.usluga) {
			for (let i = 0; i < this.currentReservation.usluga.length; i++) {
				this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiNazwa);
				this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiCena);
				this.bookingResForm.removeControl(this.dodatkiWyswietl[0].dodatkiIlosc);
			}
			this.dodatkiWyswietl.splice(0);
		}
		if (this.currentStayin.usluga) {
			for (let i = 0; i < this.currentStayin.usluga.length; i++) {
				this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiNazwa);
				this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiCena);
				this.bookingMelForm.removeControl(this.dodatkiMeldunekWyswietl[0].dodatkiIlosc);
			}
			this.dodatkiMeldunekWyswietl.splice(0);
		}
	}

	sendMail(scheduleObject): void {
		let preliminaryObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_WSTEPNA_INFO',
		};
		let confirmObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_LINK_DO_POTWIERDZENIA',
		};
		const send = forkJoin({
			Wstepna: this.receptionService.sendMailByStatus(preliminaryObject),
			Potwierdzenie: this.receptionService.sendMailByStatus(confirmObject),
		});
		send.subscribe(
			(res) => {
				this._snackBar.open(res.Wstepna.entity, '', { duration: 2000 });
				this._snackBar.open(res.Potwierdzenie.entity, '', {
					duration: 2000,
				});
			},
			(err) => {},
			() => {
				this.router.navigate(['/reception/booking-schedule']);
				this.showMelForm = false;
				this.showResForm = false;
			}
		);
	}

	onCheckinRes(item) {
		let data = item.object;
		data.dataOd = item.object.rezerwacjaDataOd;
		data.dataDo = item.object.rezerwacjaDataDo;
		this.openCheckinDialog(data);
	}

	openCheckinDialog(data): void {
		const dialogRef = this.dialog.open(CheckinReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'checkin') {
				let obj = {
					rezerwacjaId: data.rezerwacjaId,
				};
				this.reservationsService.checkinReservation(obj).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });
					if (res.status == 200) {
						//todo zamienic to na odswiezanie, a nie przeladowanie
						//window.location.reload();
						this.events.onEditBlockStayReservation();
					}
				});
			}
		});
	}

	trackByFn(index, item) {
		return index;
	}

	checkIsTTLockAvailable(): void {
		const param = 'TTLockObslugaWlaczona';
		this.receptionService.getParamValueByName(param).subscribe((res) => {
			this.isTTLockAvailable = res;
		});
	}

	generateKeyRezerwacja(rezerwacja): void {
		const rezerwacjaId = rezerwacja.rezerwacjaId;
		const message = `Czy na pewno chcesz wygenerować nowy klucz dla rezerwacji ${rezerwacjaId}?`;
		const dialogData = new ConfirmDialogModel('Nowy klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult == true) {
				this.receptionService.generateKeyRezerwacja(rezerwacjaId).subscribe((res) => {
					if (res.status == 200) {
						this._snackBar.open(res.entity, '', {
							duration: 2000,
						});
					}
				});
			}
		});
	}

	setSectionsInSectionItems() {
		this.sectionItems = new Array<SectionItem>();
		this.sections.sort((a, b) => {
			if (this.isLocalizationOn || this.authService.getHotelCode() == '0101' || this.authService.getHotelCode() == '9998') {
				//for instance for Arche
				//make Ujny Rez 4 and Ujny Rez 11 last two
				if (a.name == 'Ujny Rez 11' && b.name == 'Ujny Rez 4') {
					return 1;
				} else if (a.name == 'Ujny Rez 4' && b.name == 'Ujny Rez 11') {
					return -1;
				} else if (a.name == 'Ujny Rez 11' || a.name == 'Ujny Rez 4') return 1;
				else if (b.name == 'Ujny Rez 11' || b.name == 'Ujny Rez 4') return -1;
				else {
					//split a.name and b.name into smaller parts and than sort using these elements
					let lettersA: string[] = ['', ''];
					let stringNumbersA: string[] = ['', ''];
					let numbersA: number[] = [0, 0];
					let lettersB: string[] = ['', ''];
					let stringNumbersB: string[] = ['', ''];
					let numbersB: number[] = [0, 0];
					let whichA: number = 0;
					let whichB: number = 0;
					let i: number = 0;
					while (i < a.name.length) {
						if (whichA == 0 && i == 0) {
							lettersA[0] += a.name[0];
						} else if (whichA == 0 && i != 0) {
							if (!(a.name[i] == '1' || a.name[i] == '2' || a.name[i] == '3' || a.name[i] == '4' || a.name[i] == '5' || a.name[i] == '6' || a.name[i] == '7' || a.name[i] == '8' || a.name[i] == '9' || a.name[i] == '0'))
								lettersA[0] += a.name[i];
							else {
								whichA = 1;
								i--;
							}
						} else if (whichA == 1) {
							if (!(a.name[i] == ' ')) {
								stringNumbersA[0] += a.name[i];
							} else if (a.name[i] == ' ') {
								whichA = 2;
								i--;
							}
						} else if (whichA == 2) {
							if (!(a.name[i] == '1' || a.name[i] == '2' || a.name[i] == '3' || a.name[i] == '4' || a.name[i] == '5' || a.name[i] == '6' || a.name[i] == '7' || a.name[i] == '8' || a.name[i] == '9' || a.name[i] == '0'))
								lettersA[1] += a.name[i];
							else {
								whichA = 3;
								i--;
							}
						} else if (whichA == 3) {
							stringNumbersA[1] += a.name[i];
						}
						i++;
					}
					numbersA[0] = parseInt(stringNumbersA[0]);
					numbersA[1] = parseInt(stringNumbersA[1]);

					let j: number = 0;
					while (j < b.name.length) {
						if (whichB == 0 && j == 0) {
							lettersB[0] += b.name[0];
						} else if (whichB == 0 && j != 0) {
							if (!(b.name[j] == '1' || b.name[j] == '2' || b.name[j] == '3' || b.name[j] == '4' || b.name[j] == '5' || b.name[j] == '6' || b.name[j] == '7' || b.name[j] == '8' || b.name[j] == '9' || b.name[j] == '0'))
								lettersB[0] += b.name[j];
							else {
								whichB = 1;
								j--;
							}
						} else if (whichB == 1) {
							if (!(b.name[j] == ' ')) {
								stringNumbersB[0] += b.name[j];
							} else if (b.name[j] == ' ') {
								whichB = 2;
								j--;
							}
						} else if (whichB == 2) {
							if (!(b.name[j] == '1' || b.name[j] == '2' || b.name[j] == '3' || b.name[j] == '4' || b.name[j] == '5' || b.name[j] == '6' || b.name[j] == '7' || b.name[j] == '8' || b.name[j] == '9' || b.name[j] == '0'))
								lettersB[1] += b.name[j];
							else {
								whichB = 3;
								j--;
							}
						} else if (whichB == 3) {
							stringNumbersB[1] += b.name[j];
						}
						j++;
					}
					numbersB[0] = parseInt(stringNumbersB[0]);
					numbersB[1] = parseInt(stringNumbersB[1]);

					if (lettersA[0] != lettersB[0]) return lettersA[0] > lettersB[0] ? 1 : -1;
					else if (numbersA[0] != numbersB[0]) return numbersA[0] > numbersB[0] ? 1 : 0;
					else if (lettersA[1] != lettersB[1]) return lettersA[1] > lettersB[1] ? 1 : -1;
					else return numbersA[1] > numbersB[1] ? 1 : 0;
				}
			} else if (this.authService.getHotelCode() == '0051') {
				//NowaMotława
				//split a.name and b.name into smaller parts and than sort using these elements
				let lettersA: string[] = ['', ''];
				let stringNumbersA: string[] = ['', ''];
				let numbersA: number[] = [0, 0];
				let lettersB: string[] = ['', ''];
				let stringNumbersB: string[] = ['', ''];
				let numbersB: number[] = [0, 0];
				let whichA: number = 0;
				let whichB: number = 0;
				let i: number = 0;
				while (i < a.name.length) {
					if (whichA == 0 && i == 0) {
						lettersA[0] += a.name[0];
					} else if (whichA == 0 && i != 0) {
						if (!(a.name[i] == ' ')) lettersA[0] += a.name[i];
						else {
							whichA = 1;
							i--;
						}
					} else if (whichA == 1) {
						if (a.name[i] == ' ') {
							whichA = 2;
						}
					} else if (whichA == 2) {
						stringNumbersA[0] += a.name[i];
					}
					i++;
				}
				numbersA[0] = parseInt(stringNumbersA[0].trim());
				numbersA[1] = parseInt(stringNumbersA[1].trim());

				let j: number = 0;
				while (j < b.name.length) {
					if (whichB == 0 && j == 0) {
						lettersB[0] += b.name[0];
					} else if (whichB == 0 && j != 0) {
						if (!(b.name[j] == ' ')) lettersB[0] += b.name[j];
						else {
							whichB = 1;
							j--;
						}
					} else if (whichB == 1) {
						if (b.name[j] == ' ') {
							whichB = 2;
						}
					} else if (whichB == 2) {
						stringNumbersB[0] += b.name[j];
					}
					j++;
				}
				numbersB[0] = parseInt(stringNumbersB[0]);
				if (lettersA[0] != lettersB[0]) {
					return lettersA[0] > lettersB[0] ? 1 : -1;
				} else if (numbersA[0] != numbersB[0]) {
					return numbersA[0] > numbersB[0] ? 1 : -1;
				} else return 0;
			} else if (this.authService.getHotelCode() == '0271') {
				if (this.currentSection.name == 'Pokój') return parseInt(a.name) - parseInt(b.name);
				else {
					return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
				}
			} else {
				//for hotels without localization
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			}
		});
		this.sections.forEach((section) => {
			const perSectionItem = new SectionItem();
			perSectionItem.section = section;
			perSectionItem.minRowHeight = this.minRowHeight;
			this.sectionItems.push(perSectionItem);
		});
	}

	lockOrUnlock(): void {
		this.locked = !this.locked;
		if (this.locked == true) {
			this.buttonStatus = 'lock';
			this.lockerTooltip = 'Odblokuj zmiany';
			this.bookingMelForm.disable();
			this.bookingResForm.disable();
		} else {
			this.buttonStatus = 'lock_open';
			this.lockerTooltip = 'Zablokuj zmiany';
			this.bookingMelForm.enable();
			this.bookingMelForm.controls.pokojNazwa.disable();
			this.bookingMelForm.controls.polozenieNazwa.disable();
			this.bookingMelForm.controls.osobaAdres.disable();
			this.bookingMelForm.controls.osobaTelefon.disable();
			this.bookingMelForm.controls.zrodloRezerwacji.disable();
			this.bookingResForm.enable();
			this.bookingResForm.controls.pokojNazwa.disable();
			this.bookingResForm.controls.polozenieNazwa.disable();
			this.bookingResForm.controls.osobaAdres.disable();
			this.bookingResForm.controls.osobaTelefon.disable();
			this.bookingResForm.controls.zrodloRezerwacji.disable();
			if (this.currentReservation.usluga) {
				for (let i = 0; i < this.currentReservation.usluga.length; i++) {
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiNazwa].disable();
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiCena].disable();
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiIlosc].disable();
				}
			}
			if (this.currentStayin.usluga) {
				for (let i = 0; i < this.currentStayin.usluga.length; i++) {
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiNazwa].disable();
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiCena].disable();
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiIlosc].disable();
				}
			}
		}
	}

	lockOrUnlockNextOpening(whetherToBlock: boolean): void {
		if (whetherToBlock) {
			this.locked = true;
			if (this.locked == true) {
				this.buttonStatus = 'lock';
				this.lockerTooltip = 'Odblokuj zmiany';
				this.bookingMelForm.disable();
				this.bookingResForm.disable();
			} else {
				this.buttonStatus = 'lock_open';
				this.lockerTooltip = 'Zablokuj zmiany';
				this.bookingMelForm.enable();
				this.bookingMelForm.controls.pokojNazwa.disable();
				this.bookingMelForm.controls.polozenieNazwa.disable();
				this.bookingMelForm.controls.osobaAdres.disable();
				this.bookingMelForm.controls.osobaTelefon.disable();
				this.bookingMelForm.controls.zrodloRezerwacji.disable();
				this.bookingResForm.enable();
				this.bookingResForm.controls.pokojNazwa.disable();
				this.bookingResForm.controls.polozenieNazwa.disable();
				this.bookingResForm.controls.osobaAdres.disable();
				this.bookingResForm.controls.osobaTelefon.disable();
				this.bookingResForm.controls.zrodloRezerwacji.disable();
				for (let i = 0; i < this.currentReservation.usluga.length; i++) {
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiNazwa].disable();
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiCena].disable();
					this.bookingResForm.controls[this.dodatkiWyswietl[i].dodatkiIlosc].disable();
				}
				for (let i = 0; i < this.currentStayin.usluga.length; i++) {
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiNazwa].disable();
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiCena].disable();
					this.bookingMelForm.controls[this.dodatkiMeldunekWyswietl[i].dodatkiIlosc].disable();
				}
			}
		}
	}

	onSubmit(whatToSubmit: string) {
		this.confirmDialog('Czy jesteś pewny wprowadzonych zmian?', whatToSubmit);
	}

	confirmDialog(text, callTrigger): void {
		const dialogData = new ConfirmDialogModel('Potwierdź akcję', text);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult === true && callTrigger === 'formSubmit') {
				let data;
				data = this.bookingResForm.value;

				data['rezerwacjaId'] = this.choosenObject.rezerwacjaId;
				if (this.bookingResForm.value.polozenieId == null) {
					delete data.polozenieId;
				}
				if (this.bookingResForm.value.pokojId == null) {
					delete data.pokojId;
				}
				data.kwotaZaPobyt = parseFloat(this.bookingResForm.value.pobytBrutto).toFixed(2);
				this.stayinsNewService.editReservation(data).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });

					if ((res.status = 200)) {
						this.hideDetails();
					}
				});
			} else if (dialogResult === true && callTrigger === 'formMelSubmit') {
				let data;
				data = this.bookingMelForm.value;
				data['meldunekId'] = this.choosenObject.meldunekId;
				if (this.bookingResForm.value.polozenieId == null) {
					delete data.polozenieId;
				}
				if (this.bookingResForm.value.pokojId == null) {
					delete data.pokojId;
				}
				data.kwotaZaPobyt = parseFloat(this.bookingResForm.value.pobytBrutto).toFixed(2);
				this.stayinsNewService.editStayin(data).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });

					if ((res.status = 200)) {
						this.hideDetails();
					}
				});
			}
		});
	}

	onCancelRes(item) {
		let data = {
			reason: '',
			item: item,
		};
		this.openCancelDialog(data, 'single');
	}

	openCancelDialog(data, type: string): void {
		const dialogRef = this.dialog.open(CancelReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		let obj = {};
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'confirm') {
				if (type === 'single') {
					obj = {
						rezerwacjaId: data.item.rezerwacjaId,
						powodAnulacji: data.reason,
					};
				} else {
					obj = {
						grupaId: data.item.grupaId,
						powodAnulacji: data.reason,
					};
				}
				this.reservationsService.reservationCancel(obj, type).subscribe((res) => {
					this._snackBar.open(res.entity, '', { duration: 2000 });
					if (res.status === 200) {
						this.events.onEditBlockStayReservation();
						//todo zamienic to na odswiezanie, a nie przeladowanie
						// window.location.reload();
					}
				});
			}
		});
	}

	setItemsInSectionItems() {
		const itemMetas = new Array<ItemMeta>();

		this.sectionItems.forEach((ele) => {
			ele.itemMetas = new Array<ItemMeta>();
			ele.minRowHeight = this.minRowHeight;

			this.items.filter((i) => {
				let itemMeta = new ItemMeta();

				if (i.sectionID === ele.section.id) {
					itemMeta.item = i;
					if (itemMeta.item.start <= this.end && itemMeta.item.end >= this.start) {
						itemMeta = this.itemMetaCal(itemMeta);
						ele.itemMetas.push(itemMeta);
						itemMetas.push(itemMeta);
					}
				}
			});
		});
		const sortedItems = itemMetas.reduce((sortItems: {}, itemMeta: ItemMeta) => {
			const index = this.sectionItems.findIndex((sectionItem) => sectionItem.section.id === itemMeta.item.sectionID);

			if (!sortItems[index]) {
				sortItems[index] = [];
			}
			if (
				sortItems[index] !== undefined &&
				sortItems[index].findIndex((element) => {
					return element.item.id == itemMeta.item.id;
				}) == -1
			) {
				sortItems[index].push(itemMeta);
			}
			return sortItems;
		}, {});
		this.calCssTop(sortedItems);
	}

	cancelPasscode(rezerwacjaId, pokojNazwa): void {
		const resId = rezerwacjaId;
		const message = `Czy na pewno chcesz dezaktywować klucz dla pokoju` + ' ' + `${pokojNazwa}?`;
		const dialogData = new ConfirmDialogModel('Dezaktywuj klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult == true) {
				this.receptionService.cancelPassCodeRes(resId).subscribe((res) => {
					this._snackBar.open('Kod anulowano', '', {
						duration: 2000,
					});
				});
			}
		});
	}

	itemMetaCal(itemMeta: ItemMeta) {
		const foundStart = moment.max(moment(itemMeta.item.start), this.start);
		const foundEnd = moment.min(moment(itemMeta.item.end), this.end);
		let widthMinuteDiff = Math.abs(foundStart.diff(foundEnd, 'minutes'));
		let leftMinuteDiff = foundStart.diff(this.start, 'minutes');

		if (this.showBusinessDayOnly) {
			widthMinuteDiff -= this.getNumberOfWeekendDays(moment(foundStart), moment(foundEnd)) * this.currentPeriod.timeFramePeriod;
			leftMinuteDiff -= this.getNumberOfWeekendDays(moment(this.start), moment(foundStart)) * this.currentPeriod.timeFramePeriod;
		}

		itemMeta.cssLeft = (leftMinuteDiff / this.currentPeriodMinuteDiff) * 100;
		itemMeta.cssWidth = (widthMinuteDiff / this.currentPeriodMinuteDiff) * 100;

		if (itemMeta.item.start >= this.start) {
			itemMeta.isStart = true;
		}
		if (itemMeta.item.end <= this.end) {
			itemMeta.isEnd = true;
		}

		return itemMeta;
	}

	calCssTop(sortedItems) {
		for (const prop of Object.keys(sortedItems)) {
			for (let i = 0; i < sortedItems[prop].length; i++) {
				let elemBottom;
				const elem = sortedItems[prop][i];
				for (let prev = 0; prev < i; prev++) {
					const prevElem = sortedItems[prop][prev];
					const prevElemBottom = prevElem.cssTop + this.minRowHeight;
					elemBottom = elem.cssTop + this.minRowHeight;
					if (
						((prevElem.item.start <= elem.item.start && elem.item.start <= prevElem.item.end) ||
							(prevElem.item.start <= elem.item.end && elem.item.end <= prevElem.item.end) ||
							(prevElem.item.start >= elem.item.start && elem.item.end >= prevElem.item.end)) &&
						((prevElem.cssTop <= elem.cssTop && elem.cssTop <= prevElemBottom) || (prevElem.cssTop <= elemBottom && elemBottom <= prevElemBottom))
					) {
						elem.cssTop = prevElemBottom + 1;
						prev = 0;
					}
				}

				elemBottom = elem.cssTop + this.minRowHeight + 1;
				if (this.sectionItems[Number(prop)] && elemBottom > this.sectionItems[Number(prop)].minRowHeight) {
					this.sectionItems[Number(prop)].minRowHeight = elemBottom;
				}
			}
		}
	}

	changePeriod(period: Period, userTrigger: boolean = true) {
		this.currentPeriod = period;
		if (this.start == undefined) {
			this.start = moment().startOf('day').subtract(7, 'days');
		}

		const _start = this.start;
		this.end = moment(_start).add(this.currentPeriod.timeFrameOverall, 'minutes').endOf('day');
		this.currentPeriodMinuteDiff = Math.abs(this.start.diff(this.end, 'minutes'));

		if (userTrigger && this.events.PeriodChange) {
			this.events.PeriodChange(this.start, this.end);
		}

		if (this.showBusinessDayOnly) {
			this.currentPeriodMinuteDiff -= this.getNumberOfWeekendDays(moment(this.start), moment(this.end)) * this.currentPeriod.timeFramePeriod;
		}

		this.header = new Array<Header>();
		this.currentPeriod.timeFrameHeaders.forEach((ele: string, index: number) => {
			this.header.push(this.getDatesBetweenTwoDates(ele, index));
		});

		this.setItemsInSectionItems();
		this.showCurrentTimeIndicator();
	}

	showCurrentTimeIndicator = () => {
		if (this.ShowCurrentTimeHandle) {
			clearTimeout(this.ShowCurrentTimeHandle);
		}

		const currentTime = moment();
		if (currentTime >= this.start && currentTime <= this.end) {
			this.currentTimeVisibility = 'visible';
			this.currentTimeIndicatorPosition = (Math.abs(this.start.diff(currentTime, 'minutes')) / this.currentPeriodMinuteDiff) * 100 + '%';
			this.currentTimeTitle = currentTime.format(this.currentTimeFormat);
		} else {
			this.currentTimeVisibility = 'hidden';
		}
		this.ShowCurrentTimeHandle = setTimeout(this.showCurrentTimeIndicator, 30000);
	};

	gotoToday() {
		this.start = moment().startOf('day').subtract(7, 'days');
		this.changePeriod(this.currentPeriod);
	}

	nextPeriod() {
		this.choosenObject.rezerwacjaId = null;
		this.choosenObject.meldunekId = null;
		this.start.add(this.currentPeriod.timeFrameOverall, 'minutes');
		this.changePeriod(this.currentPeriod);
	}

	previousPeriod() {
		this.choosenObject.rezerwacjaId = null;
		this.choosenObject.meldunekId = null;
		this.start.subtract(this.currentPeriod.timeFrameOverall, 'minutes');
		this.changePeriod(this.currentPeriod);
	}

	gotoDate(event: any) {
		this.showGotoModal = false;
		this.start = moment(event).startOf('day').subtract(7, 'days');
		this.changePeriod(this.currentPeriod);
	}

	getDatesBetweenTwoDates(format: string, index: number): Header {
		const now = moment(this.start);
		const dates = new Header();
		let prev: string;
		let colspan = 0;

		while (now.isBefore(this.end) || now.isSame(this.end)) {
			if (!this.showBusinessDayOnly || (now.day() !== 0 && now.day() !== 6)) {
				const headerDetails = new HeaderDetails();
				headerDetails.name = now.locale(this.locale).format(format);
				if (prev && prev !== headerDetails.name) {
					colspan = 1;
				} else {
					colspan++;
					dates.headerDetails.pop();
				}
				prev = headerDetails.name;
				headerDetails.colspan = colspan;
				headerDetails.tooltip = this.currentPeriod.timeFrameHeadersTooltip && this.currentPeriod.timeFrameHeadersTooltip[index] ? now.locale(this.locale).format(this.currentPeriod.timeFrameHeadersTooltip[index]) : '';
				if (format == 'DD') {
					headerDetails.isSaturday = now.locale(this.locale).day() === 6;
					headerDetails.isSunday = now.locale(this.locale).day() === 0;
					switch (now.locale(this.locale).day()) {
						case 0:
							headerDetails.dayOfWeek = 'Nd';
							break;
						case 1:
							headerDetails.dayOfWeek = 'Pn';
							break;
						case 2:
							headerDetails.dayOfWeek = 'Wt';
							break;
						case 3:
							headerDetails.dayOfWeek = 'Śr';
							break;
						case 4:
							headerDetails.dayOfWeek = 'Cz';
							break;
						case 5:
							headerDetails.dayOfWeek = 'Pt';
							break;
						case 6:
							headerDetails.dayOfWeek = 'Sb';
							break;
						default:
							break;
					}
				}
				dates.headerDetails.push(headerDetails);
			}
			now.add(this.currentPeriod.timeFramePeriod, 'minutes');
		}
		return dates;
	}

	getNumberOfWeekendDays(startDate, endDate) {
		let count = 0;
		while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
			if (startDate.day() === 0 || startDate.day() === 6) {
				count++;
			}
			startDate.add(this.currentPeriod.timeFramePeriod, 'minutes');
		}
		return count;
	}

	drop(event: CdkDragDrop<Section>) {
		event.item.data.sectionID = event.container.data.id;
		this.refreshView();
		this.events.ItemDropped(event.item.data);
	}

	refresh() {
		this.subscription.add(
			this.service.refreshView.asObservable().subscribe(() => {
				this.refreshView();
			})
		);
	}

	onSectionChange(event) {
		this.events.SectionChange(this.schedulerTypes[event.target.value]);
		this.currentSection = this.schedulerTypes[event.target.value];
	}

	onLocalizationChange(event) {
		this.events.LocalizationChange(this.localizationArray[event.target.value]);
		this.choosenLocal = event.target.options[event.target.options.selectedIndex].text;
		this.service.sendMessage(this.choosenLocal);
	}

	showRoomBlockerDialog(element) {
		const dialogRef = this.dialog.open(RoomBlockerDialogComponent, {
			data: element,
			maxWidth: '400px',
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'Edited') {
				this.events.onEditBlockStayReservation();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.dialog.closeAll();
	}

	// consoleAll() {
	//   console.log(this);
	// }
}
