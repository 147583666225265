import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerRangeComponent } from 'src/app/shared/datepicker-range/datepicker-range.component';
import { NgbdSortableHeader } from 'src/app/shared/sortable-header/sortable-header.directive';
import { CarouselComponent } from './carousel/carousel.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatetimepickerComponent } from './datetimepicker/datetimepicker.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { DualListPickerComponent } from './dual-list-picker/dual-list-picker.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { BasicConfirmationDialogComponent } from './basic-confirmation-dialog/basic-confirmation-dialog.component';
import { BookingScheduleComponent } from '../pages/reception/booking-schedule/booking-schedule.component';
import { ComponentsModule } from '../components/components.module';
import { NgxTimeSchedulerModule } from './ngx-time-scheduler/src/public_api';
import { AddonsComponent } from './addons/addons.component';
import { RoomBlockerDialogComponent } from './room-blocker-dialog/room-blocker-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        MatIconModule,
        MatTableModule,
        TranslateModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatExpansionModule,
        MatCardModule,
        MatCheckboxModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatBadgeModule,
        AngularDualListBoxModule,
        ComponentsModule,
        NgxTimeSchedulerModule,
    ],
    exports: [
        DatepickerRangeComponent,
        DatetimepickerComponent,
        CarouselComponent,
        ImageModalComponent,
        SpinnerComponent,
        LightboxComponent,
        DatepickerComponent,
        DualListPickerComponent,
        MatIconModule,
        MatDialogModule,
        NgbdSortableHeader,
        MatTableModule,
        MatIconModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatExpansionModule,
        MatCardModule,
        MatCheckboxModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatBadgeModule,
        BookingScheduleComponent,
    ],
    declarations: [
        DatepickerRangeComponent,
        DatetimepickerComponent,
        CarouselComponent,
        ImageModalComponent,
        SpinnerComponent,
        LightboxComponent,
        DatepickerComponent,
        NgbdSortableHeader,
        DualListPickerComponent,
        BasicConfirmationDialogComponent,
        BookingScheduleComponent,
        AddonsComponent,
        RoomBlockerDialogComponent,
    ],
    bootstrap: [
        DatepickerRangeComponent,
        DatetimepickerComponent,
        CarouselComponent,
        ImageModalComponent,
        LightboxComponent,
        SpinnerComponent,
        DatepickerComponent,
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pl-PL' }],
})
export class SharedModule {}
