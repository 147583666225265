import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-navbar-map",
  templateUrl: "./navbar-map.component.html",
  styleUrls: ["./navbar-map.component.scss"],
})
export class NavbarMapComponent implements OnInit {
  location: Location;
  routeSubscription: Subscription;
  locationLinks: {
    title: string;
    path: string;
  }[];

  constructor(location: Location, private router: Router) {
    this.location = location;
  }

  ngOnInit(): void {
    // Set navbar-map on init
    this.getPath();

    // Control navbar-map on route change
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getPath();
      });
  }

  getPath() {
    this.locationLinks = [];
    let locationPath = this.location.path();
    let locationPathArray = locationPath.split("/").slice(1);
    for (let i = 0; i < locationPathArray.length; i++) {
      let title = locationPathArray[i];
      let path = "/" + locationPathArray[0];
      for (let j = 1; j < i + 1; j++) {
        path += "/" + locationPathArray[j];
      }
      this.locationLinks[i] = {
        title: title[0].toUpperCase() + title.substr(1, title.length),
        path: path,
      };
    }
  }
}
