export class User {
  constructor(
    public username: string,
    public userId: number,
    public hotelCode: string,
    public uuid: string,
    public tokenExpirationDate: Date,
    public isAdmin?: boolean,
    public hotelName?: string,
    public authorities?: any,
    public employeeOsobaId?: string,
    public hotelId?: string,
    public permissions?: any
  ) {}
}
