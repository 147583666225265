import { Component, OnInit } from '@angular/core';
import { ColorsPanelService } from 'src/app/components/colors-panel/colors-panel.service';

@Component({
    selector: 'app-multiproperty',
    templateUrl: './multiproperty.component.html',
    styleUrls: ['./multiproperty.component.scss'],
})
export class MultipropertyComponent implements OnInit {
    constructor() {
        document.getElementById('blank-page-loading')
            ? (document.getElementById('blank-page-loading').style.display =
                  'none')
            : null;
    }

    ngOnInit(): void {}
}
