import { Component, OnInit, Input } from "@angular/core";
import { RoomServiceService } from "src/app/pages/housekeeping/room-service/room-service.service";

@Component({
  selector: "app-lightbox",
  templateUrl: "./lightbox.component.html",
  styleUrls: ["./lightbox.component.scss"],
})
export class LightboxComponent implements OnInit {
  @Input() images;
  @Input() removeArray;
  @Input() type;
  @Input() serviceId;
  slideIndex = 0;
  constructor(
    private service: RoomServiceService
  ) {}

  ngOnInit(): void {
  }
  loadImages(): void {
    // this.imageService.fetchImages()
    //  .subscribe(images => this.images = images);
  }
  openModal() {
    document.getElementById("imgModal").style.display = "block";
  }

  closeModal() {
    document.getElementById("imgModal").style.display = "none";
  }

  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(slideIndex);

  showSlides(n) {
    let i;
    const slides = document.getElementsByClassName(
      "img-slides"
    ) as HTMLCollectionOf<HTMLElement>;
    const dots = document.getElementsByClassName("images") as HTMLCollectionOf<
      HTMLElement
    >;
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    if (dots && dots.length > 0) {
      dots[this.slideIndex - 1].className += " active";
    }
  }

  onClickDeleteImage(i, imageId) {
    this.removeArray.forEach((imagesList) => {
      imagesList.splice(i, 1);
      let serviceObject = {
        zlecenieId: this.serviceId,
        fotoId: imageId,
      }
      this.service.removePhotoOfServiceRoom(serviceObject).subscribe()
      if (imagesList.length === 0 ) {
        this.closeModal();
      }
    });
    }
}
