declare interface SubMenu {
    path: string;
    title: string;
}
declare interface RouteInfo {
    path: string;
    active: boolean;
    title: string;
    icon: string;
    class: string;
    hkModeEnabled: boolean;
    subMenu: SubMenu[];
    isHide?: boolean;
}
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        active: true,
        title: 'Dashboard',
        icon: 'icon-chart-pie-36',
        class: '',
        hkModeEnabled: true,
        subMenu: [],
    },
    {
        path: '/reception',
        active: false,
        title: 'Reception',
        icon: 'icon-book-bookmark',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'booking-schedule',
                title: 'Booking Schedule',
            },
            {
                path: 'reservations',
                title: 'Reservations',
            },
            {
                path: 'arrivals',
                title: 'Arrivals',
            },
            {
                path: 'stayins',
                title: 'Stayins',
            },
            {
                path: 'departures',
                title: 'Departures',
            },
            {
                path: 'pricelist',
                title: 'Pricelist',
            },
            {
                path: 'work-schedule',
                title: 'Work Schedule',
            },
        ],
    },
    {
        path: '/guest-book',
        active: false,
        title: 'Guest Book',
        icon: 'icon-money-coins',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'person',
                title: 'Person',
            },
            {
                path: 'company',
                title: 'Company',
            },
        ],
    },
    {
        path: '/housekeeping',
        active: true,
        title: 'Housekeeping',
        icon: 'icon-cart',
        class: '',
        hkModeEnabled: true,
        subMenu: [
            {
                path: 'service-order',
                title: 'Serviceorder',
            },
            {
                path: 'booking-schedule',
                title: 'Booking Schedule',
            },
        ],
    },
    {
        path: '/reports',
        active: true,
        title: 'Reports',
        icon: 'icon-coins',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'foodcost',
                title: 'Foodcost',
            },
            {
                path: 'housekeeping-report',
                title: 'Housekeeping',
            },
            {
                path: 'raportmanagera',
                title: 'Raport Managera',
            },
            // {
            //   path: "dailyreports",
            //   title: "Daily Reports",
            // },
        ],
    },
    {
        path: '/settings',
        active: true,
        title: 'Settings',
        icon: 'icon-settings-gear-63',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'addons',
                title: 'Addons',
            },
            {
                path: 'service-providers',
                title: 'Service Providers',
            },
        ],
    },
    {
        path: '/analytics',
        active: true,
        title: 'Analytics',
        icon: 'icon-paper',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'reservations',
                title: 'Reservations',
            },
            {
                path: 'payments',
                title: 'Payments',
            },
            // {
            //   path: "activity",
            //   title: "Activity",
            // },
        ],
    },
    {
        path: '/marketing',
        active: false,
        title: 'Marketing',
        icon: 'icon-money-coins',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'discount-codes',
                title: 'Discount Codes',
            },
        ],
    },
    {
        path: '/invoices',
        active: true,
        title: 'Invoices',
        icon: 'icon-single-copy-04',
        class: '',
        hkModeEnabled: false,
        subMenu: [],
    },
    {
        path: '/documents',
        active: true,
        title: 'documents',
        icon: 'icon-single-copy-04',
        class: '',
        hkModeEnabled: false,
        subMenu: [
            {
                path: 'pos-print',
                title: 'POS print',
            },
        ],
    },
];
