import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { RESERVATIONS_LIST_PARAMS } from "../../reception/reservations/models/request-params.model";
import * as moment from "moment";
import { filter, take, tap, toArray } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class ServiceOrderService {
  serviceOrdersList = new BehaviorSubject<any[]>(null);
  requestParams = new BehaviorSubject<RESERVATIONS_LIST_PARAMS>({
    dataOd: moment().subtract(15, "days").format("YYYY-MM-DD"),
    dataDo: moment().add(15, "days").format("YYYY-MM-DD"),
  });

  constructor(private http: HttpClient) {}

  getServiceOrderList(): Observable<any> {
    return this.http.get("api/zlecenie/getZlecenieList");
  }
  getDostepneTypy(data): Observable<any> {
    return this.http.get("api/grupa/getDostepneTypyPokoiZCenami", {
      params: data,
    });
  }
  
  getBlockForRooms(): Observable<any> {
    return this.http.get("api/zlecenie/getBlokadaList");
  }

  changeServiceOrderList(params: RESERVATIONS_LIST_PARAMS): Observable<any[]> {
    let urlParams = "";

    // Get and set parameters form request
    if (params && params.dataDo && params.dataOd) {
      urlParams = `?dataDo=${params.dataDo}&dataOd=${params.dataOd}`;

      // Store params in this service (requestParams)
      this.requestParams.next({
        dataOd: params.dataOd,
        dataDo: params.dataDo,
      });
    } else {
      // Clear params in this service (requestParams)
      this.requestParams.next(null);
    }

    return this.http
      .get<any[]>("api/zlecenie/getZlecenieList" + urlParams)
      .pipe(
        take(1),
        tap((res) => {
          this.serviceOrdersList.next(res);
        })
      );
  }
}
