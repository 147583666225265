import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { BasicConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { ReservationsService } from '../../../reservations/reservations.service';
import { StayinsNewService } from '../../../stayins/stayins-new/stayins-new.service';

@Component({
	selector: 'app-edit-stayin',
	templateUrl: './edit-stayin.component.html',
	styleUrls: ['./edit-stayin.component.scss', '../edit-reservation/edit-reservation.component.scss'],
})
export class EditStayinComponent implements OnInit {
	public availableRoomTypes = [];
	public availableRooms = [];
	public localizationSelect = false;
	public startTime = moment(this.data.meldunekDataOd);
	public endTime = moment(this.data.meldunekDataDo);
	public stayinForm: FormGroup;
	public minDate = new Date();
	public counter = 0;
	public pricePlan = [];
	public totalPriceForPlan = 0;
	public isPriceFromPlan: boolean = true;
	public isToday: boolean;
	public result: string = '';
	public selectedRoom;
	public selectedType;
	public localizationArray = [];
	public fullLocalizationTypeList = [];
	public selectedPolozenieId: number;
	public editWhat: string = '';
	public dodatkiWyswietl: any[] = [];
	public currentReservation: any = '';

	constructor(
		public appService: AppServiceService,
		public dialogRef: MatDialogRef<EditStayinComponent>,
		private formBuilder: FormBuilder,
		public stayinsNewService: StayinsNewService,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private reservationService: ReservationsService
	) {
		this.stayinForm = this.formBuilder.group({
			meldunekId: [this.data.meldunekId, Validators.required],
			meldunekDataOd: [this.startTime.format('YYYY-MM-DD')],
			meldunekDataDo: [this.endTime.format('YYYY-MM-DD')],
			// pobytBrutto: [parseFloat(this.data.pobytBrutto).toFixed(2)],
			passCode: [{ value: this.data.passCode, disabled: true }],
			osobaImiona: [{ value: this.data.osobaImiona, disabled: true }],
			osobaNazwisko: [{ value: this.data.osobaNazwisko, disabled: true }],
			osobaEmail: [{ value: this.data.osobaEmail, disabled: true }],
			osobaTelefon: [{ value: this.data.osobaTelefon, disabled: true }],
			pokojId: [this.data.pokojId],
			pokojtypId: [this.data.pokojtypId],
			polozenieId: [this.data.polozenieId],
			uwagiPortal: [this.data.meldunekUwagiPortal],
			uwagi: [this.data.meldunekUwagiMemo],
		});
	}

	ngOnInit(): void {
		this.selectedType = this.data.pokojtypNazwa;
		this.getInitialRoomData(this.data.pokojtypId);
		if (this.data.polozenieId) {
			this.stayinForm.get('polozenieId').setValue(this.data.polozenieId);
		}
		moment().format('MM-DD') == moment(this.stayinForm.value.melduneDataOd).format('MM-DD') ? (this.isToday = true) : (this.isToday = false);
		this.stayinForm.get('passCode').setValue(this.data.passCode);

		this.dialogRef.keydownEvents().subscribe((event) => {
			if (event.key === 'Escape') {
				this.onCancel();
			}
		});

		setTimeout(() => {
			this.reservationService.getReservationInfo(this.data.rezerwacjaId).subscribe((res: any) => {
				this.currentReservation = res;
				if (this.currentReservation.usluga) {
					//console.log(this.currentReservation.usluga[0]);
					for (let i = 0; i < this.currentReservation.usluga.length; i++) {
						this.dodatkiWyswietl.push({ dodatkiNazwa: 'dodatkiNazwa' + i, dodatkiCena: 'dodatkiCena' + i, dodatkiIlosc: 'dodatkiIlosc' + i });
						console.log(this.currentReservation.usluga[i].asortNazwa);
						this.stayinForm.addControl(this.dodatkiWyswietl[i].dodatkiNazwa, new FormControl(this.currentReservation.usluga[i].asortNazwa));
						this.stayinForm.addControl(this.dodatkiWyswietl[i].dodatkiCena, new FormControl(this.currentReservation.usluga[i].uslugaCenaJednBrutto));
						this.stayinForm.addControl(this.dodatkiWyswietl[i].dodatkiIlosc, new FormControl(this.currentReservation.usluga[i].uslugaIlosc));
					}
					// console.log(this.dodatkiWyswietl);
					for (let i = 0; i < this.currentReservation.usluga.length; i++) {
						this.stayinForm.controls[this.dodatkiWyswietl[i].dodatkiNazwa].disable();
						this.stayinForm.controls[this.dodatkiWyswietl[i].dodatkiCena].disable();
						this.stayinForm.controls[this.dodatkiWyswietl[i].dodatkiIlosc].disable();
					}
				}
			});
		}, 500);

		// setTimeout(() => {
		// 	//console.log(this.currentReservation);
		// }, 1000);
	}

	getInitialRoomData(pokojTypId?: number) {
		let dates = {
			dataDo: this.data.dataDo ? this.data.dataDo : this.data.meldunekDataDo,
			dataOd: this.data.dataOd ? this.data.dataOd : this.data.meldunekDataOd,
		};
		let defaultRoomType = this.data.pokojtypId;
		let defaultPolozenieId = this.data.polozenieId;
		this.stayinsNewService.getDostepneTypy(dates).subscribe(
			(res) => {
				this.fullLocalizationTypeList = res;
				if (pokojTypId) {
					this.fullLocalizationTypeList.forEach((element) => {
						if (element.pokojtypId === pokojTypId && this.selectedPolozenieId === element.polozenieId) {
							this.availableRooms = element.pokoje;
						}
					});
				}
				this.localizationArray = [];
				res.forEach((element) => {
					this.localizationArray.push({
						polozenieNazwa: element.polozenieNazwa,
						polozenieId: element.polozenieId,
					});
				});
				if (this.localizationSelect === false) {
					this.availableRoomTypes = res;
				} else {
					res.forEach((el) => {
						if (el.polozenieId === this.data.polozenieId) {
							this.availableRoomTypes.push(el);
						}
					});
					this.stayinForm.controls['pokojtypId'].setValue(defaultRoomType);
					const roomsFromSpecificType = this.availableRoomTypes.filter((rez) => {
						if (defaultPolozenieId) {
							return rez.pokojtypId == defaultRoomType || rez.polozenieId == defaultPolozenieId;
						} else {
							return rez.pokojtypId == defaultRoomType;
						}
					})[0];
					if (roomsFromSpecificType.length >= 1) {
						this.availableRooms = roomsFromSpecificType[0].pokoje;
					} else {
						this.availableRooms = [];
					}
					this.availableRoomTypes.forEach((element) => {
						if (element.pokojtypId === this.data.pokojtypId) {
							this.availableRooms = element.pokoje;
						}
					});
					this.availableRooms.push({
						pokojId: this.data.pokojId,
						pokojNazwa: this.data.pokojNazwa,
					});
				}
				this.stayinForm.controls['pokojId'].setValue(this.data.pokojId);
			},
			(error) => {
				console.log('Error happened' + error);
			}
		);
		moment().format('MM-DD') == moment(this.stayinForm.value.meldunekDataOd).format('MM-DD') ? (this.isToday = true) : (this.isToday = false);

		this.appService.isLocalizationOn.value ? (this.localizationSelect = true) : (this.localizationSelect = false);
	}

	onSubmit() {
		this.confirmDialog('Czy jesteś pewny wprowadzonych zmian?', 'formSubmit');
	}

	confirmDialog(text, callTrigger): void {
		const dialogData = new ConfirmDialogModel('Potwierdź akcję', text);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (callTrigger === 'changePrice') {
				this.isPriceFromPlan = !dialogResult;
				this.result = dialogResult;
			}
			if (dialogResult === false && callTrigger === 'changePrice') {
				this.totalPriceForPlan ? this.stayinForm.controls['pobytBrutto'].setValue(this.totalPriceForPlan.toFixed(2)) : this.stayinForm.controls['pobytBrutto'].setValue(parseFloat(this.data.pobytBrutto).toFixed(2));
			}
			if (dialogResult === true && callTrigger === 'formSubmit') {
				let data;
				data = this.stayinForm.value;
				this.stayinsNewService.editStayin(data).subscribe((res) => {
					if (res.status === 200) {
						this.dialogRef.close(true);
					}
				});
			}
		});
	}

	onCancel() {
		this.dialogRef.close(false);
	}
}
