import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
	providedIn: 'root',
})
export class SummaryAddonsService {
	public summaryAddons: number = 0; //all cost for rooms' addons (part of summary for rooms and for addons)
	public addons: any[] = []; //data for example name and amount of addons
	public addonsTableNumber: any[][] = []; //number of specified addon for specified room
	public przydzielonePokojeNumberTable: number[] = []; //info about number of przydzielonePokoje in roomTypes
	public addonsTablePointerNumber: number[] = []; //info about number pointer to amount of specified addon
	public addonsTableCounter: number = 0; //number of all selected rooms
	public disabledAddons: boolean[] = [];
	public ifNewDates: boolean = false;
	public ifUpdateAllData: boolean = true;
	public summaryCost: number = 0;
	public isFirstDate: boolean = false;
	public isSecondDate: boolean = false;
	public roomsDateFrom: any[] = [];
	public roomsDateTo: any[] = [];
	public isAccommodationArrayNotEmpty: boolean = false;

	public tempAccommodationArray;
	public whichTable;
	public addonsTableStart: boolean = true;
	public summaryAddonsCost: number = 0;
	public addonsCounter: number = 0;
	public todayYearPlusOne: number = 0;
	public accommodationArray = [];
	public accommodationSource = new MatTableDataSource(this.accommodationArray);

	constructor() {}

	getSummaryAddons() {
		return this.summaryAddons;
	}

	setSummaryAddons(summaryAddons: any) {
		this.summaryAddons = summaryAddons;
	}

	getAddons() {
		return this.addons;
	}

	setAddons(addons: any) {
		this.addons = addons;
	}

	getAddonsTableNumber() {
		return this.addonsTableNumber;
	}

	setAddonsTableNumber(addonsTableNumber: any[][]) {
		this.addonsTableNumber = addonsTableNumber;
	}

	getPrzydzielonePokojeNumberTable() {
		return this.przydzielonePokojeNumberTable;
	}

	setPrzydzielonePokojeNumberTable(przydzielonePokojeNumberTable: number[]) {
		this.przydzielonePokojeNumberTable = przydzielonePokojeNumberTable;
	}

	getAddonsTablePointerNumber() {
		return this.addonsTablePointerNumber;
	}

	setAddonsTablePointerNumber(addonsTablePointerNumber: number[]) {
		this.addonsTablePointerNumber = addonsTablePointerNumber;
	}

	getAddonsTableCounter() {
		return this.addonsTableCounter;
	}

	setAddonsTableCounter(addonsTableCounter: number) {
		this.addonsTableCounter = addonsTableCounter;
	}

	getDisabledAddons() {
		return this.disabledAddons;
	}

	setDisabledAddons(disabledAddons: boolean, addonsTableNumber: number[][]) {
		for (let i = 0; i < addonsTableNumber.length; i++) {
			this.disabledAddons[i] = disabledAddons;
		}
	}
	setDisabledAddons2(tempDisabledAddons: boolean[]) {
		this.disabledAddons = tempDisabledAddons;
	}

	getIfNewDates(): boolean {
		return this.ifNewDates;
	}

	setIfNewDates(ifNewDates: boolean) {
		this.ifNewDates = ifNewDates;
	}

	getIfUpdateAllData(): boolean {
		return this.ifUpdateAllData;
	}

	setIfUpdateAllData(ifUpdateAllData: boolean) {
		this.ifUpdateAllData = ifUpdateAllData;
	}

	getSummaryCost(): number {
		return this.summaryCost;
	}

	setSummaryCost(summaryCost) {
		this.summaryCost = summaryCost;
	}

	getIsFirstDate(): boolean {
		return this.isFirstDate;
	}

	setIsFirstDate(isFirstDate) {
		this.isFirstDate = isFirstDate;
	}

	getIsSecondDate(): boolean {
		return this.isSecondDate;
	}

	setIsSecondDate(isSecondDate) {
		this.isSecondDate = isSecondDate;
	}

	getRoomsDateFrom() {
		return this.roomsDateFrom;
	}

	setRoomsDateFrom(roomsDateFrom: any[]) {
		this.roomsDateFrom = roomsDateFrom;
	}

	getRoomsDateTo() {
		return this.roomsDateTo;
	}

	setRoomsDateTo(roomsDateTo: any[]) {
		this.roomsDateTo = roomsDateTo;
	}

	getIsAccommodationArrayNotEmpty() {
		return this.isAccommodationArrayNotEmpty;
	}

	setIsAccommodationArrayNotEmpty(isAccommodationArrayNotEmpty: boolean) {
		this.isAccommodationArrayNotEmpty = isAccommodationArrayNotEmpty;
	}

	getTempAccommodationArray() {
		return this.tempAccommodationArray;
	}

	setTempAccommodationArray(tempAccommodationArray) {
		this.tempAccommodationArray = tempAccommodationArray;
	}

	getWhichTable() {
		return this.whichTable;
	}

	setWhichTable(whichTable) {
		this.whichTable = whichTable;
	}

	getAddonsTableStart(): boolean {
		return this.addonsTableStart;
	}

	setAddonsTableStart(addonsTableStart: boolean) {
		this.addonsTableStart = addonsTableStart;
	}

	getSummaryAddonsCost(): number {
		return this.summaryAddonsCost;
	}

	setSummaryAddonsCost(summaryAddonsCost: number) {
		this.summaryAddonsCost = summaryAddonsCost;
	}

	getAddonsCounter(): number {
		return this.addonsCounter;
	}

	setAddonsCounter(addonsCounter: number) {
		this.addonsCounter = addonsCounter;
	}

	getTodayYearPlusOne(): number {
		return this.todayYearPlusOne;
	}

	setTodayYearPlusOne(todayYearPlusOne: number) {
		this.todayYearPlusOne = todayYearPlusOne;
	}

	getAccommodationArray() {
		return this.accommodationArray;
	}

	setAccommodationArray(accommodationArray) {
		this.accommodationArray = accommodationArray;
	}

	getAccommodationSource() {
		return this.accommodationSource;
	}

	setAccommodationSource(accommodationSource) {
		this.accommodationSource = accommodationSource;
	}
}
