import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { exhaustMap, take, tap } from 'rxjs/operators';

import { Stayin } from 'src/app/pages/reception/stayins/models/stayin.model';

@Injectable({
	providedIn: 'root',
})
export class StayinsService {
	stayinsList = new BehaviorSubject<Stayin[]>(null);

	constructor(private http: HttpClient) {}

	/**
	 * Set oservable to any changing of content in the reservationsList
	 * @returns Observable
	 */
	getStayinsList(data: any): Observable<Stayin[]> {
		return this.http.get<Stayin[]>('api/meldunek/getMeldunekList', { params: data }).pipe(
			tap((res) => {
				this.stayinsList.next(res);
			})
		);
	}

	sendPaymentRequest(meldunekId): Observable<any> {
		return this.http.post('api/meldunek/wyslijWezwanieDoZaplaty', meldunekId);
	}

	changeRoomOfStayin(meldunekId: number, pokojId: number): Observable<any> {
		let melPokId = { meldunekId: meldunekId, pokojId: pokojId };
		return this.http.post('/api/meldunek/przeniesMeldunekDoInnegoPokoju', melPokId);
	}

	getStayinInfo(stayinId): Observable<Stayin> {
		return this.http.get<Stayin>('api/meldunek/getMeldunekInfo?meldunekId=' + stayinId);
	}

	editStayin(stayinData): Observable<any> {
		return this.http.put<any>('api/meldunek/edytujMeldunek', stayinData);
	}
	checkOut(reason: any, type: string): Observable<any> {
		return this.http.post('api/meldunek/wymeldujPokoj', reason);
	}
}
