import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReceptionService {
  constructor(private http: HttpClient) {}

  getFirmaList(): Observable<any> {
    return this.http.get("api/firma/getFirmaList");
  }

  getRooms(): Observable<any> {
    return this.http.get("api/pokoj/getPokojList");
  }

  addGroupBooking(data): Observable<any> {
    return this.http.post<any>("api/grupa/dodajRezerwacjeGrupowa", data);
  }

  getAgeInfo(): Observable<any> {
    return this.http.get("/api/rezerwacja/getGuestAgeClassList");
  }

  generateKey(meldunekId): Observable<any> {
    return this.http.get(
      "api/ttlock/createPassCodeMeldunek?meldunekId=" + meldunekId
    );
  }
  generateKeyRezerwacja(rezerwacjaId): Observable<any> {
    return this.http.get(
      "api/ttlock/createPassCodeRezerwacja?rezerwacjaId=" + rezerwacjaId
    );
  }
  
  cancelPassCodeMel(meldunekId: Observable<any>) {
    return this.http.post(
      "api/ttlock/deletePassCodeMeldunek?meldunekId=" + meldunekId, {
        meldunekId: meldunekId
      }
    );
  }
  cancelPassCodeRes(rezerwacjaId: Observable<any>) {
    return this.http.get(
      "api/ttlock/deletePassCodeRezerwacja?rezerwacjaId=" + rezerwacjaId
    );
  }

  getParamValueByName(paramNazwa) {
    return this.http.get(
      "api/param/getParamValueByName?paramNazwa=" + paramNazwa
    );
  }

  getBranchList(): Observable<any> {
    return this.http.get('api/worktime/getBranchList')
  }

  getLocalizations(): Observable<any> {
    return this.http.get('api/pokoj/getPolozeniaList')
  }

  getRoomTypesForLocalization(): Observable<any> {
    return this.http.get('api/pokoje/getPokojtypListForPolozenie')
  }

  assignPerson(assignBody): Observable<any> {
    return this.http.put('api/pokoj/przypiszOsobeSprzatajacaPokoje', {osobaId: assignBody.osobaId, pokojIdList: assignBody.pokojeIdList})
  }

  getEventList(dataParam): Observable<any> {
    return this.http.get('api/worktime/getWorktimeEventList', {params: dataParam})
  }

  getPeriodSummaryList(dataParam): Observable<any> {
    return this.http.get('api/worktime/getWorktimePeriodSummaryList', {params: dataParam})
  }

  addWorkEvent(eventParams): Observable<any> {
    return this.http.post('api/worktime/addWorktimeEvent', {event: eventParams})
  }

  deleteWorkEvent(eventId): Observable<any> {
    return this.http.delete('api/worktime/deleteWorktimeEvent', {params: {id: eventId}})
  }

  getWorkers(): Observable<any> {
    return this.http.get('api/worktime/getEmployeeList')
  }

  addWorkEvents(eventsParams): Observable<any> {
    return this.http.post('api/worktime/addWorktimeEventForDays', eventsParams)
  }

  getUprawnieniList(): Observable<any> {
    return this.http.get("api/zlecenie/getUprawnieniList");
  }

  getPokojeOsobySprzatajacej(osobaId): Observable<any> {
    let url: string = "api/pokoj/getPokojeOsobySprzatajacej";
    if (osobaId !== null) {
      return this.http.get(url + '?osobaId=' + osobaId )
    } else {
      return this.http.get("api/pokoj/getPokojList")
    }
  }

  sendMailByStatus(mailObject): Observable<any> {
    return this.http.post("api/mail/enqueueMail", mailObject)
  }

}
