import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { loginMP } from './mp-login.interface';

@Injectable({
  providedIn: 'root'
})
export class MpLoginService {

  constructor(private http : HttpClient) { }
  login(loginData : loginMP): Observable<any> {
    return this.http.post<any>("api/users/login", loginData);
  }
}
