import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HousekeepingService {
  disableParentContent = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {}

  getRooms(): Observable<any> {
    return this.http.get("api/pokoj/getPokojList");
  }

  changeRoomStatus(roomId: number, roomStatus: number): Observable<any> {
    let data = {
      pokojId: roomId,
      pokojSprzatany: roomStatus,
    };
    return this.http.post("api/pokoj/zmienStatus", data);
  }

  changeRoomStatuses(roomsToChange): Observable<any> {
    return this.http.post("api/pokoj/zmienStatusy", roomsToChange);
  }

  updateRoomRequest(roomId: number, timestamp: string, roomRequestType: string): Observable<any> {
    let data = {
      pokojId: roomId,
      timestamp: timestamp,
      roomRequestType: roomRequestType
    };
    return this.http.put("api/pokoj/updateRoomRequest", data);
  }

  getRoomInfo(roomId: number): Observable<any> {
    return this.http.get("api/pokoj/getPokojInfo?pokojId=" + roomId);
  }
}
