import { Component, OnInit } from '@angular/core';

import { AuthService } from './auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { AppServiceService } from './pages/app-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { interval } from 'rxjs';
import { User } from './auth/models/user.model';
declare let gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private swUpdate: SwUpdate,
        private appService: AppServiceService,
        private router: Router
    ) {
        document.getElementById('blank-page-loading')
            ? (document.getElementById('blank-page-loading').style.display =
                  'block')
            : null;
        translate.addLangs(['pl', 'en']);
        let config = JSON.parse(localStorage.getItem('config'));
        if (config?.language != undefined) {
            this.switchLanguage(config.language);
        } else {
            this.switchLanguage('pl');
        }
        // this.authService.autologin();
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                window.location.reload();
                // if (confirm('New version avilable. Load it?')) { window.location.reload(); }
            });
        }
    }

    ngOnInit(): void {
        this.authService.autologin();
    }

    ngOnChanges() {}

    setUpAnalytics() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('config', 'G-YOUR-GOOGLE-ID', {
                    page_path: event.urlAfterRedirects,
                });
            });
    }
    switchLanguage(language: string) {
        let config = JSON.parse(localStorage.getItem('config'));
        config == null
            ? (config = {
                  language: language,
              })
            : (config.language = language);
        localStorage.setItem('config', JSON.stringify(config));
        this.translate.use(language);
        this.translate.setDefaultLang(language);
    }
}
