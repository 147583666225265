import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReceptionService } from '../../reception/reception.service';

@Component({
  selector: 'app-assigning',
  templateUrl: './assigning.component.html',
  styleUrls: ['./assigning.component.scss']
})
export class AssigningComponent implements OnInit {
  public assignmentForm: FormGroup;
  public localForm: FormGroup;
  public roomGroup = []
  public uprawnieniList = [];
  public localizations = [];
  public rooms = [];
  public pickedWorker: number;
  public singleWorkerRooms = []
  
  constructor(
    public dialogRef: MatDialogRef<AssigningComponent>,
    private receptionService: ReceptionService,
    private formBuilder: FormBuilder,
    ) {  }

  ngOnInit(): void {
    this.receptionService.getUprawnieniList().subscribe((res) => {
      this.uprawnieniList = res;
    });
    this.assignmentForm = this.formBuilder.group({
      osobaId: [null, Validators.required],
    });
    this.getLocalizationsAndRooms();
  }

  getSingleWorkerRooms(osobaId): void {
    this.roomGroup.forEach(el => {
      el.pokoje.forEach(el2 => {
        el2.checked = false
      })
    })
    this.singleWorkerRooms = []
    this.pickedWorker = osobaId;
    this.receptionService.getPokojeOsobySprzatajacej(osobaId).subscribe(res => {
      this.singleWorkerRooms = res
      this.roomGroup.forEach(el =>{
        el.pokoje.forEach(el2 => {
          this.singleWorkerRooms.forEach(el3 => {
            if (el2.pokojId === el3.pokojId) {
              el2.checked = true
            }
          })
          })
      })
    })
  }

  getLocalizationsAndRooms(): void {
    this.receptionService.getLocalizations().subscribe(res => {
      res.forEach(el => {
        this.roomGroup.push({
          polozenie: el.polozenieNazwa,
          pokoje: []
        })
      });
      this.assignRoomsToLocals();    
      this.localizations = res;
    })
  }
  
  changeStatusInRoomGroup(event, room): void {
    this.roomGroup.forEach(el => {
      if (el.polozenieId == room.polozenieId) {
        el.pokoje.forEach(pokoj => {
          if ( room.pokojId == pokoj.pokojId) {
            pokoj.checked 
          }
        })
      }
    })
  }


  assignRoomsToLocals(): void {
    this.receptionService.getRooms().subscribe(res => {
      res.forEach(el => {
        this.roomGroup.forEach((el2, index) => {
          el.checked = false
          if (el.polozenieNazwa == el2.polozenie) {
            this.roomGroup[index].pokoje.push(el)
          }
        })
      });
    })
  }

  changeWholeLocalStatus(e, b): void {
    b.pokoje.forEach(el => {
      el.checked = e.checked
    })
  }

  updateEmployeeAssignment(response: string): void {
    if (response == 'save') {
      let assignmentObject = {
        osobaId: this.pickedWorker,
        pokojeIdList: []
      }
      this.roomGroup.forEach(el => {
        el.pokoje.forEach(el2 => {
          if (el2.checked === true) {
            assignmentObject.pokojeIdList.push(el2.pokojId)
          }
        })
      })
      this.receptionService.assignPerson(assignmentObject).subscribe(res => this.dialogRef.close())
    } else {
      this.dialogRef.close()
    }
  }
      
}
