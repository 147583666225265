import { Component, Input, OnInit } from "@angular/core";
import { DualListComponent } from "angular-dual-listbox";

@Component({
  selector: "app-dual-list-picker",
  templateUrl: "./dual-list-picker.component.html",
  styleUrls: ["./dual-list-picker.component.scss"],
})
export class DualListPickerComponent
  extends DualListComponent
  implements OnInit {
  // source: Array<any>;
  // destination: Array<any>;
  @Input() confirmed: any;
  @Input() list: any;
  format = {
    add: "Dodaj",
    remove: "Usuń",
    all: "Zaznacz",
    none: "Odznacz",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "pl",
  };
  tab = 1;
  keepSorted = true;
  key = "_id";
  display = "_name";
  filter = false;
  sort = true;

  ngOnInit(): void {}
}
