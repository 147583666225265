import { Injectable } from '@angular/core';

import { BehaviorSubject, empty, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class AppServiceService {
    public isLocalizationOn = new BehaviorSubject<any>(false);
    public apiVersion;
    constructor(private http: HttpClient) {}

    getParamValueByName(paramNazwa) {
        return this.http.get(
            'api/param/getParamValueByName?paramNazwa=' + paramNazwa
        );
    }

    getObslugaCennikowNaPolozenie() {
        this.getParamValueByName('ObslugaCennikowNaPolozenie').subscribe(
            (res) => {
                let state = res ? true : false;
                this.isLocalizationOn.next(state);
            }
        );
    }

    getPmsModuleConfig() {
        return this.http.get<any>('api/pmsconfig/getPmsModuleConfig');
    }

    getApiVersion() {
        return this.http.get<any>('api/app/getVersion');
    }
}
