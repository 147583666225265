import {
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, exhaustMap, retry, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private _snackBar: MatSnackBar,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.indexOf('i18n') != -1) {
            return next.handle(req);
        }

        if (req.url.includes('raporty.ekoncept-pms.pl')) {
            if (req.headers.has('authorization')) {
                return next.handle(req);
            }
            return throwError('Cant authorize raportmanagera');
        }

        let apiUrl = environment.domain;
        if (req.headers.has('authorization')) {
            const modifiedReq = req.clone({
                url: apiUrl + req.url,
            });
            return next.handle(modifiedReq);
        }

        if (
            req.url.indexOf('api/rooms') != -1 ||
            req.url.indexOf('api/hotels/') != -1 ||
            req.url.indexOf('api/users/login') != -1 ||
            req.url.indexOf('api/users') != -1 ||
            req.url.indexOf('api/assortments') != -1 ||
            req.url.indexOf('api/users/groups') != -1 ||
            req.url.indexOf('api/parameters/initialConfigWizzardFinalized') !=
                -1
        ) {
            return this.authService.mpUser.pipe(
                take(1),
                exhaustMap((mpUser) => {
                    apiUrl = environment.mpDomain;
                    let modifiedReq = req.clone({
                        // Add hotel code into url
                        url: apiUrl + req.url,
                    });
                    if (mpUser) {
                        modifiedReq = req.clone({
                            // Add hotel code into url
                            url: apiUrl + req.url,
                            headers: req.headers.set(
                                'Authorization',
                                'Bearer ' + mpUser.uuid
                            ),
                        });
                    }
                    return next.handle(modifiedReq).pipe(
                        catchError((err) => {
                            console.log('er', err);
                            if (err.status == 401) {
                                console.log('1, blad: ', err);
                                this.authService.logout(); // tun on this
                                console.log('1, blad: ', err);
                            }
                            return throwError(err);
                        })
                    );
                })
            );
        }

        return this.authService.user.pipe(
            take(1),
            exhaustMap((user) => {
                // Handle all request if there in no user stored in the app (e.g login request)
                if (!user) {
                    const modifiedReq = req.clone({
                        // Add hotel code into url
                        url: apiUrl + req.url,
                    });
                    return next.handle(modifiedReq);
                }

                // Handle all request if there is user stored in the app
                const modifiedReq = req.clone({
                    // Add hotel code and token into request
                    headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + user.uuid
                    ),
                    url: apiUrl + user.hotelCode + '/' + req.url,
                });

                return next.handle(modifiedReq).pipe(
                    catchError((errorRes) => {
                        if (isDevMode()) {
                        }
                        // Set unknow error message
                        let errorMessage = 'An unknow error occurred!';

                        // Handle errors not notified from API
                        if (
                            errorRes.error &&
                            errorRes.error?.message ==
                                `Database operation error: Can't read or write data`
                        ) {
                            this._snackBar.open(
                                'Wystąpił błąd serwera. Spróbuj ponownie.',
                                'Błąd',
                                { duration: 2000 }
                            );
                            // this._snackBar.open('Sprawdź czy wszystkie pola zostały wypełnione', 'Błąd', {duration: 2000})
                            return throwError(errorRes.error?.message);
                        }
                        if (errorRes.status == 401) {
                            if (this.router.url === '/dashboard') {
                                console.log('2, blad: ', errorRes);
                                this.authService.logout();
                                console.log('2, blad: ', errorRes);
                            } else {
                                this.router.navigate(['/dashboard']);
                            }
                            if (errorRes.error != null) {
                                this._snackBar.open(
                                    errorRes.error?.message,
                                    '',
                                    {
                                        duration: 2000,
                                    }
                                );
                            }
                            return throwError(errorRes?.message);
                        }
                        if (errorRes.status == 404) {
                            this._snackBar.open(
                                'Metoda jest niedostępna, proszę zaktualizować oprogramowanie.',
                                'Ok',
                                {
                                    duration: 3000,
                                }
                            );
                            return throwError(errorRes?.message);
                        }
                        if (errorRes.error && errorRes.error?.message) {
                            this._snackBar.open(errorRes.error?.message, '', {
                                duration: 2000,
                            });
                            return throwError(errorRes.error?.message);
                        }

                        // Handle errors notified from API
                        if (errorRes.error && errorRes.error.error) {
                            this._snackBar.open(errorRes.error.error, '', {
                                duration: 2000,
                            });
                            return throwError(errorRes.error.error);
                        }

                        // Handle unknown errors
                        this._snackBar.open(errorMessage, '', {
                            duration: 2000,
                        });
                        return throwError(errorMessage);
                    })
                );
            }),

            // Reset token timer when request occur
            tap((req) => {
                this.authService.resetTokenTimer();
            })
        );
    }
}
