import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCloseResponse } from 'src/app/shared/enums/DialogEnums';

@Component({
    selector: 'app-confirm-edit',
    templateUrl: './confirm-edit.component.html',
    styleUrls: ['./confirm-edit.component.scss'],
})
export class ConfirmEditComponent implements OnInit {
    public dialogCloseResponse  = DialogCloseResponse;
    constructor(
        public dialogRef: MatDialogRef<ConfirmEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event.key === 'Escape') {
                this.sendRes(DialogCloseResponse.CANCEL);
            }
        });

        this.dialogRef.backdropClick().subscribe((event) => {
            this.sendRes(DialogCloseResponse.CANCEL);
        });
    }

    sendRes(res: DialogCloseResponse): void {
        this.dialogRef.close(res);
    }
}
