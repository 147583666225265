import { Directive, Input, Output, EventEmitter, HostListener, HostBinding } from "@angular/core";

type SortColumn = keyof any | '';
type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': 'asc', '': 'asc' };

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    selector: "th[sortable]",
    host: {
        "[class.asc]": 'direction === "asc"',
        "[class.desc]": 'direction === "desc"',
        "(click)": "rotate()",
    },
})
export class NgbdSortableHeader {
    // Bind to directive property and set default value of them
    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = '';

    // Set directive emmiter
    @Output() sort = new EventEmitter<SortEvent>();

    // Set init styles
    @HostBinding('style.cursor') cursor: string = "pointer";
    @HostBinding('style.opacity') opacity: number = 1;

    // Set hover effect
    @HostListener('mouseenter') onmouseover(eventData: Event){
        this.opacity = 0.8;
    }
    @HostListener('mouseleave') onmouseout(eventData: Event){
        this.opacity = 1;
    }

    // Change direction of sorting
    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}