import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { StaleService } from "src/app/shared/services/stale.service";
import { HttpClient } from "@angular/common/http";
import { ParamService } from "src/app/shared/services/param.service";

@Injectable({
  providedIn: "root",
})
export class StayinsNewService {
  constructor(
    private staleService: StaleService,
    private paramService: ParamService,
    public http: HttpClient
  ) {}
  dodajMeldunek(data): Observable<any> {
    return this.http.post<any>("api/meldunek/dodajMeldunek", data);
  }
  getReservationInitData(): Observable<any> {
    const rodzajGoscia$: Observable<any> = this.staleService.getRodzajeGosci();
    const zrodloRezerwacji$: Observable<any> =
      this.staleService.getZrodlaRezerwacji();
    const formyPlatnosci$: Observable<any> =
      this.staleService.getFormyPlatnosci();
    const maksymalnaIloscDzieci$: Observable<any> =
      this.paramService.getMaksymalnaIloscDzieci();

    return forkJoin(
      rodzajGoscia$,
      zrodloRezerwacji$,
      formyPlatnosci$,
      maksymalnaIloscDzieci$
    ).pipe(
      map((res) => {
        let initData = {
          rodzajeGosci: res[0],
          zrodlaRezerwacji: res[1],
          formyPlatnosci: res[2],
          maksymalnaIloscDzieci: res[3].paramWartosc,
        };
        return initData;
      })
    );
  }

  getStatusyRezerwacji(): Observable<any> {
    return this.http.get ("api/rezerwacja/getStatusyRezerwacji");
  }

  getDostepneTypy(data): Observable<any> {
    return this.http.get("api/grupa/getDostepneTypyPokoiZCenami", {
      params: data,
    });
  }

  getGuestAgeList(): Observable<any> {
    return this.http.get<any>("api/rezerwacja/getGuestAgeClassList");
  }

  getRoomPrices(data): Observable<any> {
    return this.http.get<any>("api/rezerwacja/getCenyPokoi", { params: data });
  }

  editReservation(data): Observable<any> {
    //return this.http.put("api/rezerwacja/edytujRezerwacje", data);
    return this.http.put<any>(`api/rezerwacja/zmienPokojAktywnejRezerwacji?rezerwacjaId=${data.rezerwacjaId}&pokojId=${data.pokojId}`,{});
  }

  editStayin(data): Observable<any> {
    return this.http.put("api/meldunek/edytujMeldunek", data);
  }

  getTypyDlaPolozenia(data): Observable<any> {
    return this.http.get<any>(
      "api/pokoj/getPokojtypListForPolozenie?polozenieId=" + data
    );
  }

  getRodzajeGosci(): Observable<any> {
    return this.http.get<any>("api/stale/getRodzajeGosci");
  }

  getZrodlaRezerwacji(): Observable<any> {
    return this.http.get<any>("api/stale/getZrodlaRezerwacji");
  }
}
